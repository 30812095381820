import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://demo-application.qa2.tothenew.net/api/v1",
  headers:{
    "Content-Type": "application/json"
  }
});

export const axiosBaseInstance = axios.create({
  baseURL: "https://demo-application.qa2.tothenew.net",
  headers:{
    "Content-Type": "application/json"
  }
});

export default axiosInstance;