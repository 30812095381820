import React, { useState, useEffect } from 'react';
import axiosInstance from '../../services/axios';
import Cookies from 'js-cookie';

const PollingComponent = ({setPollData, pollData, hasVoted, setHasVoted, userId, setUserId}) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState('');
    const handleVote = async (selectedChoicesIndex) => {
        const updatedChoices = pollData.choices.map((choice, index) => ({
            ...choice,
            votes: index === selectedChoicesIndex ? choice.votes + 1 : choice.votes,
            selected: index === selectedChoicesIndex
        }));
        setPollData({ ...pollData, choices: updatedChoices });

        try {
            const response = await axiosInstance.post('/save-poll-data', {
                poll_id: pollData.poll_id,
                choices: updatedChoices,
                user_id: parseInt(userId), // Include user ID in the request
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            // Check if the response status is 200 and message is "Success"
            if (response?.status === 200 && response?.data?.message === 'Success') {
                // Check the status inside the data object
                if (response?.data?.data?.status === 'error') {
                    // Handle error response
                    setMessage(response?.data?.data?.message);
                } else if (response.data?.data?.status === 'success') {
                    // Handle success response
                    setMessage('Your vote has been recorded.');

                    // Set a cookie to indicate the user has voted
                    const cookieName = `poll_${pollData.poll_id}_voted`;
                    Cookies.set(cookieName, true, { expires: 7 }); // Cookie expires in 7 days
                    setHasVoted(true);
                }
            } else {
                // Handle unexpected response status or message
                setMessage('An unexpected error occurred.');
            }

        } catch (error) {
            // Handle network or other errors
            setMessage('You have already voted');
        }

    };

    const totalVotes = pollData.choices.reduce((total, choice) => total + parseInt(choice.votes), 0);

    const getPercentage = (votes, totalVotes) => {
        if (totalVotes === 0) return 0;
        return (votes / totalVotes) * 100;
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error.message}</p>;
    }

    return (
        <>
            <div className='mb-[16px]'>
                <h2 className='capitalize text-4xl font-bold text-[#034d82]'>Poll of the day</h2>
            </div>
            <div className="mx-auto bg-gray-100 p-4 rounded-md shadow-md">
                <h2 className="text-xl font-bold text-center mb-4">{pollData.question}</h2>
                {message && <p className="text-red-500 text-center">{message}</p>}
                <ul>
                    {pollData.choices.map((choice, index) => (
                        <li key={index} className="flex flex-col items-center mb-4">
                            <div className='flex gap-2 items-center'>
                                <input
                                    type="radio"
                                    name="option"
                                    checked={choice.selected}
                                    onChange={() => handleVote(index)}
                                    className="cursor-pointer"
                                    disabled={hasVoted} // Disable if user has already voted
                                />
                                <label className="font-medium">{choice.text}</label>
                            </div>
                            <div className="relative w-full bg-gray-300 h-6 rounded-full mt-1">
                                <div
                                    className="absolute top-0 left-0 bg-gradient-to-r from-pink-300 to-blue-300
                                    h-full rounded-full"
                                    style={{ width: `${getPercentage(choice.votes, totalVotes)}%` }}
                                ></div>
                                <div className="absolute inset-0 flex items-center justify-center text-sm text-blue-600 font-extrabold">
                                    {getPercentage(choice.votes, totalVotes).toFixed(0)}%
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
};

export default PollingComponent;
