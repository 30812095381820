import React from 'react'
import Nav from './Nav'
import '../../assets/scss/header.scss'
const Header = () => {
  return (
    <header className='text-white sticky top-0 w-full z-[999]'>
      <Nav />
    </header>
  )
}

export default Header
