import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getAuthClient } from "../../auth/auth";
import { useNavigate } from "react-router";

const ChangePassword = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const auth = getAuthClient();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const onSubmit = async (data) => {
        setLoading(true);

        let name = null
        if (localStorage.getItem("userInfo") !== null) {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            name = userInfo.name;
        }

        try {
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "name": name,
                    "current_pass": data.currentPassword,
                    "new_pass": data.newPassword
                }),
                redirect: "follow"
            };

            const response = await fetch(`${auth.config.base}/api/v1/change-password`, requestOptions);
            const result = await response.json();
            console.log(result)
            if (response.ok) {
                setErrorMessage('');
                setSuccessMessage('Success, You will be redirected to the login page in few seconds.. ');
                setTimeout(() =>{
                    auth.logout().then(() => {
                        navigate("/login");
                    });
                }, 5000)
                
            } else {
                setErrorMessage(result.data.message || 'An error occurred while changing the password');
                setSuccessMessage('');
            }
        } catch (error) {
            console.error(error);
            setErrorMessage('An error occurred while changing the password');
            setSuccessMessage('');
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className="change-password flex justify-center text-center lg:min-w-[450px] w-full bg-[#f3f4f6] lg:p-14 py-[50px] px-[10px]">
            <div className="lg:m-[40px] m-[0]">
                <form className="form mt-[50px] lg:mt-[50px] md:mt-[100px] lg:min-h-[290px] md:min-h-[290px] min-h-[250px] p-10 lg:w-[500px] max-w-[500px] md:min-w-[450px] min-w-[350px] rounded-2xl shadow-2xl w-full" onSubmit={handleSubmit(onSubmit)}>
                    <label className="heading text-[25px] block font-semibold text-left mb-[20px] pb-[10px]">Change Your Password</label>
                    {errorMessage && <p className="text-red-500 mb-[15px]">{errorMessage}</p>}
                    {successMessage && <p className="text-green-500 mb-[15px]">{successMessage}</p>}

                    <input
                        type="password"
                        name="currentPassword"
                        id="currentPassword"
                        maxLength={30}
                        className="placeholder-gray-700 placeholder-opacity-50 mb-[20px] rounded-[8px] w-full border border-gray-400 py-3 px-1"
                        placeholder="Current Password"
                        {...register("currentPassword", {
                            required: "Current password is required",
                            maxLength: {
                                value: 30,
                                message: "Too long characters"
                            }
                        })}
                    />
                    {errors.currentPassword && <p className="text-red-500 mb-[10px] mt-[-10px]">{errors.currentPassword.message}</p>}
                    <input
                        type="password"
                        name="newPassword"
                        id="newPassword"
                        maxLength={30}
                        className="placeholder-gray-700 placeholder-opacity-50 mb-[20px] rounded-[8px] w-full border border-gray-400 py-3 px-1"
                        placeholder="New Password"
                        {...register("newPassword", {
                            required: "New password is required",
                            maxLength: {
                                value: 30,
                                message: "Too long characters"
                            }
                        })}
                    />
                    {errors.newPassword && <p className="text-red-500 mb-[10px] mt-[-10px]">{errors.newPassword.message}</p>}
                    <input
                        type="password"
                        name="confirmPassword"
                        id="confirmPassword"
                        maxLength={30}
                        className="placeholder-gray-700 placeholder-opacity-50 mb-[20px] rounded-[8px] w-full border border-gray-400 py-3 px-1"
                        placeholder="Confirm New Password"
                        {...register("confirmPassword", {
                            required: "Confirm new password is required",
                            maxLength: {
                                value: 30,
                                message: "Too long characters"
                            }
                        })}
                    />
                    {errors.confirmPassword && <p className="text-red-500 mb-[10px] mt-[-10px]">{errors.confirmPassword.message}</p>}
                    <button className="rounded-[8px] px-8 py-2 bg-black text-white text-center" disabled={loading}>
                        {loading ? 'Please wait...' : 'Submit'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ChangePassword;
