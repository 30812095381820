import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../services/axios';
import { Link } from 'react-router-dom';
import { ErrorItem } from '../../components/global/ErrorPage';
import { useSelector } from 'react-redux';
import MetaTag from '../../components/global/MetaTag';


export function ShimmerItem() {
  return (
      <div className='text-center'>
        <div className="mx-auto lg:px-20 w-[90%] h-[350px] mt-[80px] mb-[16px] bg-slate-300 rounded"></div>
        <div className="mx-auto lg:px-20 w-[90%] h-[350px] mt-[80px] mb-[16px] bg-slate-300 rounded"></div>
        <div className="mx-auto  lg:px-20 w-[90%] h-[350px] mt-[80px] mb-[16px] bg-slate-300 rounded"></div>
        <div className="mx-auto lg:px-20 w-[90%] h-[350px] mt-[80px] mb-[16px] bg-slate-300 rounded"></div>
        <div className="mx-auto lg:px-20 w-[90%] h-[350px] mt-[80px] mb-[16px] bg-slate-300 rounded"></div>
      </div>
    
  )
}
const PortfolioCategory = () => {
  const { alias, type } = useParams();
  const categoryAlias = `/${type}/${alias}`;

  const [teaser, setTeaser] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [category, setCategory] = useState('');
  const [cta, setCta] = useState('');
  const metaData = useSelector(state => state.siteConfig.meta_tags);
  useEffect(() => {
    setIsFetching(true);
    const fetchPortfolioCategory = async () => {
      setLoading(true);
      try {
        const res = await axiosInstance.get(`read/categories?alias=${categoryAlias}`);
        if (res.data.status === 204) {
          const errMsg = { code: res.data.status, message: `${res.data.message} for ${categoryAlias}` };
          throw errMsg;
        }
        setTeaser(res.data.data.list);
        setCategory(res?.data?.data?.list[0]?.name);
        setCta(res?.data?.data?.cta);
        setLoading(false);
      } catch (err) {
        setError(err);
      }
      setIsFetching(false);
    };
    fetchPortfolioCategory();
  }, [categoryAlias]);

  if (loading) {
    return <p className="py-16 text-center"><ShimmerItem /></p>;
  }
  if (isFetching) return <ShimmerItem />;
  if (error) return <ErrorItem error={error} />;

  return (
    <>
    <MetaTag metaData={metaData[0]} />
      <section className="bg-white pb-10 pt-[60px] md:pt-40 lg:pt-20 dark:bg-dark">
        <div className="portfolio-banner-image" />
        <h1 className=" text-center mb-[0] text-[#034d82] not-italic lg:text-[64px] text-[38px] font-bold lg:leading-[76px] leading-[50px]">Categories</h1>
        {teaser && teaser.map((item, index) => (
          <div className="min-[320px]:px-10 lg:px-20 mx-[auto]" key={index}>
            <div className="portfolio-cat p-10 mt-12">
              <div className="portfolio-tag font-bold text-[20px] text-black italic my-4" dangerouslySetInnerHTML={{ __html: category }} />
              {/*<h5 className="case-study-portfolio text-[20px] font-bold text-[#034D82] pb-2 pt-2"> CASE STUDY </h5>*/}
              <h1 className="portfolio-title text-[50px] font-bold leading-72 tracking-tighter text-[#034D82]">{item.title}</h1>
              <p className="portfolio-body pb-[10px] text-base font-normal leading-normal text-[#90A3B4]" dangerouslySetInnerHTML={{ __html: item.body }} />
              <div className="portfolio-by">
                <span className="author-name font-[700] mr-1 leading-tight tracking-tighter text-[#020202]">By<span>{item.user_name}</span></span>
                <span className="create-date text-[14px] font-normal leading-tight tracking-tighter text-[#505050]"> {item.date} .{item.read_time}</span>
              </div>
              <div className="portfolio-read-more">
                <Link to={`${item?.content_url}?nid=${item?.nid}`}>
                  <div className="justify-end flex text-[#0071B3]">{cta}</div>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </section>
    </>
  );
};

export default PortfolioCategory;
