import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { getAuthClient } from "../../auth/auth";
import axiosInstance from "../../services/axios";
import { DashaboardProfileWhithoutImgShimmer } from "../global/Loader";
const auth = getAuthClient();
const EditProfile = ({ updateProfile, getProfile }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(true);
    const [interestData, setInterestData] = useState([]);
    const [locationData, setLocationData] = useState('');
    const [selectInterest, setSelectInterest] = useState([]);
    const [selectLocation, setSelectLocation] = useState(null);
    const [serverErr, setServerErr] = useState(false)
    const [populateData, setPopulatedData] = useState({
        name: '',
        mobile: '',
        address: ''
    })
    const userId = localStorage.getItem('userInfo');

    // Error State.
    let [errors, setErrors] = useState({
        name: [],
        mobile: [],
        address: [],
    });

    let [dirty, setDirty] = useState({
        name: false,
        mobile: false,
        address: false,
    });
    let [message, setMessage] = useState("");
    // validate.
    let validate = () => {
        let errorsData = {};
        // Name Validate.
        errorsData.name = [];
        if (!populateData.name) {
            errorsData.name.push("Name is required");
        }
        const nameRegex = /^[a-zA-Z\s]+$/;
        if (populateData.name) {
            if (!nameRegex.test(populateData.name)) {
                errorsData.name.push("Allow only charecter ");
            }
        }
        // Mobile Validate.
        errorsData.mobile = [];
        if (!populateData.mobile) {
            errorsData.mobile.push("Mobile number is required");
        }
        const mobileRegex = /^[6-9]{1}[0-9]{9}$/
        if (populateData.mobile) {
            if (!mobileRegex.test(populateData.mobile)) {
                errorsData.mobile.push("Mobile number should be 10 degit and start with 6,7,8 or 9");
            }
        }

        // Address Validate
        errorsData.address = [];
        if (!populateData.address) {
            errorsData.address.push("Address is required");
        }
        setErrors(errorsData);
    }

    useEffect(validate, [populateData]);

    // const notify = () => toast("Successful edit!");
    useEffect(() => {
        document.title = 'Edit Profile';
        const userId = localStorage.getItem('userInfo');
        getData(userId);
        document.title = 'Edit Profile';
        if (!localStorage.getItem("drupal-oauth-token")) {
            navigate("/login");
        }
        getInterestLocationData();
    }, [])

    // get interest and location data 
    const getInterestLocationData = async () => {
        try {
            const res = await axiosInstance.get('/read/user-interset-and-location');
            setLocationData(res?.data?.data?.location);
            setInterestData(res?.data?.data?.interset);
        } catch (error) {
            console.log(error);
        }
    }

    // get user data 
    const getData = async (userId) => {

        const url = `/user/${JSON.parse(userId)?.uid}?_format=json`;
        const headers = new Headers({
            Accept: 'application/json',
        });

        auth.fetchWithAuthentication(url, { headers })
            .then((response) => response.json())
            .then((data) => {
                setSelectLocation(data?.data?.field_location[0]?.target_id);
                setSelectInterest(data?.data?.field_interest?.map((data) => data.target_id));
                setPopulatedData({
                    name: data?.data?.field_name[0]?.value,
                    mobile: data?.data?.field_mobile[0]?.value,
                    address: data?.data?.field_address[0]?.value,
                })
                setLoader(false);
                // if (isValidData(data)) {
                //   // Initialize the list of content with data retrieved from Drupal.
                //   updateContent(data.data);
                // }
            })
            .catch(err => console.log('There was an error accessing the API', err));
    }
    const handleInterestChange = (e) => {
        if (e.target.checked) {
            setSelectInterest([...selectInterest, parseInt(e.target.value)])
        } else {
            setSelectInterest(prevState => {
                return prevState.filter(item => item !== parseInt(e.target.value))
            });
        }
    }
    // cancel edit profile 
    const handleCancel = (e) => {
        e.preventDefault();
        updateProfile(false);

    }
    const handleLocationChangeData = (e) => {

        setSelectLocation(e.target.value);
    }

    const handleSubmit = async (e) => {
        updateProfile(true);
        e.preventDefault();
        //set all controls as dirty.
        let dirtyData = dirty;
        Object.keys(dirty).forEach((control) => {
            dirtyData[control] = true;
        });
        setDirty(dirtyData);

        validate();

        let isValid = () => {
            let valid = true;
            for (let control in errors) {
                if (errors[control].length > 0) {
                    valid = false;
                }
            }
            return valid;
        }

        if (isValid()) {

            setLoading(true);

            let selectInterestObj = selectInterest.map((data) => {
                return {
                    "target_id": data
                }
            })

            const fetchUrl = `/user/${JSON.parse(userId).uid}?_format=json`;
            let data = JSON.stringify({
                "field_address": [
                    {
                        "value": populateData.address
                    }
                ],
                "field_mobile": [
                    {
                        "value": populateData.mobile
                    }
                ],
                "field_name": [
                    {
                        "value": populateData.name
                    }
                ],
                "field_location": [
                    {
                        "target_id": selectLocation != '-Select location-' ? selectLocation : null
                    }
                ],

                "field_interest": selectInterestObj
            });

            const fetchOptions = {
                // Use HTTP PATCH for edits, and HTTP POST to create new articles.
                method: 'PATCH',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                    // 'Authorization': tokenValue
                }),
                body: data
            };

            auth.fetchWithAuthentication(fetchUrl, fetchOptions)
                .then((response) => response.json()).then((data) => {


                    setLoading(false);
                    updateProfile(false);
                    getProfile()
                    // navigate('/dashboard');
                })
                .catch((err) => {
                    setServerErr(true)
                    // console.log(err)
                    setLoading(false);
                });
        }
    }
    return (
        <div className="user-profile-wrapper">
            {/* <ToastContainer toastClassName="white-toast" /> */}
            {
                loader ? <DashaboardProfileWhithoutImgShimmer /> :

                    <form className="edit-form bg-[#fff]   mx-auto py-5 px-10" onSubmit={handleSubmit}>
                        <h3 className="text-[20px] text-[#034d82] ">Update Your Profile..</h3>
                        <div className="flex">
                            <div className="w-1/2 mr-2">
                                <div className="mt-4">
                                    <input type="text" name="name" maxLength={30} value={populateData.name} onChange={(e) => { setPopulatedData({ ...populateData, [e.target.name]: e.target.value }) }}
                                        onBlur={(event) => {
                                            setDirty({ ...dirty, [event.target.name]: true });
                                            validate();
                                        }} placeholder="Name" className="lg:ml-[0] md:ml-[0] ml-[0] placeholder-gray-700 placeholder-opacity-50 lg:mb-[2px] md:mb-[2px] mb-[2px] rounded-[8px] w-full lg:m-3 m-0 block bg-[#f0f0f0] py-3 px-5 lg:mt-3 my-[5px] " />
                                </div>
                                <div className="text-red-500 pl-[10px]">
                                    {dirty['name'] && errors['name'][0] ? errors['name'] : ""}
                                </div>
                            </div>
                            <div className="w-1/2 ml-2">
                                <div className="mt-4">
                                    <input type="text" name="mobile" maxLength={10} value={populateData.mobile} onChange={(e) => { setPopulatedData({ ...populateData, [e.target.name]: e.target.value }) }}
                                        onBlur={(event) => {
                                            setDirty({ ...dirty, [event.target.name]: true });
                                            validate();
                                        }} placeholder="Mobile" className="lg:ml-[0] md:ml-[0] ml-[0] placeholder-gray-700 placeholder-opacity-50 lg:mb-[2px] md:mb-[2px] mb-[2px] rounded-[8px] w-full lg:m-3 m-0 block bg-[#f0f0f0] py-3 px-5 lg:mt-3 my-[5px] " />
                                </div>
                                <div className="text-red-500 pl-[10px]">
                                    {dirty['mobile'] && errors['mobile'][0] ? errors['mobile'] : ""}
                                </div>
                            </div>
                        </div>
                        <div className="flex">
                            <div className=" w-1/2 mr-2">
                                <div className="mt-4">
                                    <input type="text" name="address" maxLength={200} value={populateData.address} onChange={(e) => { setPopulatedData({ ...populateData, [e.target.name]: e.target.value }) }}
                                        onBlur={(event) => {
                                            setDirty({ ...dirty, [event.target.name]: true });
                                            validate();
                                        }} placeholder="Address" className="lg:ml-[0] md:ml-[0] ml-[0] placeholder-gray-700 placeholder-opacity-50 lg:mb-[2px] md:mb-[2px] mb-[2px] rounded-[8px] w-full lg:m-3 m-0 block bg-[#f0f0f0] py-3 px-5 lg:mt-3 my-[5px] " />
                                </div>
                                <div className="text-red-500 pl-[10px]">
                                    {dirty['address'] && errors['address'][0] ? errors['address'] : ""}
                                </div>
                            </div>

                            <div className="w-1/2 ml-2">
                                <select className="mt-4 py-3 px-4 pe-9 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600 border" onChange={handleLocationChangeData}>
                                    <option>-Select location-</option>
                                    {Object.keys(locationData).map((key) => <option value={key} selected={key == selectLocation ? "selected" : ''}>{locationData[key]}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="w-1/2">
                            <h3 className="text-[20px] text-[#034d82]  mt-5 underline">Interested in</h3>
                            <div className="-ml-3 flex">
                                {Object.keys(interestData).map((key) =>
                                    <div className=" flex items-center form-group mt-5 mx-3" key={key}>
                                        <input type="checkbox" className="mr-[10px]" id={key} name={parseInt(key)} value={parseInt(key)} onChange={handleInterestChange} checked={selectInterest.includes(parseInt(key))} />
                                        <label for="html">{interestData[key]}</label>
                                    </div>
                                )}
                            </div>
                        </div>


                        <div className="mt-8 text-center">
                            <button className={`edit-btn text-white rounded-md px-8 py-2 ${loading && `animate-pulse pointer-events-none`}`}>{loading ? 'Please wait..' : 'Save'}</button>
                            <button className={`edit-btn text-white rounded-md px-8 py-2 ml-4 ${loading && `pointer-events-none cursor-not-allowed opacity-50`}`} onClick={handleCancel}>Cancel</button>
                        </div>
                    </form>
            }
        </div>
    )
}

export default EditProfile;