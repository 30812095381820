import React , { useState, useEffect } from "react";
import '../../assets/scss/homepage.scss';
import axiosInstance, {axiosBaseInstance} from "../../services/axios";
import { useForm } from 'react-hook-form';

const ApplyJob = ({nid}) => {
  let uid = 0;
  if(localStorage.getItem('userInfo') !== null){
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    uid = userInfo.uid;
  }
  const [country, setCountry] = useState(0);
  const [state, setState] = useState(0);
  const [city, setCity] = useState(0);
  const [loading, setLoading] = useState(false);

  const [countryData, setcountryData] = useState([]);
  const [stateData, setstateData] = useState([]);
  const [cityData, setcityData] = useState([]);
  const [jobApplied,setJobApplied] = useState(false);
  const [resume, setResume] = useState(null);
  const [resumeobj, setResumeObj] = useState(null);
  const [validResume,setValidResume] = useState(true);
  const [resumeError, setResumeError] = useState(true);

  const { register, handleSubmit, formState: { errors }, reset } = useForm();


  // Function to handle form submission
  const onSubmit = async (jobdata) => {
    
    setLoading(true);
    //upload the resume of the user
    const fetchUrl = `${process.env.REACT_APP_API_URL}file/upload/demoapp_content_entity/demoapp_content_entity/resume?_format=json`;
    const fetchOptions = {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/octet-stream',
            'Content-Disposition': `filename="userpic${new Date().getTime().toString(36)}.pdf"`
        }),
        body: resume
    };
    try {
      //Resume upload
      const response = await fetch(fetchUrl, fetchOptions)
      .then((response) => response.json())
      .then((data) => {
        const raw = JSON.stringify({
          "fullname": jobdata.name,
          "phone_no": jobdata.phoneNumber,
          "email": jobdata.email,
          "description": jobdata.description ?? '',
          "skills": jobdata.skills,
          "country": jobdata.country !== '-select-' ? jobdata.country : null,
          "state": jobdata.state !== '-select-' ? jobdata.state : null,
          "city": jobdata.city !== '-select-' ? jobdata.city : null,
          "job_id": nid ? nid : null,
          "user_id":jobdata.user_id,
          "resume": {
            "target_id": data?.data?.fid[0].value
          }
        });

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const jobresponse = axiosInstance.post('/resume-submission', raw, { headers: myHeaders });
        jobresponse.then(response => {

          if(response.data?.status === 200){
            setJobApplied(true);
            setLoading(false);
          }

        }).catch(error => {
          console.error('Error:', error);
        });
        }
    );
    //Save job form
     } catch (error) {
      console.error('There has been a problem with your job submission:', error);
    }
    reset(); // Reset form fields after submission

  // Scroll to the top of the window
  window.scrollTo({
    top: 60,
    behavior: "smooth"
  });

  };

  const handleInputChange = (event) => {
    const { name, value, type } = event.target;
    if(name == 'country'){
      setCountry(value);
      setState(0);
    }
    if(name == 'state'){
      setState(value);
    }
    if(name == 'city'){
      setCity(value);
    }
    if(name == 'resume'){
      const file = event.target.files[0];
      const validTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
      if (file) {
        const isValidType = validTypes.includes(file.type);
        const isValidSize = file.size <= 8 * 1024 * 1024;
        if (!isValidType || !isValidSize) {
          setValidResume(false);
          setResume(null);
          setResumeError(isValidType ? "File size must be less than 2 MB." : "Invalid file type. Only pdf, doc, and docx are allowed.");
        } else {
          setValidResume(true);
          setResume(file);
          setResumeError("");
        }
      }
    }
  }

  // Custom phone number validation function
  const validatePhoneNumber = (value) => {
    // Regular expression to match a valid phone number format
    const phoneRegex = /^[6-9]\d{9}$/; // Assuming a 10-digit phone number
    return phoneRegex.test(value) || "Invalid phone number. ";
  };
  
  useEffect(() => {
    const fetchData = async () => {
       try {
         const countryResponse = await axiosInstance.get('/read/location-list/0');
         const stateResponse = await axiosInstance.get(`/read/location-list/${country}`);
         const cityResponse = await axiosInstance.get(`/read/location-list/${state}`);
         setcountryData(countryResponse.data.data);
         setstateData(country > 0 ? stateResponse.data.data : []);
         setcityData(state > 0 && state !== '' ? cityResponse.data.data : []);
       } catch (error) {
         console.error('Error fetching data:', error);
       }
     };
     fetchData();
   }, [country,state,city]);

  return (
    <>
      <div className="display-message">
        {jobApplied && (
          <div className="success-message pb-3 text-green-500">
            Your job application has been submitted. We will get back to you
            soon. Thank you!
          </div>
        )}
      </div>
      <form className="resume-form " onSubmit={handleSubmit(onSubmit)}>
        <label className=" border-b border-[#c4c4c4] block mb-2 text-[#262626] text-[20px] font-semibold dark:text-white pb-[10px]">
          Send Your Resume
        </label>
        <div className="flex lg:flex-row flex-col">
          <div className="lg:pr-[34px] pr-0  w-full lg:w-[50%] ">
            <div className="mb-[8px] w-full md:w-full ">
              <label className="block mb-2 text-[#646464] text-[16px] font-medium dark:text-white">
                Name<span className="text-red-500">*</span>
              </label>
              {/* <input type="text" id="name" name="name" {...register('name', { required: true })} className=" rounded-[8px] bg-[#f0f0f0] text-[18px] text-[#3b3b3b] focus:border-[#646464] block w-full p-2.5 h-[40px]" /> */}
              <input
                type="text"
                id="name"
                name="name"
                {...register("name", { required: true })}
                className="rounded-[8px] bg-[#f0f0f0] text-[18px] text-[#3b3b3b] focus:border-[#646464] block w-full p-2.5 h-[40px]"
              />
              {errors.name && (
                <span className="text-red-500">Please enter name</span>
              )}
            </div>
            <div className="mb-[8px] w-full md:w-full ">
              <label className="block mb-2 text-[#646464] text-[16px] font-medium dark:text-white">
                Email<span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                id="email"
                name="email"
                {...register("email", { required: true })}
                className=" rounded-[8px] bg-[#f0f0f0] text-[18px] text-[#3b3b3b] focus:border-[#646464] block w-full p-2.5  h-[40px]"
              />
              {errors.email && (
                <span className="text-red-500">Please enter email</span>
              )}
            </div>
            {/* <div className="mb-[8px] w-full md:w-full ">
                  <label className="block mb-2 text-[#646464] text-[16px] font-medium dark:text-white">Phone Number<span className="text-red-500">*</span></label>
                  <input type="text" id="phoneNumber" name="phoneNumber" {...register('phoneNumber', { required: true,  validate: validatePhoneNumber })} className=" rounded-[8px] bg-[#f0f0f0] text-[18px] text-[#3b3b3b] focus:border-[#646464] block w-full p-2.5  h-[40px]" />
                  {errors.phoneNumber && <span className="text-red-500">{errors.phoneNumber.message}</span>}
                  {errors.phoneNumber && <span className="text-red-500">Please enter phone number</span>}
                </div> */}
            <div className="mb-[8px] w-full md:w-full ">
              <label className="block mb-2 text-[#646464] text-[16px] font-medium dark:text-white">
                Phone Number<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                {...register("phoneNumber", {
                  required: true,
                  validate: validatePhoneNumber,
                })}
                className="rounded-[8px] bg-[#f0f0f0] text-[18px] text-[#3b3b3b] focus:border-[#646464] block w-full p-2.5 h-[40px]"
              />
              {errors.phoneNumber && errors.phoneNumber.type === "required" && (
                <span className="text-red-500">Please enter phone number</span>
              )}
              {errors.phoneNumber && errors.phoneNumber.type === "validate" && (
                <span className="text-red-500">
                  Invalid phone number format
                </span>
              )}
            </div>

            <div className="mb-[8px] w-full md:w-full ">
              <label className="block mb-2 text-[#646464] text-[16px] font-medium dark:text-white">
                Skills<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="skills"
                name="skills"
                {...register("skills", { required: true })}
                className="rounded-[8px] bg-[#f0f0f0] text-[18px] text-[#3b3b3b] focus:border-[#646464] block w-full p-2.5  h-[40px]"
              />
              {errors.skills && (
                <span className="text-red-500">Please enter skills</span>
              )}
            </div>
          </div>
          <div className="w-full lg:w-[50%]">
            <div className="mb-[8px] w-full md:w-full ">
              <label className="block mb-2 text-[#646464] text-[16px] font-medium dark:text-white">
                Country
              </label>
              <select
                id="country"
                name="country"
                {...register("country", { required: false })}
                onChange={handleInputChange}
                className=" rounded-[8px] bg-[#f0f0f0] text-[13px] text-[#3b3b3b] focus:border-[#646464] block w-full p-2.5  h-[40px]"
              >
                <option value="-select-">-select-</option>
                {countryData &&
                  countryData.map((item, index) => (
                    <option key={item.tid} value={item.tid}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="mb-[8px] w-full md:w-full ">
              <label className="block mb-2 text-[#646464] text-[16px] font-medium dark:text-white">
                State
              </label>
              <select
                id="state"
                name="state"
                {...register("state", { required: false })}
                onChange={handleInputChange}
                className=" rounded-[8px] bg-[#f0f0f0] text-[13px] text-[#3b3b3b] focus:border-[#646464] block w-full p-2.5  h-[40px]"
              >
                <option value="-select-">-select-</option>
                {stateData &&
                  stateData.map((item, index) => (
                    <option key={item.tid} value={item.tid}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="mb-[8px] w-full md:w-full ">
              <label className="block mb-2 text-[#646464] text-[16px] font-medium dark:text-white">
                City
              </label>
              <select
                id="city"
                name="city"
                {...register("city", { required: false })}
                onChange={handleInputChange}
                className="rounded-[8px] bg-[#f0f0f0] text-[13px] text-[#3b3b3b] focus:border-[#646464] block w-full p-2.5  h-[40px]"
              >
                <option value="-select-">-select-</option>
                {cityData &&
                  cityData.map((item, index) => (
                    <option key={item.tid} value={item.tid}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="mb-[8px] w-full md:w-full ">
              <label className="block mb-2 text-[#646464] text-[16px] font-medium dark:text-white">
                Resume{" "}
                <span className=" text-gray-700 text-[11px]">
                  (Allowed types: pdf doc docx.
                  <span className="text-red-500 text-[10px]">
                    {" "}
                    Max size: 8 MB.
                  </span>
                  )
                </span>
              </label>
              <input
                type="file"
                id="resume"
                name="resume"
                {...register("resume", { required: true })}
                onChange={handleInputChange}
                className=" border border-[#ccc] rounded-[8px]  bg-[#f0f0f0] text-[18px] text-[#3b3b3b] focus:border-[#646464] block w-full p-1.5"
              />
              <div className="description text-[14px] text-[#444] mt-[5px]"></div>
              {errors.resume && (
                <span className="text-red-500">Please Upload Resume.</span>
              )}
              {!validResume && <p className="text-red-500">{resumeError}</p>}
            </div>
          </div>
        </div>
        <div className="resume-des w-full flex lg:flex-row flex-col">
          <div className="w-full pr-0">
            <div className="mb-[8px] w-full md:w-full">
              <label className="block mb-2 text-[#646464] text-[16px] font-medium dark:text-white">
                Description
              </label>
              <textarea
                id="message"
                rows="4"
                name="description"
                {...register("description", { required: false })}
                className=" resize-none rounded-[8px] bg-[#f0f0f0] text-[18px] text-[#3b3b3b] focus:border-[#646464] block w-full p-2.5"
              ></textarea>
            </div>
          </div>
        </div>
        <input
              type="hidden"
              name="user_id"
              id="user_id"
              value={uid}
              {...register("user_id")}
            />
        <div className="my-5 text-center ">
          <button
            type="submit"
            className="inline-block rounded text-white pt-[14px] pb-[14px] pl-[55px] pr-[55px] bg-gradient-to-r from-[#0f5c74] to-[rgba(0,3,69,.91)] hover:opacity-[.9] "
          >
            {loading ? "Please wait" : "Submit"}
          </button>
        </div>
      </form>
    </>
  );
};

export default ApplyJob;
