import LaptopFrame from './assets/images/laptop-frame.png';
import Hammer from "./assets/images/hammer.png";
import Search from "./assets/images/searchHome.png";
import LanguageFont from "./assets/images/LangFront.png";
import PopupComa from "./assets/images/popup-coma.png";
import GoogleImg from "./assets/images/google.png";
import FacebookImg from "./assets/images/fb.png";
import LinkedInImg from "./assets/images/linkedin.png";
import ArrowDown from "./assets/images/arrow-down.svg";


const  icons = { 
       LaptopFrame, Hammer , Search , LanguageFont , PopupComa  , GoogleImg , FacebookImg , LinkedInImg, ArrowDown
}
export default icons;
