// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/popup-coma.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.testimonial-sec .slick-slider .slick-arrow {
  display: none !important;
}
.testimonial-sec .testimonial {
  position: relative;
  width: 100%;
}
.testimonial-sec .testimonial::after {
  transform: rotate(45deg);
  background: white;
  width: 30px;
  height: 30px;
  content: "";
  position: absolute;
  top: calc(100% - 15px);
  left: 50%;
  margin-left: -20px;
  border-radius: 0 0px 4px 0;
  z-index: -1;
}
.testimonial-sec .testimonial .author-info-outer {
  position: absolute;
}

.expanded-list-container li {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-position-x: calc(100% - 20px);
  background-position-y: 20px;
}
.expanded-list-container li .listing-content :last-child {
  display: contents;
}

@media only screen and (max-width: 768px) {
  .listing-coma-image {
    display: none;
  }
  .testimonial-modal {
    width: 85%;
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/testimonial.scss"],"names":[],"mappings":"AAEQ;EACI,wBAAA;AADZ;AAII;EACI,kBAAA;EACA,WAAA;AAFR;AAGQ;EACI,wBAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,sBAAA;EACA,SAAA;EACA,kBAAA;EACA,0BAAA;EACA,WAAA;AADZ;AAGQ;EACI,kBAAA;AADZ;;AAOI;EACI,yDAAA;EACA,4BAAA;EACA,wCAAA;EACA,2BAAA;AAJR;AAOY;EACI,iBAAA;AALhB;;AAWA;EACG;IACC,aAAA;EARF;EAUC;IACC,UAAA;EARF;AACF","sourcesContent":[".testimonial-sec{\n    .slick-slider{\n        .slick-arrow {\n            display: none !important;\n        }\n    }\n    .testimonial{\n        position: relative;\n        width: 100%;\n        &::after{\n            transform: rotate(45deg);\n            background: white;\n            width: 30px;\n            height: 30px;\n            content: \"\";\n            position: absolute;\n            top: calc(100% - 15px);\n            left: 50%;\n            margin-left: -20px;\n            border-radius: 0 0px 4px 0;\n            z-index: -1;\n        }\n        .author-info-outer {\n            position: absolute;\n        }\n    }\n}\n\n.expanded-list-container{\n    li{\n        background-image: url('../images/popup-coma.png');\n        background-repeat: no-repeat;\n        background-position-x: calc(100% - 20px);\n        background-position-y: 20px;\n        \n        .listing-content{\n            :last-child{\n                display: contents;\n            }\n        }\n    }\n}\n\n@media only screen and (max-width: 768px) {\n   .listing-coma-image{\n    display: none;\n   }\n   .testimonial-modal{\n    width:85%;\n   }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
