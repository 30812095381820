import { useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import LoginSlider from "./LoginSlider";
import icons from "../../Icons";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAuthClient } from "../../auth/auth";
import { processImageUrl } from "../../utils/utils";
import {
  LoginSocialGoogle, LoginSocialFacebook, LoginSocialInstagram, LoginSocialLinkedin, LoginSocialTwitter
} from 'reactjs-social-login';

import {
  GoogleLoginButton, FacebookLoginButton, InstagramLoginButton, LinkedInLoginButton, TwitterLoginButton
} from 'react-social-login-buttons';
import LoadingSpinner, { DashaboardProfileShimmer, LoadingWait } from "../../components/global/Loader";
const auth = getAuthClient();

const Login = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  const dispatch = useDispatch();
  const notify = () => toast("Successful logged in!");
  const [tokenCaptcha, setTokenCaptcha] = useState("");
  const [errCaptcha, setErrCaptcha] = useState("");
  const [loading, setLoading] = useState("");
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    document.title = 'Login';
    if (localStorage.getItem("drupal-oauth-token")) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const onSubmit = async (data) => {
    if (!tokenCaptcha) {
      setErrCaptcha("Please select the google recaptcha.");
    } else {
      auth.login(data.username, data.password).then((res) => {
        if (res.access_token) {
          setLoading('Please wait...');
          setTimeout(() => {
            if (location.state && location.state.from) {
              navigate(location.state.from);
            } else {
              navigate('/dashboard');
            }

          }, 3000)
          setErrCaptcha('');
        } else {
          setError(true);
          setErrorMessage(res)
        }
      }).catch((error) => {

      })
    }
  }

  const handleCaptchaChange = (value) => {
    setTokenCaptcha(value);
    setErrCaptcha('');
  }
  window.scrollTo(0, 0);

  // GOOGLE LOGIN.
  const [provider, setProvider] = useState('');
  const [profile, setProfile] = useState();
  const REDIRECT_URI = 'http://localhost:3000';
  const onLoginStart = useCallback(() => {
    //alert('login start');
  }, []);

  return (
    <>
      {
        loader ? <LoadingWait /> :  <div className="login-wrapper flex items-stretch flex-wrap	">
        <div className="login-banner w-full md:w-1/2">
          <LoginSlider />
        </div>
        <div className="login-form w-full md:w-1/2 p-[4%]">
          <ToastContainer toastClassName="white-toast" />
          <div className="">
            <form onSubmit={handleSubmit(onSubmit)}>
              {isError && <p className="text-red-500 mb-4">{errorMessage}</p>}
              <h3 className="text-center  md:text-start text-4xl mt-0 mb-5 font-bold">Login</h3>
              <h4 className="">Welcome back! Please enter your details.</h4>
              <div className="mb-2">
                <input
                  type="text"
                  name="username"
                  id="username" maxLength={40}
                  className="w-full border-none bg-[#f0f0f0] rounded-[8px] py-3 px-5 mt-[10px] placeholder-gray-700 placeholder-opacity-50"
                  placeholder="Username"
                  {...register("username", {
                    required: "Username is required",
                    onChange: (e) => setErrorMessage('')
                  })}
                />
              </div>
              {errors.username && (
                <p className="text-red-500">{errors.username.message}</p>
              )}
              <div className="mb-2">
                <input
                  type="password"
                  name="password"
                  id="password"
                  maxLength={30}
                  className="w-full border-none bg-[#f0f0f0] rounded-[8px] py-3 px-5 mt-[10px] placeholder-gray-700 placeholder-opacity-50"
                  placeholder="Password"
                  {...register("password", {
                    required: "Password is required",
                    maxLength: {
                      value: 30,
                      message: "Too long characters"
                    },
                    onChange: (e) => setErrorMessage('')
                  })}
                />
              </div>
              {errors.password && (
                <p className="text-red-500">{errors.password.message}</p>
              )}
              <div className="my-4 flex justify-between">
  
                <div className="forgot">
                  <Link to="/user/password">
                    <u>Forgot Password</u>
                  </Link>
                </div>
              </div>
              <ReCAPTCHA sitekey="6LfgprUpAAAAAEUfyDfGBszw0OKc7RHz0rg0iJWN" onChange={handleCaptchaChange} />
              <p className="text-red-500">{errCaptcha}</p>
              <div className="mt-8">
                <button className="btn rounded-[6px] w-full bg-black text-white text-center p-[10px]" disabled={loading ? true : false} >
                  {!loading ? 'Login' : 'Please wait...'}
                </button>
              </div>
              <p className="mt-4 or text-center">or</p>
              <div className=" mt-4 social-logins">
                <div className="google-signin soc-btn">
                  {/* <Link
                    to="/"
                    className="flex w-[210px]	mx-auto items-center gap-5"
                  >
                    <img
                      src={processImageUrl(icons.GoogleImg)}
                      className="w-[25px]"
                      alt="google-img"
                    />
                    Sign in with Google
                  </Link> */}
                  <LoginSocialGoogle
                    client_id="678926229740-faag3teocu368noa7o425ppo31ld89g6.apps.googleusercontent.com"
                    onLoginStart={onLoginStart}
                    redirect_uri={REDIRECT_URI}
                    scope="openid profile email"
                    discoveryDocs="claims_supported"
                    access_type="online"
                    onResolve={({ provider, data }: IResolveParams) => {
                      setProvider(provider);
                      setProfile(data);
                      setLoader(true);
                      
                      data?.email ?  auth.socialLogin(data,provider).then((res) => {
                        navigate('/dashboard');
                        
                      }) : setLoader(false);
                     
  
                    }}
                    onReject={err => {
                      console.log(err);
                    }}
                  >
                    <GoogleLoginButton />
                  </LoginSocialGoogle>
  
                  <LoginSocialFacebook
                    isOnlyGetToken
                    appId={process.env.REACT_APP_FB_APP_ID || ''}
                    onLoginStart={onLoginStart}
                    onResolve={({ provider, data }) => {
                      setProvider(provider)
                      setProfile(data)
                    }}
                    onReject={(err) => {
                      console.log(err)
                    }}
                  >
                    <FacebookLoginButton />
                  </LoginSocialFacebook>
  
                  <LoginSocialInstagram
                    isOnlyGetToken
                    client_id={process.env.REACT_APP_INSTAGRAM_APP_ID || ''}
                    client_secret={process.env.REACT_APP_INSTAGRAM_APP_SECRET || ''}
                    redirect_uri={REDIRECT_URI}
                    onLoginStart={onLoginStart}
                    onResolve={({ provider, data }) => {
                      setProvider(provider)
                      setProfile(data)
                    }}
                    onReject={(err) => {
                      console.log(err)
                    }}
                  >
                    <InstagramLoginButton />
                  </LoginSocialInstagram>
  
                  <LoginSocialLinkedin
                    isOnlyGetToken
                    client_id={process.env.REACT_APP_LINKEDIN_APP_ID || ''}
                    client_secret={process.env.REACT_APP_LINKEDIN_APP_SECRET || ''}
                    redirect_uri={REDIRECT_URI}
                    onLoginStart={onLoginStart}
                    onResolve={({ provider, data }) => {
                      setProvider(provider)
                      setProfile(data)
                    }}
                    onReject={(err) => {
                      console.log(err)
                    }}
                  >
                    <LinkedInLoginButton />
                  </LoginSocialLinkedin>
  
                  <LoginSocialTwitter
                    isOnlyGetToken
                    client_id={process.env.REACT_APP_TWITTER_V2_APP_KEY || ''}
                    redirect_uri={REDIRECT_URI}
                    onLoginStart={onLoginStart}
                    onResolve={({ provider, data }) => {
                      setProvider(provider)
                      setProfile(data)
                    }}
                    onReject={(err) => {
                      console.log(err)
                    }}
                  >
                    <TwitterLoginButton />
                  </LoginSocialTwitter>
                </div>
              </div>
              <div className="mt-12  mb-0 text-center">
                <span className="text-gray-500">
                  Don't have an account?
                  <Link to="/register" state={{ from: location.state }} className="text-black ml-2 underline">
                    Sign up for free
                  </Link>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
      }
    </>
  );
};

export default Login;