import errorImg from "../../assets/images/network_error.jpg";


const NetworkError = () => {
  return (
    <section className="bg-white pb-10 pt-[50px] pl-[20px] pr-[20px] dark:bg-dark lg:pb-20 ">
      <div className='container my-[0] mx-[auto]'>
          <div className='text-center flex justify-center'>
            <img src={errorImg} alt='Network Error' className='w-max-[100%] block my-[48px]'/>
          </div>
          <h1 className="text-center text-[48px]  text-[#061f53] font-bold tracking-[3px]">Internal Server Error</h1>
          <p className="text-center text-black text-[18px] pt-[20px] tracking-[2px] text-shadow">Something went wrong at our end! Don't worry, we'll fix it soon.</p>
          <p className="text-center text-black text-[16px] my-[20px] tracking-[1px]">Please try again after some time.</p>
      </div>
    </section>
  )
}

export default NetworkError;