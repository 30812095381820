import React from 'react'
import '../../assets/scss/common.scss'
   
  const Left = ({ categories, handleInputChange,selectedCategory}) => {
    
    let cat_options = categories?.filters?.tid?.options;
    return (
      <>
      {Object.entries(cat_options || {}).map(([key,value]) => (
        <div className="blog-checkbox flex gap-3" key={key}>
        <input 
          type="checkbox" 
          value={key} 
          name="catname"  
          onChange={(e)=> handleInputChange(e)}
          checked={selectedCategory.includes(key)} 
          className="m-auto  !h-[19px] !w-[23px] border-gray-200 rounded" id={`hs-default-checkbox-${key}}`} />
        <label htmlFor={`hs-default-checkbox-${key}`} className="w-[90%] text-[20px] tracking-[-1px] font-normal text-black dark:text-gray-400">{value}</label>
      </div>
      ))}
    </>
    );
   
  }
  export default Left