import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

function MetaTag({ metaData }) {
   const metaDataDef = useSelector(state => state.siteConfig.meta_tags);
    if(!metaData){
        metaData = metaDataDef[0];
    }
   return (
    
    <Helmet>
        <title>{metaData?.title}</title>
        <meta name="description" content={metaData?.description} />
        <meta name="keywords" content={metaData?.keywords} />  
    </Helmet>
    
 )
}
export default MetaTag;