import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Home from "../pages/home/index";
import ErrorPage from "../components/global/ErrorPage";
import Blogs from "../pages/blogs/Blogs";
import Jobs from "../pages/jobs/Jobs";
import Portfolio from "../pages/portfolio/Portfolio";
import Glossary from "../pages/glossary/Glossary";
import Header from "../components/global/Header";
import Footer from "../components/global/Footer";
import PortfolioDetail from "../pages/portfolio/PortfolioDetail";
import ExpandedTestimonials from "../pages/testimonials/ExpandedTestimonials";
import Quiz from "../pages/quiz/Quiz";
import BlogDetail from "../pages/blogs/Detail";
import Login from "../pages/login/Login";
import Register from "../pages/regiser/Register";
import Dashboard from "../pages/dashboard/Dashboard";
import { useEffect, useState } from "react";
import { siteConfig } from "../features/config/configSlice";
import { useDispatch, useSelector } from "react-redux";
import NetworkError from "../components/global/NetworkError";
import Pages from "../pages/pages/Pages";
import axiosInstance from "../services/axios";
import JobDetail from "../pages/jobs/JobDetail";
import ForgetPassword from "../pages/login/ForgetPassword";
import PortfolioCategory from "../pages/portfolio/PortfolioCategory";
import ChangePassword from "../pages/login/ChangePassword";


const CustomRouter = () => {
  const [error,setError] = useState();
  const dispatch = useDispatch();
  const {home_portfolio,home_quiz, home_testimonial,home_featuredblog} = useSelector((state) => state.siteConfig);

  useEffect(() => {
    async function fetchHeadings(){
      try {
        const res = await axiosInstance.get("/read/site-config");
        dispatch(siteConfig.updateConfig(res.data.data));
      } catch (error) {
        setError(error);
      }
    }
    if(typeof home_portfolio.heading === "undefined" &&
      typeof home_quiz.heading === "undefined" &&
      typeof home_testimonial.heading === "undefined" &&
      typeof home_featuredblog.heading === "undefined") {
        fetchHeadings();
      }
  },[dispatch, home_quiz.heading,  home_portfolio.heading, home_featuredblog.heading, home_testimonial.heading]);

  if(error?.code === 'ERR_NETWORK') return <NetworkError />;

  return (
    <Router>
      <Header />
      <Routes>
         <Route path="/" element={<Home />}/>
         <Route path='*' element={<ErrorPage/>} />
         <Route path='blogs' element={<Blogs />} />
         <Route path=':id/:lid/*' element={<BlogDetail />} />
         <Route path='portfolios' element={<Portfolio />} />
         <Route path='portfolio/:id' element={<PortfolioDetail />} />
         <Route path='glossary' element={<Glossary />} />
         <Route path='jobs' element={<Jobs />} />
         <Route path='quiz' element={<Quiz />} />
         <Route path="login" element={<Login />} />
         <Route path="user/password" element={<ForgetPassword />} />
         <Route path="register" element={<Register />} />
         <Route path="dashboard" element={<Dashboard />} />
         <Route path="testimonials" element={<ExpandedTestimonials />} />
         <Route path='page/:id/*' element={<Pages />} />
         <Route path="jobs/:alias" element={<JobDetail />} />
         <Route path="/category/:type/:alias" element={<PortfolioCategory />} />
         <Route path="settings/password" element={<ChangePassword />} />

      </Routes>
      <Footer />
    </Router>
  );
};

export default CustomRouter;
