import { Link } from "react-router-dom";

const UserJobSlider = ({ appliedJobs }) => {
  console.log(appliedJobs);
  return (
    <div className="slider2 flex-grow-[1] ">
      <div className="listing m-[-10px] flex flex-wrap items-inherit ">
        {appliedJobs.map((item, index) => (
          <div
            key={index}
            className="listing-inner relative w-full md:w-[calc(30%-10px)] min-h-[250px] bg-gradient-to-br from-blue-500 via-blue-300 to-blue-600 m-5 text-white py-8 px-6 transition duration-300 ease-in-out transform hover:shadow-lg rounded-lg overflow-hidden"
          >
            <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 z-10"></div>
            <div className="relative z-20">
              <Link to={item.view_node} className="hover:text-blue-100">
                <h3 className="text-[18px] lg:text-[22px] font-bold pb-[15px]">
                  {item.title}
                </h3>
              </Link>
              <div className="flex items-center mb-4">
                {item.field_experience && (
                  <p className="inline-block bg-[#fff] text-black text-sm md:text-[14px] px-4 py-2 rounded-full mr-4">
                    {item.field_experience}
                  </p>
                )}
                {item.field_job_location && (
                  <p className="inline-block bg-[#fff] text-black text-sm md:text-[14px] px-4 py-2 rounded-full">
                    {item.field_job_location}
                  </p>
                )}
              </div>
              <div className="text-sm text-gray-100 mb-2">
                <strong>Applied On :</strong>  {item.created}
              </div>
              <div className="text-sm text-gray-100">
                <strong>Status:</strong> In Progress
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserJobSlider;
