import SlickSlider from "../slick/SlickSlider";
import { Link } from "react-router-dom";

const UserJobSlider = ({ jobLocation }) => {
  const sliderSettings = {
    infinite: jobLocation.length > 3 ? true : false,
    dots: false,
    arrows: jobLocation.length > 3 ? true : false,
    speed: 300,
    slidesToShow: 3,
    autoplay: true,
    column: 2,
    loop: true,
    responsive: [
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 3000,
          speed: 1500,
          arrows: false,
        }
      }
    ]
  };

  return (
    <div className="slider2 flex-grow-[1] ">
      <div className='listing user-job-slider '>
        <SlickSlider
          settings={sliderSettings}
          slides={jobLocation.map((item, index) => (
            <div
              className=' m-[15px] min-h-[250px] bg-gradient-to-r from-[#0f5c74] to-[rgba(0,3,69,.91)] shadow-neutral-700 shadow-lg  text-[#fff] p-[20px] hover:scale-[1.05] '
            >
              <Link to={item?.view_node}>
                <h3 className='text-[18px] lg:text-[22px] font-bold pb-[15px]'>{item?.title}</h3>
              </Link>
              {item.field_experience && (
                <p className='experience relative text-[20px] pb-[10px] pl-[50px] before:left-[0] before:absolute before:h-[25px] before:w-[25px] before:top-[3px]'>
                  {item?.field_experience}
                </p>
              )}
              {item.field_job_location && (
                <p className='location relative text-[20px] pb-[10px] pl-[50px] before:left-[0] before:absolute before:h-[25px] before:w-[25px] before:top-[3px]'>
                  {item?.field_job_location}
                </p>
              )}
            </div>
          ))}
        />
      </div>
    </div>
  )
}

export default UserJobSlider
