import React, { useEffect, useState } from 'react';
import blogsimg from '../../assets/images/blogs.png';
import { Link } from 'react-router-dom';
import { processImageUrl } from '../../utils/utils';
import axiosInstance from '../../services/axios';

const Blogs = ({ blogs }) => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const [mostViewBlog, setMostViewBlog] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get("/read/most-view-blogs");
      const blogData = response?.data?.data;
      setMostViewBlog(blogData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  if (!blogs) {
    return <div>No Blogs items to show!</div>;
  }

  return (
    <section>
      <div className="min-[320px]:px-10 lg:px-20">
        <div className="page-title flex justify-center items-end text-center lg:items-center mt-[-30px] mb-[30px] lg:mt-[50px] lg:mb-[50px]">
          <h2 className="mt-[60px] md:mt-[5px] text-[#034d82] lg:text-[64px] text-[38px] font-bold lg:leading-[76px] leading-[50px]">{blogs?.title}</h2>
          <span className="pencil-image"><img src={blogs?.icon} /></span>
        </div>
        <ul className="blogs-outer flex flex-wrap m-[-15px] lg:m-[-15px]">
          {
            mostViewBlog.map((item, index) => (
              <li className="m-[15px] w-[calc(100%_-_30px)] md:w-[calc(50%_-_30px)] xl:w-[calc(33.33%_-_30px)] relative" key={index}>
                <div className="xyz shadow-neutral-700 shadow-lg rounded-xl h-full">
                  <div className="blog-imge transform transition-transform duration-200 hover:scale-90 p-[8px] pb-0">
                    <Link to={item?.blog_url}>
                      <img
                        className="aspect-[146/100] border rounded-lg text-slate-800 w-full border-solid border-black border-opacity-30"
                        src={item.blog_image || blogsimg}
                        alt={item.blog_img_alt}
                        onClick={handleScrollToTop}
                      />
                    </Link>
                  </div>
                  <div className="blogs-bottom-sec p-[20px] lg:pb-[60px]">
                    <div className="blog-title md:mb-[40px] mb-[40px] lg:mb-2 text-[#034d82] font-bold text-[20px] line-clamp-3 pb-[8px] leading-[30px]">
                      <div dangerouslySetInnerHTML={{ __html: item.title }} />
                    </div>
                    <div className='container flex items-center	lg:justify-between md:justify-between sm:justify-start	mb-[20px] md:w-[81%] 2xl:w-[84%] absolute bottom-0 '>
                      <span className="category-btn w-[127px] mr-[20px]">
                        <Link to={`/blogs?cid=${item.category_id}`} onClick={handleScrollToTop} className='block text-center hover:font-extrabold font-normal text-[12px] text-white pr-[15px] pl-[15px] py-[9px] uppercase cursor-pointer'>
                          {item.categories}
                        </Link>
                      </span>
                      <Link data-dialog-type="modal" className="btn" to={item?.blog_url}>
                        <p className="reads text-[#000] capitalize font-bold text-[16px] relative after:absolute after:h-[29px] after:w-[29px] after:top-[67%] after:left-[96%]  after:ml-[15px] after:translate-y-[-50%] after:transition after:duration-[.2s] hover:after:translate-y-[-50%] hover:after:translate-x-[5px]">
                          {item.read_time} Read
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
            ))
          }
        </ul>
        <div className="more-link text-center pt-[40px] mb-[3rem] btn">
          <Link to="/blogs" className="inline-block rounded text-white pt-[16px] pb-[16px] pl-[60px] pr-[60px] bg-gradient-to-r from-[#0f5c74] to-[rgba(0,3,69,.91)] hover:opacity-[.9]" onClick={handleScrollToTop}>
          {blogs?.block_read_more_label}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Blogs;
