import { useEffect, useState } from "react";
import UserProfile from "../../components/userprofile/UserProfile";
import { json, useNavigate } from "react-router-dom";
import { getAuthClient } from "../../auth/auth";
import { DashboardShimmerItem } from "../../components/global/Loader";
import '../../assets/scss/homepage.scss';
import { CirclePicker } from "react-color";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const auth = getAuthClient();
const Dashboard = () => {

    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState(null);
    const userId = JSON.parse(localStorage.getItem('userInfo')).uid;
    const [profilePicLoading, setProfilePicLoading] = useState(false);
    const [interestedBlog, setInterestedBlog] = useState([]);
    const [jobLocation, setJobLocation] = useState([]);
    const [appliedJobs, setAppliedJobs] = useState([]);
    const [interestLocationInfo, setInterestLocationInfo] = useState(null);
    const [loader, setLoader] = useState(true);
    const [userInfoLoader, setUserInfoLoader] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [bgColor, setBgColor] = useState('');
    const [txtColor, setTxtColor] = useState('');
    const [tileColor, setTileColor] = useState('');
    const [currentBgColor, setCurrentBgColor] = useState('')
    const [currentTxtColor, setCurrentTxtColor] = useState('')
    const [currentTileColor, setCurrentTileColor] = useState('')
    const [disableBtn, setDisableBtn] = useState(false);
    const [serverErr, setServerErr] = useState(false)
    const [themeLoader, setThemeLoader] = useState({
        'save': false,
        'setDefault': false
    });

    const notify = () => toast("Chnages saved successfully!");
    useEffect(() => {
        document.title = 'Dashboard';
        if (!localStorage.getItem("drupal-oauth-token")) {
            navigate("/login");

        }
        getProfile()
        // getInterestedBlogData()

    }, [])

    const handleProfilePicLoading = (flag) => {
        setProfilePicLoading(flag);
    }

    const handleLoading = (flag) => {
        setUserInfoLoader(flag)
    }
    // change theme color functinality
    const changeDashboardTheme = (check) => {

        setDisableBtn(true)
        check === 'setDefaultBtn' ? setThemeLoader({
            ...themeLoader, 'setDefault': true
        }) : setThemeLoader({
            ...themeLoader, 'save': true
        })
        const fetchUrl = `/user/${userId}?_format=json`;
        let data = JSON.stringify({
            "field_dashboard_bg_color": [
                {
                    "value": check === 'setDefaultBtn' ? '' : currentBgColor,
                }
            ],
            "field_dashboard_text_color": [
                {
                    "value": check === 'setDefaultBtn' ? '' : currentTxtColor,
                }
            ],
            "field_dashboard_tile_color": [
                {
                    "value": check === 'setDefaultBtn' ? '' : currentTileColor,
                }
            ]
        });
        const fetchOptions = {
            method: 'PATCH',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Cache': 'no-cache'
            }),
            body: data
        };

        auth.fetchWithAuthentication(fetchUrl, fetchOptions)
            .then((response) => response.json()).then((data) => {
                setIsOpen(false)
                setDisableBtn(false)
                check === 'setDefaultBtn' ? setThemeLoader({
                    ...themeLoader, 'setDefault': false
                }) : setThemeLoader({
                    ...themeLoader, 'save': false
                })
                notify()
            })
            .catch((err) => {
                setServerErr(true)
                // console.log(err)
            });
    }
    const getProfile = () => {
        handleLoading(true);
        const headers = new Headers({
            Accept: 'application/json',
        });

        auth.fetchWithAuthentication(`/api/v1/read/dashbord-data-api/${userId}?_format=json`, { headers })
            .then((response) => response.json())
            .then((data) => {
                setUserInfo({
                    user: data?.data?.user_detail?.list[0]?.name,
                    name: data?.data?.user_detail?.list[0]?.field_name,
                    mobile: data?.data?.user_detail?.list[0]?.field_mobile,
                    address: data?.data?.user_detail?.list[0]?.field_address,
                    // user_picture: data?.data?.user_picture[0] && data?.data?.user_picture[0].url,
                    user_picture: data?.data?.user_detail?.list[0] && data?.data?.user_detail?.list[0].user_picture,
                    greet_txt: data?.data?.user_detail?.list[0]?.nothing

                })
                setCurrentBgColor(data?.data?.user_detail?.list[0]?.field_dashboard_bg_color)
                setCurrentTxtColor(data?.data?.user_detail?.list[0]?.field_dashboard_text_color)
                setCurrentTileColor(data?.data?.user_detail?.list[0]?.field_dashboard_tile_color)
                setBgColor(data?.data?.user_detail?.list[0]?.field_dashboard_bg_color)
                setTxtColor(data?.data?.user_detail?.list[0]?.field_dashboard_text_color)
                setTileColor(data?.data?.user_detail?.list[0]?.field_dashboard_tile_color)
                setJobLocation(data?.data?.location?.list)
                setAppliedJobs(data?.data?.applied_jobs?.list)
                setInterestedBlog(data?.data?.interest?.list)
                setInterestLocationInfo(data?.data?.user_info);
                setLoader(false);
                handleLoading(false)
                handleProfilePicLoading(false);
                // if (isValidData(data)) {
                //   // Initialize the list of content with data retrieved from Drupal.
                //   updateContent(data.data);
                // }
            })
            .catch(err => console.log('There was an error accessing the API', err));
    }
    const toggleSlide = () => {
        setIsOpen(!isOpen);
    };
    const resetColors = () => {
        setCurrentBgColor(bgColor)
        setCurrentTxtColor(txtColor)
        setCurrentTileColor(tileColor)
    };

    const setDefaultColor = () => {
        setCurrentBgColor('');
        setCurrentTxtColor('');
        setCurrentTileColor('')
        changeDashboardTheme('setDefaultBtn')
    }
    useEffect(function(){
        window.scrollTo(0,0);
       },[]);

    return (
        <div className="dashboard">
            <ToastContainer />
            {
                loader ? <DashboardShimmerItem /> : <UserProfile userInfo={userInfo} getProfile={getProfile} profilePicLoading={profilePicLoading} jobLocation={jobLocation} appliedJobs={appliedJobs} interestedBlog={interestedBlog} handleProfilePicLoading={handleProfilePicLoading} interestLocationInfo={interestLocationInfo} handleLoading={handleLoading} userInfoLoader={userInfoLoader} currentBgColor={currentBgColor} currentTxtColor={currentTxtColor} currentTileColor={currentTileColor} />
            }
            <div className="color-button" onClick={toggleSlide}>
                <div className="color-change-logo"></div>
                <div className={`color-change-inner ${isOpen ? "open" : ""}`}></div>
            </div>
            <div className={`slide ${isOpen ? "open" : ""}`}>
                <p className="py-[10px] font-bold text-center leading-5 text-[#007a9f] text-[20px] border-b-[1px] border-[#0000001f]">Dashboard Setting</p>
                <p className="py-[10px] leading-5 text-[#000] ">Change background color of <span className=" font-bold text-[#007a9f]">Dashboard</span> page.</p>
                <div className=" w-full py-[10px] border-b-[1px] border-[#0000001f]">
                    <CirclePicker
                        color={currentBgColor}
                        onChangeComplete={(color) => {
                            setCurrentBgColor(color.hex);
                        }}
                    />
                </div>
                <p className="py-[10px] leading-5 text-[#000] ">Change background color of <span className=" font-bold text-[#007a9f]">User Info</span> tile.</p>
                <div className=" w-full py-[10px] border-b-[1px] border-[#0000001f]">
                    <CirclePicker
                        color={currentTileColor}
                        onChangeComplete={(color) => {
                            setCurrentTileColor(color.hex);
                        }}
                    />
                </div>
                <p className="py-[10px] leading-5 text-[#000] ">Change color of <span className=" font-bold text-[#007a9f]">User Info</span> text.</p>
                <div className=" w-full py-[10px] border-b-[1px] border-[#0000001f]">
                    <CirclePicker
                        color={currentTxtColor}
                        onChangeComplete={(color) => {
                            setCurrentTxtColor(color.hex);
                        }}
                    />
                </div>
                <div className="mt-8 text-center flex justify-between">
                    <button className={`edit-btn text-white rounded-md px-3 w-[125px]  py-2 false ${disableBtn && `pointer-events-none`}`} onClick={resetColors}>Reset</button>
                    <button className={`edit-btn text-white rounded-md px-3 w-[125px]  py-2 false ${themeLoader.save && `animate-pulse`} ${disableBtn && `pointer-events-none`}`} onClick={() => changeDashboardTheme('saveBtn')}>{themeLoader.save ? `Please Wait...` : 'Save'}</button>
                </div>
                <div className="mt-4 text-center flex justify-between">
                    <button className={`edit-btn text-white rounded-md px-3  py-2 false ${themeLoader.setDefault && `animate-pulse`} ${disableBtn && `pointer-events-none`}`} onClick={setDefaultColor}>{themeLoader.setDefault ? `Please Wait...` : 'Set as default'}</button>
                </div>
            </div>
        </div>
    )
}


export default Dashboard;
