// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list {
  border: 1px solid #fff;
  padding: 5px;
}

.pagination {
  display: flex;
  border: 1px solid #fff;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.pagination .active {
  background: #0f5c74;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/pagination.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,YAAA;AACJ;;AAEE;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AACJ;AAAI;EACE,mBAAA;EACA,WAAA;AAEN","sourcesContent":[".list {\n    border: 1px solid #fff;\n    padding: 5px;\n  }\n  \n  .pagination {\n    display: flex;\n    border: 1px solid #fff;\n    justify-content: center;\n    align-items: center;\n    padding: 20px;\n    .active {\n      background: #0f5c74;\n      color: #fff;\n      // border: 1px solid #fff;\n    }\n    button{\n\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
