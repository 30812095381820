import React from 'react';

//const Pagination = ({ postsPerPage, length }) => {
const Pagination = ({ postsPerPage, length, handlePagination,currentPage}) => {
  const paginationNumbers = [];

  for (let i = 1; i <= Math.ceil(length / postsPerPage); i++) {
    paginationNumbers.push(i);
  }
  return (
    <div className='pagination'>
      {paginationNumbers.map((pageNumber,index) => (
        <button className={`${index+1 === currentPage ? 'active':''} bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded mx-1`} onClick={()=> handlePagination(pageNumber)} key={pageNumber}>{pageNumber}</button>
      ))}
    </div>
  );
};

export default Pagination;