import { useState } from 'react';
import React, { useRef } from 'react';
import { getAuthClient } from "../../auth/auth";
import { useNavigate } from 'react-router-dom';

const auth = getAuthClient();
const EditProfileImage = ({ getProfile, profilePicLoading, handleProfilePicLoading }) => {
    const fileInputRef = useRef(null);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isValidImage, setValidImage] = useState(true);
    const userId = localStorage.getItem('userInfo');
    const [enableBtn, setEnableBtn] = useState(false)
    const navigate = useNavigate();

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (!file?.name.match(/\.(jpg|jpeg|png|gif)$/)) {
            setValidImage(false);
            setEnableBtn(false)
            return false;
        } else {
            setFile(file);
            setEnableBtn(true)
            file === undefined ? setEnableBtn(false) : setEnableBtn(true)
            setValidImage(true)
        }

    };

    const handleUpload = () => {
        handleProfilePicLoading(true)
        const fetchUrl = `/file/upload/user/user/user_picture?_format=json`;
        const fetchOptions = {
            // Use HTTP PATCH for edits, and HTTP POST to create new articles.
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/octet-stream',
                'Content-Disposition': `filename="userpic${new Date().getTime().toString(36)}.png"`
            }),
            body: file
        };

        auth.fetchWithAuthentication(fetchUrl, fetchOptions)
            .then((response) => response.json())
            .then((data) => {
                const raw = JSON.stringify({
                    "user_picture": [
                        {
                            "target_id": data?.data?.fid[0].value,
                            "target_uuid": data?.data?.uuid[0].value
                        }
                    ]
                });
                const requestOptions = {
                    method: "PATCH",
                    headers: new Headers({
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Cache': 'no-cache'
                        // 'Authorization': tokenValue
                    }),
                    body: raw,
                    redirect: "follow"
                };
                const fetchUrl = `/user/${JSON.parse(userId).uid}?_format=json`;
                auth.fetchWithAuthentication(fetchUrl, requestOptions)
                    .then((response) => response.json()).then((data) => {
                        setEnableBtn(false);
                        getProfile();
                    })
            })
    };

    const showFileInput = () => {
        fileInputRef.current.click();
    };

    return (
        // <div className='editprofile-container'>
        //     <input type="file" onChange={handleFileChange} />
        //     {!isValidImage &&
        //         <p className='text-red-500'>Please select png, jpg, jpeg, gif file</p>
        //     }
        //     {enableBtn && (
        //         <button onClick={handleUpload} disabled={profilePicLoading}>
        //             {profilePicLoading ? 'Please wait...' : 'Upload'}
        //         </button>
        //     )}
        // </div>

        <div className='editprofile-container' style={{marginTop: 0}}>
            {/* 
        <div className='editprofile-img' onClick={() => fileInputRef.current.click()}></div>
        */}
            {enableBtn ? null : (
                <div className='editprofile-img' onClick={showFileInput}></div>
            )}
            <label className='file-input-label'>
                <input
                    className='hidden'
                    type="file"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                />
            </label>
            {!isValidImage &&
                <p className='text-red-500'>Please select png, jpg, jpeg, gif file</p>
            }
            {enableBtn && (
                <button onClick={handleUpload} disabled={profilePicLoading} className={`${profilePicLoading && `animate-pulse`}`}>
                    {profilePicLoading ? 'Please wait...' : 'Upload'}
                </button>
            )}
        </div>
    )
}

export default EditProfileImage