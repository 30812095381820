import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "../../assets/scss/portfolio.scss";
import axiosInstance from "../../services/axios";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import MetaTag from "../../components/global/MetaTag";
import { processImageUrl } from "../../utils/utils";

const PortfolioItem = ({ info, cta, layout_display }) => {
  if (layout_display === "grid") {
    return (
      <div className="portfolio-grid-container inline-block  w-full lg:w-[33.33%] mb-[14px] px-[10px]">
        <div className="grid-inner-element relative">
          <img
            className="d-block w-[100%] h-auto"
            src={info.field_image}
            alt={info?.title}
          />
          <h3 className="absolute bottom-4 left-4 lg:text-[28px] text-[22px] lg:mb-[25px] mb-[15px] font-bold lg:leading-[initial] leading-[1em]">
            <NavLink
              className="text-white"
              to={info?.view_node + "?nid=" + info.nid}
            >
              {info?.title}
            </NavLink>
          </h3>
          <div className="grid-hidden-container">
            <h3 className="lg:text-[32px] text-[26px] lg:mb-[25px] mb-[15px] font-bold lg:leading-[initial] leading-[1em] line-clamp-2">
              <NavLink
                className="text-white"
                to={info?.view_node + "?nid=" + info.nid}
              >
                {info?.title}
              </NavLink>
            </h3>
            <h5
              className="lg:text-[20px] text-[18px] font-bold  mb-[10px] text-white opacity-35 lg:leading-[initial] leading-[1.4em] line-clamp-2"
              dangerouslySetInnerHTML={{ __html: info?.body }}
            ></h5>
            <div
              className="desc lg:text-[20px] text-[18px]  text-gray-400 lg:leading-[initial] leading-[1.3em] line-clamp-2"
              dangerouslySetInnerHTML={{
                __html: info?.field_about_the_customer,
              }}
            ></div>
            <NavLink
              className="grid-readcase-button text-[16px] inline-block rounded pr-10 py-3 pl-8 font-semibold leading-6 text-black shadow-sm lg:mt-[50px] mt-[25px]"
              to={info?.view_node}
            >
              {cta ? cta : "Read Case Study"}
            </NavLink>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="portfolio-item-container lg:flex items-center bg-[#f8f8f8]">
        <div className="portfolio-item-image ">
          <img
            className="d-block w-[100%] lg:h-[661px] h-[330px]"
            src={processImageUrl(info.field_image)}
            alt={info?.title}
          />
        </div>
        <div className="portfolio-item-text lg:p-[80px] p-[20px] ">
          <h3 className="lg:text-[32px] text-[26px] lg:mb-[25px] mb-[15px] font-bold lg:leading-[initial] leading-[1em]">
            <NavLink
              className="text-[#034d82]"
              to={info?.view_node + "?nid=" + info.nid}
            >
              {info?.title}
            </NavLink>
          </h3>
          <h5
            className="lg:text-[20px] text-[18px] font-bold lg:mb-[30px] mb-[10px] text-[#034d82] opacity-35 lg:leading-[initial] leading-[1.4em]"
            dangerouslySetInnerHTML={{ __html: info?.body }}
          ></h5>
          <div
            className="desc lg:text-[20px] text-[18px] min-h-[80px] text-gray-400 lg:leading-[initial] leading-[1.3em]"
            dangerouslySetInnerHTML={{ __html: info?.field_about_the_customer }}
          ></div>
          <NavLink
            className="readcase-button text-[16px] inline-block rounded pr-10 py-3 pl-8 font-semibold leading-6 text-white shadow-sm lg:my-[50px] my-[25px]"
            to={info?.view_node + "?nid=" + info.nid}
          >
            {cta ? cta : "Read Case Study"}
          </NavLink>
        </div>
      </div>
    );
  }
};

const MainShimmerItem = () => {
  return (
    <>
      <div className=" animate-pulse h-[450px] mb-3 mt-[-100px] bg-gray-500"></div>
      
        <div className='text-center'>
        <div className="mx-auto w-[800px] h-[60px] mt-[20px] mb-[16px] bg-slate-300 rounded"></div>
        <div className="mx-auto w-[800px] h-[16px] mb-[50px] bg-slate-300 rounded"></div>
      </div>
      <div className="flex mt-6 animate-pulse items-center">
        <div className="flex-[1_1_50%] order-2">
          <div className="w-full aspect-[12/7] bg-slate-300"></div>
        </div>
        <div className="flex-[1_1_50%] p-[80px] order-1">
          <div className="w-full h-[48px] mb-[25px] bg-slate-300 rounded"></div>
          <div className="w-full h-[30px] mb-[50px] bg-slate-300 rounded"></div>
          <div className="w-full h-[16px] mb-[10px] bg-slate-300 rounded"></div>
          <div className="w-full h-[16px] mb-[30px] bg-slate-300 rounded"></div>
          <div className="inline-block w-[170px] rounded h-[40px] bg-slate-300"></div>
        </div>
      </div>
      <div className="flex animate-pulse items-center">
        <div className="flex-[1_1_50%]">
          <div className="w-full aspect-[12/7] bg-slate-300"></div>
        </div>
        <div className="flex-[1_1_50%] p-[80px]">
          <div className="w-full h-[48px] mb-[25px] bg-slate-300 rounded"></div>
          <div className="w-full h-[30px] mb-[50px] bg-slate-300 rounded"></div>
          <div className="w-full h-[16px] mb-[10px] bg-slate-300 rounded"></div>
          <div className="w-full h-[16px] mb-[30px] bg-slate-300 rounded"></div>
          <div className="inline-block w-[170px] rounded h-[40px] bg-slate-300"></div>
        </div>
      </div>
    </>
  );
};
const ShimmerItem = () => {
  return (
    <>
      <div className="flex animate-pulse items-center mt-[-60px]">
        <div className="flex-[1_1_50%] order-2">
          <div className="w-full aspect-[12/7] bg-slate-300"></div>
        </div>
        <div className="flex-[1_1_50%] p-[80px] order-1">
          <div className="w-full h-[48px] mb-[25px] bg-slate-300 rounded"></div>
          <div className="w-full h-[30px] mb-[50px] bg-slate-300 rounded"></div>
          <div className="w-full h-[16px] mb-[10px] bg-slate-300 rounded"></div>
          <div className="w-full h-[16px] mb-[30px] bg-slate-300 rounded"></div>
          <div className="inline-block w-[170px] rounded h-[40px] bg-slate-300"></div>
        </div>
      </div>
      <div className="flex animate-pulse items-center">
        <div className="flex-[1_1_50%]">
          <div className="w-full aspect-[12/7] bg-slate-300"></div>
        </div>
        <div className="flex-[1_1_50%] p-[80px]">
          <div className="w-full h-[48px] mb-[25px] bg-slate-300 rounded"></div>
          <div className="w-full h-[30px] mb-[50px] bg-slate-300 rounded"></div>
          <div className="w-full h-[16px] mb-[10px] bg-slate-300 rounded"></div>
          <div className="w-full h-[16px] mb-[30px] bg-slate-300 rounded"></div>
          <div className="inline-block w-[170px] rounded h-[40px] bg-slate-300"></div>
        </div>
      </div>
    </>
  );
};
const RightShimmerItem = () => {
  return (
    <>
      <div className="flex animate-pulse items-center mt-[-60px]">
        <div className="flex-[1_1_50%]">
          <div className="w-full aspect-[12/7] bg-slate-300"></div>
        </div>
        <div className="flex-[1_1_50%] p-[80px]">
          <div className="w-full h-[48px] mb-[25px] bg-slate-300 rounded"></div>
          <div className="w-full h-[30px] mb-[50px] bg-slate-300 rounded"></div>
          <div className="w-full h-[16px] mb-[10px] bg-slate-300 rounded"></div>
          <div className="w-full h-[16px] mb-[30px] bg-slate-300 rounded"></div>
          <div className="inline-block w-[170px] rounded h-[40px] bg-slate-300"></div>
        </div>
      </div>
      <div className="flex animate-pulse items-center">
        <div className="flex-[1_1_50%] order-2">
          <div className="w-full aspect-[12/7] bg-slate-300"></div>
        </div>
        <div className="flex-[1_1_50%] p-[80px] order-1">
          <div className="w-full h-[48px] mb-[25px] bg-slate-300 rounded"></div>
          <div className="w-full h-[30px] mb-[50px] bg-slate-300 rounded"></div>
          <div className="w-full h-[16px] mb-[10px] bg-slate-300 rounded"></div>
          <div className="w-full h-[16px] mb-[30px] bg-slate-300 rounded"></div>
          <div className="inline-block w-[170px] rounded h-[40px] bg-slate-300"></div>
        </div>
      </div>
    </>
  );
};
const GridShimmerItem = () => {
  return (
    <>
      <div className="px-[80px] flex gap-[20px] mt-[-40px]">
        <div className="mx-auto w-[800px] h-[259px] bg-slate-300 rounded"></div>
        <div className="mx-auto w-[800px] h-[259px]  bg-slate-300 rounded"></div>
        <div className="mx-auto w-[800px] h-[259px]  bg-slate-300 rounded"></div>
      </div>
    </>
  );
};

const ErrorItem = ({ error }) => {
  return (
    <div className="min-h-[500px] flex items-center justify-center">
      <div className="flex-[1_1_100%] text-center p-[48px]">
        <h1 className="text-[48px] font-bold mb-[10px] text-blue-900 leading-[48px]">
          {error?.code}
        </h1>
        <p className="text-[20px] m-0 text-gray-600">{error?.message}</p>
      </div>
    </div>
  );
};

const Portfolio = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(true);
  const [layout, setLayout] = useState(null);
  const [portfolioItems, setPortfolioItems] = useState([]);
  const [offset, setOffset] = useState(3);
  const [hasMore, setHasMore] = useState(false);
  const [metaData, setMetaData] = useState(true);

  const fetchPortfolio = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      offset: offset,
    });
    setIsFetching(true);
    try {
      const res = await axiosInstance.post("/create/portfolios", raw, {
        headers: myHeaders,
      });
      const newItems = res.data.data.list;
      if (!newItems.length) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      setPortfolioItems([
        ...new Map(
          [...portfolioItems, ...newItems].map((item) => [item["nid"], item])
        ).values(),
      ]);
      setData(res.data.data);
      setMetaData(res.data.data?.meta[0]);
      !layout && setLayout(res.data.data.layout_display);
      setIsFetching(false);
    } catch (error) {
      setError(error);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchPortfolio();
  }, [offset]);

  if (isFetching && offset === 3) {
    return (
      <>
        <div className="py-20 text-center">
          <MainShimmerItem />
        </div>
      </>
    );
  }

  if (error) return <ErrorItem error={error} />;

  if (!portfolioItems.length && !isFetching) {
    return <div>No Portfolio items to show!</div>;
  }
  return (
    <>
      <MetaTag metaData={metaData} />
      <section className="dark:bg-dark/80  ">
        {!!data.image ? (
          <div className="banner-section">
            <div className="banner-img">
              <img
                src={processImageUrl(data.image)}
                alt={data?.image_alt}
                className="w-full h-auto"
              />
            </div>
            <div className="banner-content">
              <div className="banner-text">
                <h2>
                  {!!data?.banner_title
                    ? data?.banner_title
                    : "Portfolio Listing"}
                </h2>
                <p
                  dangerouslySetInnerHTML={{ __html: data?.banner_description }}
                ></p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="page-heading text-center lg:pt-[60px] lg:mb-[50px] mb-[20px] lg:px-20">
          <h1 className="text-[#034d82] not-italic lg:text-[64px] text-[38px] font-bold lg:leading-[76px] leading-[50px]">
            {data.title || "Crafted With Love, Built With Passion"}
          </h1>
          <p className="text-lg font-normal text-gray-700 leading-64px pt-[10px] mb-[10px]">
            {data.subtitle ||
              "TechSpotLight offers transformative technology and software solutions to startups, global brands & Fortune 500 companies."}
          </p>
        </div>
        {portfolioItems?.length ? (
          <div className="portfolio-btn-container flex gap-[10px] justify-end pb-[26px]">
            <button
              data-tooltip-id="left-tooltip"
              data-tooltip-content="Left"
              className={`left-btn ${layout === "left" ? "active" : ""}`}
              onClick={() => setLayout("left")}
            ></button>
            <Tooltip id="left-tooltip" />

            <button
              data-tooltip-id="right-tooltip"
              data-tooltip-content="Right"
              className={`right-btn ${layout === "right" ? "active" : ""}`}
              onClick={() => setLayout("right")}
            ></button>
            <Tooltip id="right-tooltip" />

            <button
              data-tooltip-id="grid-tooltip"
              data-tooltip-content="Grid"
              className={`grid-btn ${layout === "grid" ? "active" : ""}`}
              onClick={() => setLayout("grid")}
            ></button>
            <Tooltip id="grid-tooltip" />
          </div>
        ) : (
          ""
        )}
        <div className={`${layout} case-studies lg:pb-[50px] pb-[0]`}>
          <div>
            {portfolioItems.map((item, index) => (
              <PortfolioItem
                key={item.nid}
                info={item}
                cta={data.cta}
                layout_display={layout}
              />
            ))}
          </div>
        </div>
        {isFetching &&
          (layout === "right" ? (
            <div className="py-10 text-center">
              <ShimmerItem />
            </div>
          ) : layout === "left" ? (
            <div className="py-10 text-center">
              <RightShimmerItem />
            </div>
          ) : layout === "grid" ? (
            <div className="py-10 text-center">
              <GridShimmerItem />
            </div>
          ) : null)}
        {hasMore && !isFetching ? (
          <div className="text-center mb-12">
            <button
              className=" load-more-btn inline-block rounded text-white py-4 px-12 mt-[40px]"
              onClick={() => setOffset((prevValue) => prevValue + 3)}
            >
              Load More
            </button>
          </div>
        ) : null}
      </section>
    </>
  );
};

export default Portfolio;
