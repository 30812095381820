import React, { useRef } from 'react'
import { useState } from 'react';
import Select from 'react-select';


const StateList = () => {
  const states = [
    {
        "value": "uttar-pradesh",
        "label": "Uttar Pradesh"
    },
    {
        "value": "uttarakhand",
        "label": "Uttarakhand"
    },
    {
        "value": "andaman-and-nicobar-islands",
        "label": "Andaman and Nicobar Islands"
    },
    {
        "value": "andhra-pradesh",
        "label": "Andhra Pradesh"
    },
    {
        "value": "arunachal-pradesh",
        "label": "Arunachal Pradesh"
    },
    {
        "value": "assam",
        "label": "Assam"
    },
    {
        "value": "bihar",
        "label": "Bihar"
    },
    {
        "value": "chandigarh",
        "label": "Chandigarh"
    },
    {
        "value": "chhattisgarh",
        "label": "Chhattisgarh"
    },
    {
        "value": "dadra-and-nagar-haveli",
        "label": "Dadra and Nagar Haveli"
    },
    {
        "value": "daman-and-diu",
        "label": "Daman and Diu"
    },
    {
        "value": "delhi",
        "label": "Delhi"
    },
    {
        "value": "goa",
        "label": "Goa"
    },
    {
        "value": "gujarat",
        "label": "Gujarat"
    },
    {
        "value": "haryana",
        "label": "Haryana"
    },
    {
        "value": "himachal-pradesh",
        "label": "Himachal Pradesh"
    },
    {
        "value": "jammu-and-kashmir",
        "label": "Jammu and Kashmir"
    },
    {
        "value": "jharkhand",
        "label": "Jharkhand"
    },
    {
        "value": "karnataka",
        "label": "Karnataka"
    },
    {
        "value": "kerala",
        "label": "Kerala"
    },
    {
        "value": "ladakh",
        "label": "Ladakh"
    },
    {
        "value": "lakshadweep",
        "label": "Lakshadweep"
    },
    {
        "value": "madhya-pradesh",
        "label": "Madhya Pradesh"
    },
    {
        "value": "maharashtra",
        "label": "Maharashtra"
    },
    {
        "value": "manipur",
        "label": "Manipur"
    },
    {
        "value": "meghalaya",
        "label": "Meghalaya"
    },
    {
        "value": "mizoram",
        "label": "Mizoram"
    },
    {
        "value": "nagaland",
        "label": "Nagaland"
    },
    {
        "value": "odisha",
        "label": "Odisha"
    },
    {
        "value": "puducherry",
        "label": "Puducherry"
    },
    {
        "value": "punjab",
        "label": "Punjab"
    },
    {
        "value": "rajasthan",
        "label": "Rajasthan"
    },
    {
        "value": "sikkim",
        "label": "Sikkim"
    },
    {
        "value": "tamil-nadu",
        "label": "Tamil Nadu"
    },
    {
        "value": "telangana",
        "label": "Telangana"
    },
    {
        "value": "tripura",
        "label": "Tripura"
    },
    {
        "value": "uttarakhand",
        "label": "Uttarakhand"
    },
    {
        "value": "west-bengal",
        "label": "West Bengal"
    }
];
 const stateRef = useRef(null)
  const [selectedState, setSelectedState] = useState('uttar-pradesh');

  const handleChange = (value) => {
    setSelectedState(value);
    stateRef.current.blur();
  };

  return (
    <Select 
    ref={stateRef}
    className='state-dropdown mt-[5px]'
    defaultValue={states.find(option => option.value === selectedState)}
    onChange={handleChange}
    options={states}
  />

  );
};

export default StateList;