import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import avatatImg from "../../assets/images/avatar.png";
import EditProfileImage from "./EditeProfileImage";
import UserBlogSlider from "./UserBlogSlider";
import UserJobSlider from "./UserJobSlider";
import AppliedJobs from "./AppliedJobs";
import editImg from "../../assets/images/edit.png";
import EditProfile from "./EditProfile";
import {
  DashaboardProfileShimmer,
  RecommendedBlogShimmer,
} from "../global/Loader";
import { processImageUrl } from "../../utils/utils";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const UserProfile = ({
  userInfo,
  getProfile,
  profilePicLoading,
  handleProfilePicLoading,
  jobLocation=[],
  appliedJobs=[],
  interestedBlog=[],
  interestLocationInfo,
  userInfoLoader,
  currentBgColor,
  currentTxtColor,
  currentTileColor,
}) => {
  const navigate = useNavigate();
  const [isEditProfile, setEditeProfile] = useState(false);
  useEffect(() => {
    if (!localStorage.getItem("userInfo")) {
      navigate("/login");
    }
  }, [navigate]);

  const handleEditProfile = (flag) => {
    setEditeProfile(flag);
  };
  
  return (
    <div
      className="py-[50px] lg:py-[150px] md:py-[100px] lg:px-20 user-profile-data"
      style={{ backgroundColor: currentBgColor }}
    >
      <div className="container mx-auto p-[10px] lg:p-[0]">
        {isEditProfile ? (
          <EditProfile
            updateProfile={handleEditProfile}
            getProfile={getProfile}
          />
        ) : userInfoLoader ? (
          <DashaboardProfileShimmer />
        ) : (
          <div
            className="user-container px-[40px] py-[20px] bg-[#f3f4f6]"
            style={{ backgroundColor: currentTileColor }}
          >
            <div className="flex items-center flex-col md:flex-row lg:gap-[110px] md:gap-[30px] gap-[10px]">
              <div className="user-profile-bg ">
                <div className="flex flex-col items-center justify-center relative ">
                  {userInfo?.user_picture === undefined ? (
                    <img
                      src={processImageUrl(avatatImg)}
                      className="rounded-xl"
                    />
                  ) : (
                    <img
                      className="bg-white relative shadow-md border-[3px] border-white max-h-[250px] w-[250px] h-[250px] rounded-[100%]"
                      src={processImageUrl(userInfo?.user_picture)}
                    />
                  )}
                  <EditProfileImage
                    getProfile={getProfile}
                    profilePicLoading={profilePicLoading}
                    handleProfilePicLoading={handleProfilePicLoading}
                  />
                </div>
              </div>

              <div className=" justify-center flex flex-col w-3/4">
                <div className="flex justify-between">
                  <h1 className="font-bold text-4xl text-[#021f5d] pb-[10px] ">
                    {" "}
                    {userInfo?.greet_txt} {userInfo?.name}
                  </h1>
                  <button
                    className="edit-icon w-[24px] h-[24px] lg:w-[20px] lg:h-[20px]"
                    onClick={() => setEditeProfile(true)}
                  >
                    <img src={processImageUrl(editImg)} alt="editImg" />
                  </button>
                </div>
                <h2 className="mt-[15px] font-bold text-xl flex gap-[15px]">
                  {userInfo?.user && (
                    <strong className="profile-heading">User Name: </strong>
                  )}{" "}
                  <span
                    className="text-sky-950"
                    style={{ color: currentTxtColor }}
                  >
                    {userInfo?.user}
                  </span>
                </h2>
                {userInfo?.name && (
                  <h2 className="mt-[15px] font-bold text-xl flex gap-[15px]">
                    <strong className="profile-heading">Name: </strong>{" "}
                    <span
                      className="text-sky-950"
                      style={{ color: currentTxtColor }}
                    >
                      {userInfo?.name}
                    </span>
                  </h2>
                )}
                {userInfo?.mobile && (
                  <h2 className="mt-[15px] font-bold text-xl flex gap-[15px]">
                    <strong className="profile-heading">Mobile: </strong>
                    <span
                      className="text-sky-950"
                      style={{ color: currentTxtColor }}
                    >
                      {userInfo?.mobile}
                    </span>
                  </h2>
                )}
                {interestLocationInfo?.location && (
                  <h2 className="mt-[15px] font-bold text-xl flex gap-[15px]">
                    <strong className="profile-heading">Location: </strong>
                    <span
                      className="text-sky-950"
                      style={{ color: currentTxtColor }}
                    >
                      {" "}
                      {interestLocationInfo?.location}
                    </span>
                  </h2>
                )}
                {interestLocationInfo?.interest && (
                  <h2 className="mt-[15px] font-bold text-xl flex gap-[15px]">
                    <strong className="profile-heading">Interested In: </strong>
                    <span
                      className="text-sky-950"
                      style={{ color: currentTxtColor }}
                    >
                      {" "}
                      {interestLocationInfo.interest
                        .split(",")
                        .map((item, index) => (
                          <span key={index}>
                            {" "}
                            {index > 0 && ", "} {item.trim()}{" "}
                          </span>
                        ))}
                    </span>
                  </h2>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="container mx-auto user-profile-sliders pt-[50px]">
        <Tabs>
          <TabList>
            <Tab>Recommended Blogs</Tab>
            <Tab>Recommended Jobs</Tab>
            <Tab>Applied Jobs</Tab>
          </TabList>

          <TabPanel>
            {userInfoLoader ? (
              <RecommendedBlogShimmer />
            ) : interestedBlog.length > 0 ? (
              <>
                <UserBlogSlider interestedBlog={interestedBlog} />
              </>
            ) : null}
          </TabPanel>
          <TabPanel>
            {userInfoLoader ? (
              <RecommendedBlogShimmer />
            ) : jobLocation.length > 0 ? (
              <>
                <UserJobSlider jobLocation={jobLocation} />{" "}
              </>
            ) : null}
          </TabPanel>
          <TabPanel>
            {userInfoLoader ? (
              <RecommendedBlogShimmer />
            ) : appliedJobs.length > 0 ? (
              <>
                <AppliedJobs appliedJobs={appliedJobs} />{" "}
              </>
            ) : null}
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default UserProfile;
