import React from "react";
import '../../assets/scss/common.scss'
import Img from '../../assets/images/avatar.png'

export default function LoadingSpinner() {
  return (
    <div className="spinner-container">
      <div className="loading-spinner-outer">
        <div className="loading-spinner">
        </div>
      </div>
    </div>
  );
}


export function LoadingWait() {
  return (
    <div className="spinner-container login-waiting-loader">
      <div className="loading-spinner-outer">
        <div className="loading-spinner">
        </div>
      </div>
    </div>
  )
}


/*
Function to blur the content before the loading 
*/
export function ShimmerItem() {
  return (
    <>
      <div className='text-center'>
        <div className="mx-auto w-[800px] h-[60px] mt-[80px] mb-[16px] bg-slate-300 rounded"></div>
        <div className="mx-auto w-[800px] h-[16px] mb-[50px] bg-slate-300 rounded"></div>
      </div>
      <div className='flex animate-pulse items-center'>
        <div className='flex-[1_1_50%] order-2'>
          <div className='w-full aspect-[12/7] bg-slate-300'></div>
        </div>
        <div className='flex-[1_1_50%] p-[80px] order-1'>
          <div className="w-full h-[48px] mb-[25px] bg-slate-300 rounded"></div>
          <div className="w-full h-[30px] mb-[50px] bg-slate-300 rounded"></div>
          <div className="w-full h-[16px] mb-[10px] bg-slate-300 rounded"></div>
          <div className="w-full h-[16px] mb-[30px] bg-slate-300 rounded"></div>
          <div className="inline-block w-[170px] rounded h-[40px] bg-slate-300"></div>
        </div>
      </div>
      <div className='flex animate-pulse items-center'>
        <div className='flex-[1_1_50%]'>
          <div className='w-full aspect-[12/7] bg-slate-300'></div>
        </div>
        <div className='flex-[1_1_50%] p-[80px]'>
          <div className="w-full h-[48px] mb-[25px] bg-slate-300 rounded"></div>
          <div className="w-full h-[30px] mb-[50px] bg-slate-300 rounded"></div>
          <div className="w-full h-[16px] mb-[10px] bg-slate-300 rounded"></div>
          <div className="w-full h-[16px] mb-[30px] bg-slate-300 rounded"></div>
          <div className="inline-block w-[170px] rounded h-[40px] bg-slate-300"></div>
        </div>
      </div>
    </>
  )
}

// dashaboard shimmer 
export function RecommendedBlogShimmer() {
  return (
    <div className="container mx-auto">
      <div className="flex flex-wrap -m-4">
        <div className="p-4 md:w-1/4">
          <div className="h-full border-2 border-gray-200 rounded-lg overflow-hidden">
            <div className="lg:h-48 bg-gray-400 md:h-36 w-full object-cover object-center"></div>
            <div className="p-6">
              <h2 className="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></h2>
              <h1 className="w-1/2 mb-4 h-6 animate-pulse bg-gray-500"></h1>
              <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
              <p className="leading-relaxed mb-3 w-2/3 h-3 animate-pulse bg-gray-400"></p>
              <p className="leading-relaxed mb-3 w-1/2 h-3 animate-pulse bg-gray-400"></p>
              <div className="flex items-center flex-wrap ">
                <a className="bg-indigo-300 h-4 animate-pulse mt-2 w-32 inline-flex items-center md:mb-2 lg:mb-0">

                </a>
                <span className="bg-indigo-300 w-16 mt-2 h-4 animate-pulse mr-3 px-2 inline-flex items-center ml-auto leading-none text-sm pr-5 py-1">

                </span>

              </div>
            </div>
          </div>
        </div>
        <div className="p-4 md:w-1/4">
          <div className="h-full border-2 border-gray-200 rounded-lg overflow-hidden">
            <div className="lg:h-48 bg-gray-400 md:h-36 w-full object-cover object-center"></div>
            <div className="p-6">
              <h2 className="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></h2>
              <h1 className="w-1/2 mb-4 h-6 animate-pulse bg-gray-500"></h1>
              <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
              <p className="leading-relaxed mb-3 w-2/3 h-3 animate-pulse bg-gray-400"></p>
              <p className="leading-relaxed mb-3 w-1/2 h-3 animate-pulse bg-gray-400"></p>
              <div className="flex items-center flex-wrap ">
                <a className="bg-indigo-300 h-4 animate-pulse mt-2 w-32 inline-flex items-center md:mb-2 lg:mb-0">

                </a>
                <span className="bg-indigo-300 w-16 mt-2 h-4 animate-pulse mr-3 px-2 inline-flex items-center ml-auto leading-none text-sm pr-5 py-1">

                </span>

              </div>
            </div>
          </div>
        </div>
        <div className="p-4 md:w-1/4">
          <div className="h-full border-2 border-gray-200 rounded-lg overflow-hidden">
            <div className="lg:h-48 bg-gray-400 md:h-36 w-full object-cover object-center"></div>
            <div className="p-6">
              <h2 className="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></h2>
              <h1 className="w-1/2 mb-4 h-6 animate-pulse bg-gray-500"></h1>
              <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
              <p className="leading-relaxed mb-3 w-2/3 h-3 animate-pulse bg-gray-400"></p>
              <p className="leading-relaxed mb-3 w-1/2 h-3 animate-pulse bg-gray-400"></p>
              <div className="flex items-center flex-wrap ">
                <a className="bg-indigo-300 h-4 animate-pulse mt-2 w-32 inline-flex items-center md:mb-2 lg:mb-0">

                </a>
                <span className="bg-indigo-300 w-16 mt-2 h-4 animate-pulse mr-3 px-2 inline-flex items-center ml-auto leading-none text-sm pr-5 py-1">

                </span>

              </div>
            </div>
          </div>
        </div>
        <div className="p-4 md:w-1/4">
          <div className="h-full border-2 border-gray-200 rounded-lg overflow-hidden">
            <div className="lg:h-48 bg-gray-400 md:h-36 w-full object-cover object-center"></div>
            <div className="p-6">
              <h2 className="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></h2>
              <h1 className="w-1/2 mb-4 h-6 animate-pulse bg-gray-500"></h1>
              <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
              <p className="leading-relaxed mb-3 w-2/3 h-3 animate-pulse bg-gray-400"></p>
              <p className="leading-relaxed mb-3 w-1/2 h-3 animate-pulse bg-gray-400"></p>
              <div className="flex items-center flex-wrap ">
                <a className="bg-indigo-300 h-4 animate-pulse mt-2 w-32 inline-flex items-center md:mb-2 lg:mb-0">

                </a>
                <span className="bg-indigo-300 w-16 mt-2 h-4 animate-pulse mr-3 px-2 inline-flex items-center ml-auto leading-none text-sm pr-5 py-1">

                </span>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export function DashaboardProfileShimmer() {
  return (
    <div className="container mx-auto mb-20">
      <div className='text-center'>
        <div className="bg-[#f3f4f6] rounded-lg">
          <div className="flex items-center flex-col md:flex-row lg:gap-[50px] md:gap-[30px] gap-[10px] border-2 border-gray-200 rounded-lg p-3">
            <div className="lg:h-48 md:h-36 object-cover object-center flex flex-col items-center justify-center relative "><img src={Img} className="rounded-full w-[244px] h-[244px]"></img></div>
            <div className="p-6 justify-center flex flex-col w-1/3">
              <h1 className="bg-gray-400 animate-pulse mb-2 h-8 font-bold text-4xl text-[#021f5d] pb-[10px] "></h1>
              <p className="w-full mb-5 h-4 animate-pulse bg-gray-500 mt-[15px] font-bold text-xl flex gap-[15px]"></p>
              <p className="leading-relaxed mb-5 w-full h-4 animate-pulse bg-gray-400"></p>
              <p className="leading-relaxed mb-5 w-full h-4 animate-pulse bg-gray-400"></p>
              <p className="leading-relaxed mb-5 w-full h-4 animate-pulse bg-gray-400"></p>
              <p className="leading-relaxed mb-5 w-full h-4 animate-pulse bg-gray-400"></p>
              <div className=" w-full">
                <a className="bg-gray-400 h-4 animate-pulse mt-2 w-32 md:mb-2 lg:mb-0 w-full"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export function DashaboardProfileWhithoutImgShimmer() {
  return (
    <div className="container mx-auto bg-[#f3f4f6] rounded-lg">
      <div className='text-center'>
        <div className="md:w-90">
          <div className="flex items-center flex-col md:flex-row lg:gap-[50px] md:gap-[30px] gap-[10px] p-3">
            <div className="p-6 justify-center flex flex-col w-full bg-[#f3f4f6">
              <div className="flex">
                <p className="w-full mb-5 h-4 animate-pulse bg-gray-500 font-bold text-xl flex gap-[15px] w-1/2 mr-2"></p>
                <p className="leading-relaxed mb-5 w-full h-4 animate-pulse bg-gray-400 w-1/2 ml-2"></p>
              </div>
              <div className="flex">
                <p className="w-full mb-5 h-4 animate-pulse bg-gray-500 font-bold text-xl flex gap-[15px] w-1/2 mr-2"></p>
                <p className="leading-relaxed mb-5 w-full h-4 animate-pulse bg-gray-400 w-1/2 ml-2"></p>
              </div>
              <div className="flex">
                <p className="w-full mb-5 h-4 animate-pulse bg-gray-500 font-bold text-xl flex gap-[15px] w-1/2 mr-2"></p>
                <p className="leading-relaxed mb-5 w-full h-4 animate-pulse bg-gray-400 w-1/2 ml-2"></p>
              </div>
              <div className="flex">
                <p className="w-full mb-5 h-4 animate-pulse bg-gray-500 font-bold text-xl flex gap-[15px] w-1/2 mr-2"></p>
                <p className="leading-relaxed mb-5 w-full h-4 animate-pulse bg-gray-400 w-1/2 ml-2"></p>
              </div>
              <div className="flex">
                <p className="w-full mb-5 h-4 animate-pulse bg-gray-500 font-bold text-xl flex gap-[15px] w-1/2 mr-2"></p>
                <p className="leading-relaxed mb-5 w-full h-4 animate-pulse bg-gray-400 w-1/2 ml-2"></p>
              </div>
              <div className="flex">
                <p className="w-full mb-5 h-4 animate-pulse bg-gray-500 font-bold text-xl flex gap-[15px] w-1/2 mr-2"></p>
                <p className="leading-relaxed mb-5 w-full h-4 animate-pulse bg-gray-400 w-1/2 ml-2"></p>
              </div>
              <div className="flex">
                <p className="w-full mb-5 h-4 animate-pulse bg-gray-500 font-bold text-xl flex gap-[15px] w-1/2 mr-2"></p>
                <p className="leading-relaxed mb-5 w-full h-4 animate-pulse bg-gray-400 w-1/2 ml-2"></p>
              </div>
              <div className=" w-full">
                <a className="bg-gray-400 h-4 animate-pulse mt-2 w-32 md:mb-2 lg:mb-0 w-full"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export function DashboardShimmerItem() {
  return (
    <>
      <div className="container mx-auto">
        <div className='text-center py-[50px] lg:py-[150px] md:py-[100px]'>
          <div className="md:w-90">
            <div className="flex items-center flex-col md:flex-row lg:gap-[50px] md:gap-[30px] gap-[10px] border-2 border-gray-200 rounded-lg p-3">
              <div className="lg:h-48 md:h-36 object-cover object-center flex flex-col items-center justify-center relative "><img src={Img} className="rounded-full w-[244px] h-[244px]"></img></div>
              <div className="p-6 justify-center flex flex-col w-1/3">
                <h1 className="bg-gray-400 animate-pulse mb-2 h-8 font-bold text-4xl text-[#021f5d] pb-[10px] "></h1>
                <p className="w-full mb-5 h-4 animate-pulse bg-gray-500 mt-[15px] font-bold text-xl flex gap-[15px]"></p>
                <p className="leading-relaxed mb-5 w-full h-4 animate-pulse bg-gray-400"></p>
                <p className="leading-relaxed mb-5 w-full h-4 animate-pulse bg-gray-400"></p>
                <p className="leading-relaxed mb-5 w-full h-4 animate-pulse bg-gray-400"></p>
                <p className="leading-relaxed mb-5 w-full h-4 animate-pulse bg-gray-400"></p>
                <div className=" w-full">
                  <a className="bg-gray-400 h-4 animate-pulse mt-2 w-32 md:mb-2 lg:mb-0 w-full"></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        <div className="flex flex-wrap -m-4">
          <div className="p-4 md:w-1/4">
            <div className="h-full border-2 border-gray-200 rounded-lg overflow-hidden">
              <div className="lg:h-48 bg-gray-400 md:h-36 w-full object-cover object-center"></div>
              <div className="p-6">
                <h2 className="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></h2>
                <h1 className="w-1/2 mb-4 h-6 animate-pulse bg-gray-500"></h1>
                <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                <p className="leading-relaxed mb-3 w-2/3 h-3 animate-pulse bg-gray-400"></p>
                <p className="leading-relaxed mb-3 w-1/2 h-3 animate-pulse bg-gray-400"></p>
                <div className="flex items-center flex-wrap ">
                  <a className="bg-indigo-300 h-4 animate-pulse mt-2 w-32 inline-flex items-center md:mb-2 lg:mb-0">

                  </a>
                  <span className="bg-indigo-300 w-16 mt-2 h-4 animate-pulse mr-3 px-2 inline-flex items-center ml-auto leading-none text-sm pr-5 py-1">

                  </span>

                </div>
              </div>
            </div>
          </div>
          <div className="p-4 md:w-1/4">
            <div className="h-full border-2 border-gray-200 rounded-lg overflow-hidden">
              <div className="lg:h-48 bg-gray-400 md:h-36 w-full object-cover object-center"></div>
              <div className="p-6">
                <h2 className="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></h2>
                <h1 className="w-1/2 mb-4 h-6 animate-pulse bg-gray-500"></h1>
                <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                <p className="leading-relaxed mb-3 w-2/3 h-3 animate-pulse bg-gray-400"></p>
                <p className="leading-relaxed mb-3 w-1/2 h-3 animate-pulse bg-gray-400"></p>
                <div className="flex items-center flex-wrap ">
                  <a className="bg-indigo-300 h-4 animate-pulse mt-2 w-32 inline-flex items-center md:mb-2 lg:mb-0">

                  </a>
                  <span className="bg-indigo-300 w-16 mt-2 h-4 animate-pulse mr-3 px-2 inline-flex items-center ml-auto leading-none text-sm pr-5 py-1">

                  </span>

                </div>
              </div>
            </div>
          </div>
          <div className="p-4 md:w-1/4">
            <div className="h-full border-2 border-gray-200 rounded-lg overflow-hidden">
              <div className="lg:h-48 bg-gray-400 md:h-36 w-full object-cover object-center"></div>
              <div className="p-6">
                <h2 className="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></h2>
                <h1 className="w-1/2 mb-4 h-6 animate-pulse bg-gray-500"></h1>
                <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                <p className="leading-relaxed mb-3 w-2/3 h-3 animate-pulse bg-gray-400"></p>
                <p className="leading-relaxed mb-3 w-1/2 h-3 animate-pulse bg-gray-400"></p>
                <div className="flex items-center flex-wrap ">
                  <a className="bg-indigo-300 h-4 animate-pulse mt-2 w-32 inline-flex items-center md:mb-2 lg:mb-0">

                  </a>
                  <span className="bg-indigo-300 w-16 mt-2 h-4 animate-pulse mr-3 px-2 inline-flex items-center ml-auto leading-none text-sm pr-5 py-1">

                  </span>

                </div>
              </div>
            </div>
          </div>
          <div className="p-4 md:w-1/4">
            <div className="h-full border-2 border-gray-200 rounded-lg overflow-hidden">
              <div className="lg:h-48 bg-gray-400 md:h-36 w-full object-cover object-center"></div>
              <div className="p-6">
                <h2 className="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></h2>
                <h1 className="w-1/2 mb-4 h-6 animate-pulse bg-gray-500"></h1>
                <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                <p className="leading-relaxed mb-3 w-2/3 h-3 animate-pulse bg-gray-400"></p>
                <p className="leading-relaxed mb-3 w-1/2 h-3 animate-pulse bg-gray-400"></p>
                <div className="flex items-center flex-wrap ">
                  <a className="bg-indigo-300 h-4 animate-pulse mt-2 w-32 inline-flex items-center md:mb-2 lg:mb-0">

                  </a>
                  <span className="bg-indigo-300 w-16 mt-2 h-4 animate-pulse mr-3 px-2 inline-flex items-center ml-auto leading-none text-sm pr-5 py-1">

                  </span>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

