import { Provider } from 'react-redux';
import './App.css';
import CustomRouter from './routers/Router';
import { store } from './store/store';
import Loader from '../src/components/global/Loader';
import MetaTag from '../src/components/global/MetaTag';
import React, { useEffect, useState } from 'react';
function App() {
  const [isLoading, setIsLoading] = useState(true);
    
      useEffect(() => {
        // Simulate an API call
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }, []);
    
  return (
    <>
    {isLoading ? (
      <Loader />
  ) : (
    <Provider store={store}>
      <MetaTag metaData={''}/>
    <CustomRouter />
   </Provider>
  )}
   
  
  </>
  )
}

export default App;
