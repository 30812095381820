import axiosInstance from "../../services/axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../assets/scss/footer.scss";
import { useSelector } from "react-redux";

const Footer = () => {
  const [footer, setFooter] = useState([]);
  const [aboutFooter, setAboutFooter] = useState([]);

  const footerSocial = useSelector((state) => state.siteConfig.footer_social);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get("/read/menu/footer");
        setFooter(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchFooterAboutData = () => {
      axiosInstance
        .get("/read/footer-about-us")
        .then((response) => {
          setAboutFooter(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };
    fetchFooterAboutData();
  }, []);

  const handleScrollToTop = () => {
    const header = document.querySelector("body");

    if (header) {
      header.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <footer className=" home-footer text-center text-surface/75 dark:bg-neutral-700 dark:text-white/75 lg:text-left bg-[#071315] text-white lg:p-10 lg:px-20">
      <div>
        {aboutFooter.data && aboutFooter.data.length > 0 && (
          <div dangerouslySetInnerHTML={{ __html: aboutFooter.data[0].body }} />
        )}
      </div>
      <div className="mx-6 pt-10 text-center md:text-left">
        <div className="grid-1 grid gap-8 grid-cols-2 lg:grid-cols-4 lg:px-10 justify-items-center">
          {footer.length > 0 &&
            footer.map((item, index) => (
              <ul key={index}>
                <h6 className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
                  {item.link.url === "" ? (
                    item.link.title
                  ) : (
                    <Link
                      to={item.link.url}
                      target={item.link.options?.external && "_blank"}
                      onClick={handleScrollToTop}
                    >
                      {item.link.title}
                    </Link>
                  )}
                </h6>
                {item.subtree.map((subItem, subIndex) => (
                  <li
                    key={subIndex}
                    className="mb-4 text-[#b4b3b3] hover:text-[#fff]"
                  >
                    <Link to={subItem.link.url} onClick={handleScrollToTop}>
                      {subItem.link.title}
                    </Link>
                  </li>
                ))}
              </ul>
            ))}
        </div>
      </div>

      <div className="flex flex-col items-center justify-center border-b border-neutral-200 p-6 dark:border-white/10 lg:justify-between">
        <div className="m-4 mt-0 hidden lg:block">
          <span className="text-[18px] font-semibold">
            {footerSocial?.heading}
          </span>
        </div>

        <div className="flex justify-center gap-2 items-center">
          {typeof footerSocial.links !== "undefined" &&
            footerSocial.links.length > 0 &&
            footerSocial.links.map((item) => (
              <Link
                key={item.name}
                to={item.url}
                target="_blank"
                className="rounded-full bg-opacity-30 p-3 flex items-center text-white"
              >
                <img
                  src={item?.icon}
                  alt={item?.name}
                  className=" max-h-[600px] w-[36px] h-[36px] rounded-[100%]"
                />
              </Link>
            ))}
        </div>
      </div>
      <div className="bg-black/5 p-2  md:pb-0 lg:pb-0 text-center">
        <span>© 2024 Copyright: </span>
        <Link className="font-semibold" to="/">
          TechSpotLight
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
