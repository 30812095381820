import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import CardList from "../../components/blog/CardList";
import Left from "../../components/blog/Left";
import "../../assets/scss/pagination.scss";
import axiosInstance from "../../services/axios";
import { ErrorItem } from "../../components/global/ErrorPage";
import MetaTag from "../../components/global/MetaTag";
import InfiniteScroll from "react-infinite-scroller";

const Blog = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const [searchTitle, setSearchTitle] = useState("");
  const [prevData, setPrevData] = useState([]);
  const [filter, setFilter] = useState({
    title: "",
    category: [],
    currentPage: 0,
  });

  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location?.search);
  const categoryIdFromUrl = query.get('cid');
  const [checkedCategories, setCheckedCategories] = useState([]);
  const scrollTopBlogRef = useRef('');

  useEffect(() => {
    if (categoryIdFromUrl) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        category: [categoryIdFromUrl],
        currentPage: 0,
      }));
    } else {
      setFilter((prevFilter) => ({
        ...prevFilter,
        category: [],
        currentPage: 0,
      }));
    }
  }, [categoryIdFromUrl]);

  useEffect(() => {
    setCheckedCategories(filter?.category);
  }, [filter?.category]);
  
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  
  useEffect(() => {
    const handler = setTimeout(() => {
      setFilter((prevFilter) => ({
        ...prevFilter,
        title: searchTitle,
        currentPage: 0
      }));
    }, 500); 

    return () => {
      clearTimeout(handler);
    };
  }, [searchTitle]);

  const fetchPosts = async () => {
    if (loading) return;   
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const itemsPerPage = isNaN(parseInt(data?.pager?.items_per_page)) ? 0 : parseInt(data?.pager?.items_per_page);
    const offset = parseInt(filter?.currentPage) * itemsPerPage;
    const raw = JSON.stringify({ title: filter?.title, tid: filter?.category, offset: offset });
    try {
      const res = await axiosInstance.post("/read/blog-listing", raw, { headers: myHeaders });
      setPrevData(prev => filter?.currentPage === 0 ? res?.data?.data?.list : [...prev, ...res.data?.data?.list]);
      setData(res?.data?.data);
      setFirstLoad(true);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
    
  useEffect(() => {
    setLoading(true);
  }, [filter?.category, filter?.title, searchTitle]);

  useEffect(() => {
    fetchPosts();
  }, [filter]);

  const handleFilterChange = (categoryId) => {
    navigate(`/blogs?cid=${categoryId}`);
    if (scrollTopBlogRef?.current) {
      scrollTopBlogRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
   };
  
 const onLoadHandler = () => {
  setTimeout(() => {
    setFilter({ ...filter, currentPage: filter.currentPage + 1 });
  }, 1000);
};
 const handleCheckedItem = () => {
    setFilter(prevFilter => ({
      ...prevFilter,
      category: [],
      currentPage: 0
    }));
    fetchPosts();
    setIsVisible(false);
  };

  const handleInputChange = (event) => {
    const { type, value, checked } = event.target;
    if (type === "search") {
      setSearchTitle(value);
    } else {
      setFilter(prevFilter => ({
        ...prevFilter,
        category: checked
          ? [...prevFilter?.category, value]
          : prevFilter?.category.filter(item => item !== value),
        currentPage: 0
      }));
    }
  };
 
  const MainShimmerItem = () => {
    return (
      <>
      <div className=" animate-pulse h-[450px] mb-3 mt-[-100px] bg-gray-500"></div>

        <div className="text-center">
          <div className="mx-auto w-[800px] h-[60px] mt-[20px] mb-[16px] bg-slate-300 rounded"></div>
          <div className="mx-auto w-[800px] h-[16px] mb-[50px] bg-slate-300 rounded"></div>
        </div>
        <div className="container mx-auto mt-6">
          <div className="flex flex-wrap -m-4">
            <div className="p-4 md:w-[33.33%]">
              <div className="h-full border-2 border-gray-200 rounded-lg overflow-hidden">
                <div className="lg:h-48 bg-gray-400 md:h-36 w-full object-cover object-center"></div>
                <div className="p-6">
                  <h2 className="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></h2>
                  <h1 className="w-1/2 mb-4 h-6 animate-pulse bg-gray-500"></h1>
                  <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                  <p className="leading-relaxed mb-3 w-2/3 h-3 animate-pulse bg-gray-400"></p>
                  <p className="leading-relaxed mb-3 w-1/2 h-3 animate-pulse bg-gray-400"></p>
                  <div className="flex items-center flex-wrap ">
                    <a className="bg-indigo-300 h-4 animate-pulse mt-2 w-32 inline-flex items-center md:mb-2 lg:mb-0"></a>
                    <span className="bg-indigo-300 w-16 mt-2 h-4 animate-pulse mr-3 px-2 inline-flex items-center ml-auto leading-none text-sm pr-5 py-1"></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-4 md:w-[33.33%]">
              <div className="h-full border-2 border-gray-200 rounded-lg overflow-hidden">
                <div className="lg:h-48 bg-gray-400 md:h-36 w-full object-cover object-center"></div>
                <div className="p-6">
                  <h2 className="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></h2>
                  <h1 className="w-1/2 mb-4 h-6 animate-pulse bg-gray-500"></h1>
                  <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                  <p className="leading-relaxed mb-3 w-2/3 h-3 animate-pulse bg-gray-400"></p>
                  <p className="leading-relaxed mb-3 w-1/2 h-3 animate-pulse bg-gray-400"></p>
                  <div className="flex items-center flex-wrap ">
                    <a className="bg-indigo-300 h-4 animate-pulse mt-2 w-32 inline-flex items-center md:mb-2 lg:mb-0"></a>
                    <span className="bg-indigo-300 w-16 mt-2 h-4 animate-pulse mr-3 px-2 inline-flex items-center ml-auto leading-none text-sm pr-5 py-1"></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-4 md:w-[33.33%]">
              <div className="h-full border-2 border-gray-200 rounded-lg overflow-hidden">
                <div className="lg:h-48 bg-gray-400 md:h-36 w-full object-cover object-center"></div>
                <div className="p-6">
                  <h2 className="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></h2>
                  <h1 className="w-1/2 mb-4 h-6 animate-pulse bg-gray-500"></h1>
                  <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                  <p className="leading-relaxed mb-3 w-2/3 h-3 animate-pulse bg-gray-400"></p>
                  <p className="leading-relaxed mb-3 w-1/2 h-3 animate-pulse bg-gray-400"></p>
                  <div className="flex items-center flex-wrap ">
                    <a className="bg-indigo-300 h-4 animate-pulse mt-2 w-32 inline-flex items-center md:mb-2 lg:mb-0"></a>
                    <span className="bg-indigo-300 w-16 mt-2 h-4 animate-pulse mr-3 px-2 inline-flex items-center ml-auto leading-none text-sm pr-5 py-1"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const ShimmerItem = () => {
    return (
      <>
        <div className="container mx-auto mt-6">
          <div className="flex flex-wrap -m-4">
            <div className="p-4 md:w-[33.33%]">
              <div className="h-full border-2 border-gray-200 rounded-lg overflow-hidden">
                <div className="lg:h-48 bg-gray-400 md:h-36 w-full object-cover object-center"></div>
                <div className="p-6">
                  <h2 className="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></h2>
                  <h1 className="w-1/2 mb-4 h-6 animate-pulse bg-gray-500"></h1>
                  <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                  <p className="leading-relaxed mb-3 w-2/3 h-3 animate-pulse bg-gray-400"></p>
                  <p className="leading-relaxed mb-3 w-1/2 h-3 animate-pulse bg-gray-400"></p>
                  <div className="flex items-center flex-wrap ">
                    <a className="bg-indigo-300 h-4 animate-pulse mt-2 w-32 inline-flex items-center md:mb-2 lg:mb-0"></a>
                    <span className="bg-indigo-300 w-16 mt-2 h-4 animate-pulse mr-3 px-2 inline-flex items-center ml-auto leading-none text-sm pr-5 py-1"></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-4 md:w-[33.33%]">
              <div className="h-full border-2 border-gray-200 rounded-lg overflow-hidden">
                <div className="lg:h-48 bg-gray-400 md:h-36 w-full object-cover object-center"></div>
                <div className="p-6">
                  <h2 className="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></h2>
                  <h1 className="w-1/2 mb-4 h-6 animate-pulse bg-gray-500"></h1>
                  <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                  <p className="leading-relaxed mb-3 w-2/3 h-3 animate-pulse bg-gray-400"></p>
                  <p className="leading-relaxed mb-3 w-1/2 h-3 animate-pulse bg-gray-400"></p>
                  <div className="flex items-center flex-wrap ">
                    <a className="bg-indigo-300 h-4 animate-pulse mt-2 w-32 inline-flex items-center md:mb-2 lg:mb-0"></a>
                    <span className="bg-indigo-300 w-16 mt-2 h-4 animate-pulse mr-3 px-2 inline-flex items-center ml-auto leading-none text-sm pr-5 py-1"></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-4 md:w-[33.33%]">
              <div className="h-full border-2 border-gray-200 rounded-lg overflow-hidden">
                <div className="lg:h-48 bg-gray-400 md:h-36 w-full object-cover object-center"></div>
                <div className="p-6">
                  <h2 className="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></h2>
                  <h1 className="w-1/2 mb-4 h-6 animate-pulse bg-gray-500"></h1>
                  <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                  <p className="leading-relaxed mb-3 w-2/3 h-3 animate-pulse bg-gray-400"></p>
                  <p className="leading-relaxed mb-3 w-1/2 h-3 animate-pulse bg-gray-400"></p>
                  <div className="flex items-center flex-wrap ">
                    <a className="bg-indigo-300 h-4 animate-pulse mt-2 w-32 inline-flex items-center md:mb-2 lg:mb-0"></a>
                    <span className="bg-indigo-300 w-16 mt-2 h-4 animate-pulse mr-3 px-2 inline-flex items-center ml-auto leading-none text-sm pr-5 py-1"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  if (error) return <ErrorItem error={error} />;

  if (!firstLoad) {
    return (
      <div className="py-20 text-center">
        <MainShimmerItem />
      </div>
    );
  }

  return (
    <>
      <MetaTag metaData={data?.meta[0]} />
      <div className="overflow-hidden">
        {!!data?.image ? (
          <div className="banner-section">
            <div className="banner-img">
              <img
                src={data?.image}
                alt={data?.image_alt}
                className="w-full "
              />
            </div>
            <div className="banner-content">
              <div className="banner-text">
                <h2>
                  {!!data?.banner_title ? data?.banner_title : "Blog Listing"}
                </h2>
                <p
                  dangerouslySetInnerHTML={{ __html: data?.banner_description }}
                ></p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="text-center pb-[20px] lg:px-20 lg:pt-[95px] px-[20px] md:px-[20px]">
          <h2 className="text-[#034d82] text-center not-italic lg:text-[64px] text-[38px] font-bold lg:leading-[76px] leading-[50px] ">
            {!!data?.title ? data?.title : "Curated From Our Top Writers"}
          </h2>
          <p className="text-center text-lg font-normal text-gray-700 leading-64px pt-[10px] mb-[10px]">
            {!!data?.subtitle
              ? data?.subtitle
              : "Read best quality content,updates & news from top rated TechSpotLight's team"}
          </p>
        </div>
        <section className="bg-white pb-10 pt-2 lg:px-20 lg:pb-20" ref={scrollTopBlogRef}>
          <div className="block md:block lg:flex flex-col">
            <div className="search-container pb-[30px] lg:pb-[50px] px-[20px] lg:px-[0]">
              <div className="search__bar">
                <form name="search" onSubmit={(e) => e.preventDefault()}>
                  <input
                    type="search"
                    className="search__term"
                    name="title"
                    placeholder="Search"
                    onChange={handleInputChange}
                    value={searchTitle}
                  />
                </form>
              </div>
              <div className="search-filter relative">
                <button
                  className="text-[#034d82] font-bold flex items-center gap-3"
                  onClick={toggleVisibility}
                >
                  {isVisible ? (
                    <>
                      <span>Filter</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        height="24"
                        width="24"
                      >
                        <g fill="#034d82" fillRule="nonzero">
                          <path d="m12 22c5.5228475 0 10-4.4771525 10-10s-4.4771525-10-10-10-10 4.4771525-10 10 4.4771525 10 10 10zm0 2c-6.627417 0-12-5.372583-12-12s5.372583-12 12-12 12 5.372583 12 12-5.372583 12-12 12z"></path>
                          <path d="m9.20710678 16.2071068c-.39052429.3905243-1.02368927.3905243-1.41421356 0s-.39052429-1.0236893 0-1.4142136l6.99999998-6.99999998c.3905243-.39052429 1.0236893-.39052429 1.4142136 0s.3905243 1.02368927 0 1.41421356z"></path>
                          <path d="m7.79289322 9.20710678c-.39052429-.39052429-.39052429-1.02368927 0-1.41421356s1.02368927-.39052429 1.41421356 0l7.00000002 6.99999998c.3905243.3905243.3905243 1.0236893 0 1.4142136s-1.0236893.3905243-1.4142136 0z"></path>
                        </g>
                      </svg>
                    </>
                  ) : (
                    <>
                      <span>Filter</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 17 14"
                        className="w-5 h-5"
                        aria-hidden="true"
                        fill="none"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M1 1h15M1 7h15M1 13h15"
                        ></path>
                      </svg>
                    </>
                  )}
                </button>
                {isVisible && (
                  <div className="category-details w-full lg:w-[26%] bg-white absolute top-[36px] left-0 z-10">
                    <div className="category-detail-container flex flex-wrap justify-between gap-[26px] px-[20px] py-[20px]  rounded-[4px] ">
                      <Left
                        categories={data}
                        handleInputChange={handleInputChange}
                        selectedCategory={filter?.category}
                      />
                    </div>
                    <div className="px-[20px] py-[20px] text-right">
                      <button
                        className="check-btn w-[75px] hover:font-extrabold font-normal text-[12px] text-white pr-[15px] pl-[15px] py-[9px] uppercase"
                        onClick={handleCheckedItem}
                      >
                        RESET
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <main role="main" className="w-full">
              {loading ? (
                <ShimmerItem />
              ) : prevData?.length ? (
                <InfiniteScroll
                  pageStart={0}
                  loadMore={onLoadHandler}
                  hasMore={
                    filter.currentPage * parseInt(data?.pager?.items_per_page) <
                    parseInt(data?.pager?.total_items)
                  }
                  loader={<ShimmerItem key="loader" />}
                >
                  <CardList handleFilterChange={handleFilterChange} posts={prevData} loading={loading} />
                </InfiniteScroll>
              ) : (
                "No Blogs items to show!"
              )}
            </main>
          </div>
        </section>
      </div>
    </>
  );
};

export default Blog;
