import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import "../../assets/scss/glossary.scss";
import axiosInstance from '../../services/axios';
import { useSelector } from 'react-redux';
import MetaTag from '../../components/global/MetaTag';
import { processImageUrl } from '../../utils/utils';

const letterCount = (data,letter) => {
  return data.filter(({title}) => title.charAt(0).toLowerCase() === letter.toLowerCase()).length;
};

const formattedDate = timestamp => (new Date(+timestamp).toLocaleDateString('en-US',{
  weekday:'long',
  month:'long',
  day:'numeric',
  year:'numeric',
  hour:'numeric',
  minute:'numeric'
}));

const ShimmerItem = () => {
  return (
    <>
      <div className=" animate-pulse h-[450px] mb-3 mt-[-100px] bg-gray-500"></div>

      <div className='text-center'>
        <div className="mx-auto w-[800px] h-[60px] mt-[20px] mb-[16px] bg-slate-300 rounded"></div>
        <div className="mx-auto w-[800px] h-[16px] mb-[50px] bg-slate-300 rounded"></div>
      </div>
      <div className='flex gap-2 lg:px-10'>
        <div className='w-[100px] h-[60px] bg-slate-300 rounded">'></div>
        <div className='w-[100px] h-[60px] bg-slate-300 rounded">'></div>
        <div className='w-[100px] h-[60px] bg-slate-300 rounded">'></div>
        <div className='w-[100px] h-[60px] bg-slate-300 rounded">'></div>
        <div className='w-[100px] h-[60px] bg-slate-300 rounded">'></div>
        <div className='w-[100px] h-[60px] bg-slate-300 rounded">'></div>
        <div className='w-[100px] h-[60px] bg-slate-300 rounded">'></div>
        <div className='w-[100px] h-[60px] bg-slate-300 rounded">'></div>
        <div className='w-[100px] h-[60px] bg-slate-300 rounded">'></div>
        <div className='w-[100px] h-[60px] bg-slate-300 rounded">'></div>
        <div className='w-[100px] h-[60px] bg-slate-300 rounded">'></div>
        <div className='w-[100px] h-[60px] bg-slate-300 rounded">'></div>
        <div className='w-[100px] h-[60px] bg-slate-300 rounded">'></div>
        <div className='w-[100px] h-[60px] bg-slate-300 rounded">'></div>
        <div className='w-[100px] h-[60px] bg-slate-300 rounded">'></div>
        <div className='w-[100px] h-[60px] bg-slate-300 rounded">'></div>
      </div>
      
      <div className=' mx-auto w-[90%] h-[400px] mt-[100px] mb-[16px] bg-slate-300 rounded'></div>

    </>
  );
};



const Glossary = () => {
  const [glossaryData,setGlossaryData] = useState([]);
  const [glossaryconfig,setglossaryconfig] = useState([]);
  const [filteredData,setFilteredData] = useState([]);
  const [error,setError] = useState();
  const [loading, setLoading] = useState(false);
  const [activeLetter, setActiveLetter] = useState("A");
  const avaiableFilters = Array.from(new Set(glossaryData.map(({title}) => title.toLowerCase().charAt(0))));
  const metaData = useSelector(state => state.siteConfig.meta_tags);
  const dataTableStyle = {
    rows:{
      style:{
        border:'none !important'
      }
    },
    headCells:{
      style:{
        backgroundColor:"#90a3b4",
        color:"#ffffff",
        fontSize:"16px",
        fontWeight:'700',
        border:'1px solid #fff'
      }
    },
    cells:{
      style:{
        padding:'4px 10px',
        fontSize:'16px',
        background:'rgba(0,0,0,0.063)',
        border:'1px solid #fff'
      }
    }
  };
  
  const columns = [
    {
      name:'Title',
      selector: row => (row.content_type==='portfolio' || row.content_type==='jobs') ? <NavLink className="text-[#0071b3] hover:text-[#055a8e] active:text-[#23aeff]" to={`${row.url_alias}?nid=${row.nid}`}>{row.title}</NavLink> : (row.content_type==='blog') ? <NavLink className="text-[#0071b3] hover:text-[#055a8e] active:text-[#23aeff]" to={`/blog${row.url_alias}?nid=${row.nid}`}>{row.title}</NavLink> : row.title,
      sortable:true,
      grow:2
    },
    {
      name:'Author',
      selector: row => row.author,
      sortable:true

    },
    {
      name:'Last Update',
      selector: row => formattedDate(row.last_update),
      sortable:true
    }
  ];
  const fetchGlossary = async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get("/read/glossary-list");
      setGlossaryData(res.data.data.list);
      setglossaryconfig(res.data.data);
      const inititalData = res.data.data.list.filter(({title}) => title.charAt(0).toLowerCase() === "a")
      setFilteredData(inititalData);
      setLoading(false)
    } catch(err){
      setError(err.message);
    }
  }
  useEffect(() => {
     fetchGlossary();
  }, [])
  if (loading) {
    return <div className='py-16 text-center'><ShimmerItem /></div>
  }
  const filterHandler = (letter = "A") => {
    const filteredData = glossaryData.filter(({title}) => title.charAt(0).toLowerCase() === letter.toLowerCase())
    setFilteredData(filteredData);
    setActiveLetter(letter); 
  }
  const resetFilter = () => {
    setFilteredData(glossaryData);
    setActiveLetter("");
  }
  if(error) return <p className='py-[200px] text-center'>{error}</p>
  return (
    <>
      <MetaTag metaData={metaData[0]} />
      <section className="glossary dark:bg-dark pb-[80px] ">
      <div className='banner-section'>
      <div className="banner-img">
        {glossaryconfig.image && 
          <img 
          src={processImageUrl(glossaryconfig.image)} 
          alt={glossaryconfig.image_alt} 
          className='w-full max-h-[600px]'
          />
        }
      </div>
      <div className="banner-content">
              <div className="banner-text">
                <h2>
                {!!glossaryconfig?.banner_title
                  ? glossaryconfig?.banner_title
                  : "Glossary Listing"}
                </h2>
                
                {!!glossaryconfig?.banner_description
                  ? <div dangerouslySetInnerHTML={{ __html: glossaryconfig?.banner_description }} />
                  : <p>A glossary is a collection of words pertaining to a specific topic</p>
                  }
                

              </div>
        </div>
      </div>
        <div className="glossary-inner min-[320px]:px-10 lg:px-20  lg:pt-[80px] md:pt-[50px] pt-[50px]">
        <h1 className=" text-center mb-[0] text-[#034d82] not-italic lg:text-[64px] text-[38px] font-bold lg:leading-[76px] leading-[50px]">Glossary</h1>
        <p className="text-center mb-[50px] text-lg font-normal text-gray-700 leading-64px pt-[10px] ">A glossary also known as a vocabulary or clavis, is an alphabetical list of terms in a particular domain of knowledge with the definitions for those terms.</p>
        <ul className="glossary-filter mb-4 flex flex-wrap m-[-5px]">
            {avaiableFilters.sort().map((letter, index) => (
            <li
                onClick={() => filterHandler(letter)}
                className={`flex m-[5px] items-center justify-center glossary-tab border-[1px] border-[#0000001f] rounded-[8px] lg:py-[6px] py-[3px] md:py-[5px] ${
                activeLetter === letter ? "bg-[#0071b3]" : "bg-[#fff]"}`} key={index} >
                {letter !== " " ? ( <> <button className={`font-semibold text-[18px] pl-1 py-1 uppercase ${ activeLetter === letter ? "text-white" : "text-[#0071b3] hover:text-[#055a8e] active:text-[#23aeff]" }`} key={letter} > {letter} </button> <span className={`text-[15px] font-medium ${ activeLetter === letter ? "text-white" : "text-[#0071b3]" }`} > &nbsp;({letterCount(glossaryData, letter)})&nbsp; </span> </> ) : ( "" )}
                {index + 1 !== avaiableFilters.length && letter !== " " ? ( <></> ) : ( "" )}
            </li>
            ))}
            <div className='view-all mt-[6px] ml-[10px]'>
          <button onClick={resetFilter} className="reset-button bg-[#0071b3] text-white px-4 py-[0.7rem] rounded ">Reset</button>
          </div>
        </ul>
          
          <DataTable columns={columns} data={filteredData} customStyles={dataTableStyle} />
        </div>
      </section>
    </>  
  )
 
}

export default Glossary;
