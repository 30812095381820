import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import LoginSlider from "../login/LoginSlider";
import icons from "../../Icons";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Config from "../../Const";
import { getAuthClient } from "../../auth/auth";
import { processImageUrl } from "../../utils/utils";

const Register = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [unameExist, setUnameExist] = useState(false);
  const [emailExist, setEmailExist] = useState(false);
  // const notify = () => toast("Successfully registered and activation link send to your mail");
  const [tokenCaptcha, setTokenCaptcha] = useState("");
  const [errCaptcha, setErrCaptcha] = useState("");
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false)

  const auth = getAuthClient();
  useEffect(() => {
    document.title = 'Register';
  }, [])

  const onSubmit = async (data) => {
    if (!tokenCaptcha) {
      setErrCaptcha("Please select the google recaptcha.");
    }
    else {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const raw = JSON.stringify({
        name: {
          value: data.uname,
        },
        mail: {
          value: data.mail,
        },
        pass: {
          value: ' ',
        },
        field_name: {
          value: data.name,
        },
        field_mobile: {
          value: data.mobile,
        },
        field_address: {
          value: data.address,
        },
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://demo-application.qa2.tothenew.net/user/register?_format=json",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.data.message) {
            let errorMessage = result.data.message;
            errorMessage.includes('username') ? setUnameExist(true) : setUnameExist(false);
            errorMessage.includes('email address') ? setEmailExist(true) : setEmailExist(false);
            setLoading(false);
          } else {
            // notify();

            // regiteration activation process 
            const requestOptions = {
              method: "POST",
              headers: new Headers({
                'Content-Type': 'application/json',
              }),
              body: JSON.stringify({
                "name": data.uname,
                "base_url": Config.base_url,
              }),
              redirect: "follow"
            };
            fetch(`${auth.config.base}/api/v1/write/register-account-activation-verify?_format=json`, requestOptions)
              .then((response) => response.json())
              .then((result) => {
                if (result.data.response) {
                  setErrorMessage('');
                  setSuccessMessage('Link send to your mail to reset password');
                } else {
                  setErrorMessage('Wrong username or email');
                  setSuccessMessage('');
                }
                setLoading(false);
              })
              .catch((error) => console.error(error));
            // setTimeout(() => {
            //   navigate("/login");
            // }, 2000)
          }
        })
        .catch((error) => {

        });
    }
  };
  const handleCaptchaChange = (value) => {
    setTokenCaptcha(value);
    setErrCaptcha('');
  };
  window.scrollTo(0, 0);

  return (
    <div className="login-wrapper  flex items-stretch flex-wrap">
      <div className="login-banner w-full md:w-1/2">
        <LoginSlider />
      </div>
      <div className="regiter-form w-full md:w-1/2 px-[7%]">
        <div className="">
          <ToastContainer toastClassName="white-toast" />
          {errorMessage && <div className="text-red-500">{errorMessage}</div>}
          {successMessage && <div className="text-green-500">{successMessage}</div>}
          <form onSubmit={handleSubmit(onSubmit)}>
            <h3 className="text-center  md:text-start text-4xl mt-2 mb-5 font-bold">Create an account</h3>
            <h4 className="">Lets get stated with your 30days free trial.</h4>
            <div className="mb-2">
              <input
                name="uname"
                placeholder="Username"
                className="w-full border-none bg-[#f0f0f0] rounded-[8px] py-3 px-5 mt-[10px] placeholder-gray-700 placeholder-opacity-50"
                maxLength={40}
                {...register("uname", {
                  required: "Username is required",
                  onChange: (e) => setUnameExist(false),
                  maxLength: {
                    value: 40,
                    message: "Allow max 40 charecters"
                  },
                })}
              />
            </div>
            {errors.uname && (
              <p className="text-red-500 pl-[10px]">{errors.uname.message}</p>
            )}
            {unameExist && <p className="text-red-500">Username already exist</p>}
            <div className="mb-2">
              <input
                name="mail"
                placeholder="Email" maxLength={40}
                className="w-full border-none bg-[#f0f0f0] rounded-[8px] py-3 px-5 mt-[10px] placeholder-gray-700 placeholder-opacity-50"
                {...register("mail", {
                  onChange: (e) => setEmailExist(false),
                  required: "Email is required",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Entered value does not match email format",
                  },
                })}
              />
            </div>
            {errors.mail && (
              <p className="text-red-500 pl-[10px] ">
                {errors.mail.message}
              </p>
            )}
            {emailExist && <p className="text-red-500">Email address already exist</p>}
            {/* <div className="mb-2">
              <input
                name="pass"
                placeholder="Password"
                type="password"
                maxLength={30}
                className="w-full border-none bg-[#f0f0f0] rounded-[8px] py-3 px-5 mt-[10px] placeholder-gray-700 placeholder-opacity-50 "
                {...register("pass", {
                  required: "Password is required",
                  minLength: {
                    value: 8,
                    message: "Password must have at least 8 characters"
                  },
                  maxLength: {
                    value: 30,
                    message: "Too long characters"
                  },
                })}
              />
            </div>
            {errors.pass && (
              <p className="text-red-500 pl-[10px] ">{errors.pass.message}</p>
            )} */}

            <div className="mb-2">
              <input
                name="name"
                placeholder="Enter your name" maxLength={40}
                className="w-full border-none bg-[#f0f0f0] rounded-[8px] py-3 px-5 mt-[10px] placeholder-gray-700 placeholder-opacity-50"
                {...register("name", {
                  required: "Name is required",
                  maxLength: {
                    value: 40,
                    message: "Too long characters"
                  },
                  pattern: {
                    value: /^[A-Za-z\s]+$/i,
                    message: "Enter character value",
                  },
                })}
              />
            </div>
            {errors?.name && <p role="alert" className="text-red-500 pl-[10px]">{errors.name.message}</p>}
            <div className="mb-2">
              <input
                name="mobile"
                placeholder="Enter Mobile No" maxLength={10}
                className="w-full border-none bg-[#f0f0f0] rounded-[8px] py-3 px-5 mt-[10px] placeholder-gray-700 placeholder-opacity-50"
                {...register("mobile", {
                  required: "Mobile number is required",
                  pattern: {
                    value: /^[6-9]{1}[0-9]{9}$/,
                    message: "Mobile number should be 10 degit and start with 6,7,8 or 9",
                  }
                })}
              />
            </div>
            {errors.mobile && (
              <p className="text-red-500 pl-[10px] ">{errors.mobile.message}</p>
            )}
            <div className="mb-4">
              <input
                name="address"
                placeholder="Enter your Address" maxLength={400}
                className="w-full border-none bg-[#f0f0f0] rounded-[8px] py-3 px-5 mt-[10px] placeholder-gray-700 placeholder-opacity-50"
                {...register("address", {
                  required: "Address is required",
                })}
              />
            </div>
            {errors.address && (
              <p className="text-red-500 pl-[10px] mb-4">{errors.address.message}</p>
            )}

            <ReCAPTCHA
              sitekey="6LfgprUpAAAAAEUfyDfGBszw0OKc7RHz0rg0iJWN"
              onChange={handleCaptchaChange}
            />
            <p className="text-red-500 mb-3">{errCaptcha}</p>

            <div className="mt-12">
              <button className="btn w-full bg-black text-white text-center p-[10px]">
                {loading ? 'please wait...' : 'Create Account'}
              </button>
            </div>
            <p className="mt-4 or text-center">or</p>
            <div className=" mt-4 social-logins">
              <div className="google-signin soc-btn">
                <Link
                  to="/"
                  className="flex w-[210px]	mx-auto items-center gap-5"
                >
                  <img
                    src={processImageUrl(icons.GoogleImg)}
                    className="w-[25px]"
                    alt="google-img"
                  />
                  Sign in with Google
                </Link>
              </div>
              <div className="fb-signin soc-btn">
                <Link
                  to="/"
                  className="flex w-[210px]	mx-auto items-center gap-5"
                >
                  <img
                    src={processImageUrl(icons.FacebookImg)}
                    className="w-[25px]"
                    alt="facebook-img"
                  />
                  Sign in with Facebook
                </Link>
              </div>
              <div className="linkedin-signin soc-btn">
                <Link
                  to="/"
                  className="flex w-[210px]	mx-auto items-center gap-5"
                >
                  <img
                    src={processImageUrl(icons.LinkedInImg)}
                    className="w-[25px]"
                    alt="linkedin-img"
                  />
                  Sign in with LinkedIn
                </Link>
              </div>
            </div>
            <div className="mt-12  mb-8 text-center">
              <span className="text-gray-500">
                Already have an account?
                <Link to="/login" className="text-black ml-2 underline">
                  Login Now
                </Link>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
