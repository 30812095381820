export const processImageUrl = (url) => {
    if (url && url.startsWith('/s3/files')) {
      return `${process.env.REACT_APP_API_URL}${url}`;
    }
    return url || ''; // Return an empty string if the URL is undefined or null
  };

export const getEnvUrl = () => {
  if (window.location.origin.includes("localhost")) {
    return "https://reactdemo-application.qa2.tothenew.net";
  } else {
    return window.location.origin;
  }
}