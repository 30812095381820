import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import '../../assets/scss/homepage.scss';
import { Link } from 'react-router-dom';
import NiceTry from '../../assets/images/nice-try-colorful.gif';
import PollingComponent from '../../pages/quiz/PollingComponent';
import axiosInstance from '../../services/axios';
import "../../assets/scss/quiz.scss";
import Congrate from '../../assets/images/congrats.gif';

const Quiz = ({quiz, setPollData, pollData, hasVoted, setHasVoted, userId, setUserId }) => {

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentQuizItem, setCurrentQuizItem] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [showScore, setShowScore] = useState(false);
  const [score, setScore] = useState(0);
  const [showAnswers, setShowAnswers] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const openModal = (item) => {
    setCurrentQuizItem(item);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentQuizItem(null);
    setCurrentQuestionIndex(0);
    setSelectedAnswers([]);
    setShowScore(false);
    setScore(0);
    setShowAnswers(false);
    setErrorMessage('');
  };

  const handleAnswer = (answerText) => {
    if (selectedAnswers[currentQuestionIndex] !== undefined) {
      return; 
    }

    const isCorrect = currentQuizItem.questions[currentQuestionIndex]?.correct_options.includes(answerText);

    // Update state with selected answer and score
    const updatedSelectedAnswers = [...selectedAnswers];
    updatedSelectedAnswers[currentQuestionIndex] = answerText;
    setSelectedAnswers(updatedSelectedAnswers);
    setScore(prevScore => isCorrect ? prevScore + 1 : prevScore);
  };

  const nextQuestion = () => {
    if (selectedAnswers[currentQuestionIndex] === undefined) {
      setErrorMessage('Please select an answer before proceeding.');
      return;
    }
    const nextIndex = currentQuestionIndex + 1;
    if (nextIndex < currentQuizItem?.questions.length) {
      setCurrentQuestionIndex(nextIndex);
    } else {
      setShowScore(true);
    }
    setErrorMessage('');
  };

  const handleSubmit = () => {
    if (selectedAnswers[currentQuestionIndex] === undefined) {
      setErrorMessage('Please select an answer before submitting.');
      return;
    }
    setShowScore(true);
    setErrorMessage('');
  };

  const checkAnswers = () => {
    setShowAnswers(true);
  };

  const startAgain = () => {
    setShowScore(false);
    setScore(0);
    setCurrentQuestionIndex(0);
    setSelectedAnswers([]);
    setErrorMessage('');
    setShowAnswers(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  if (!Array.isArray(quiz) || quiz.length === 0) {
    return <div>No quiz item to show!</div>;
  }

  return (
    <div className="content">
      {quiz.map((item, index) => (
        <div className="bg-[#f7fbff] pt-12 md:pt-16 lg:pt-25 pb-[50px]" key={index}>
          <div className="container mx-auto px-4 lg:px-20">
            <div className="flex flex-col md:flex-row justify-between items-start">
              <div className="qz-left font-[400] text-[16px] leading-[38px] text-[#90a3b4] w-full md:w-[50%] lg:border-r-2 lg:border-gray-300 lg:pr-[80px] mb-8 md:mb-0">
                 {item.title && (
                   <div dangerouslySetInnerHTML={{ __html: item.description }} />
                 )}
                <div className="text-center md:text-start lg:text-start mt-4">
                  <button 
                    onClick={() => openModal(item)}
                    className="arrow bg-gradient-to-r from-[#0f5c74] to-[rgba(0,3,69,.91)] hover:opacity-[.9] text-[#fff] pt-[5px] pb-[5px] pr-[30px] pl-[30px] relative rounded-[4px] duration-[.3s] font-medium text-[16px] text-center before:absolute before:h-[20px] before:w-[20px] before:right-[36px] before:top-[52%] before:translate-y-[-50%] hover:before:translate-y-[-50%] hover:before:translate-x-[5px] before:duration-[.4s]"
                  >
                     Try Quizzer 
                  </button>
                </div>
              </div>
              <div className="qz-right w-full md:w-[50%] lg:pl-[80px] pt-[40px] md:pt-0">
                <PollingComponent pollData = {pollData} setPollData = {setPollData} hasVoted = {hasVoted} setHasVoted = {setHasVoted} userId = {userId} setUserId = {setUserId} />
              </div>
            </div>
          </div>

          {/* Modal for each quiz item */}
          <Modal
            isOpen={modalIsOpen && currentQuizItem === item}
            onRequestClose={closeModal}
            contentLabel="Quiz Modal"
            className="Modal"
            overlayClassName="Overlay"
          >
            <button className="close-button" onClick={closeModal}>×</button>
        {showScore ? (
          <div className="score-section">
            <h2 className="text-center">Quiz Score</h2>
            <p className="text-center">You scored {score} out of <span className='score-1'>{currentQuizItem.questions.length}</span>.</p>
            <button onClick={startAgain} className="start-again-btn">Start Again</button>
          </div>
        ) : (
              <>
                <div className="question-section">
                  <h2 className="text-center">
                    {currentQuizItem?.questions[currentQuestionIndex]?.question || 'No question available'}
                  </h2>
                </div>
                <div className="answer-section">
                  {currentQuizItem?.questions[currentQuestionIndex]?.options?.map((option, index) => {
                    const isSelected = selectedAnswers[currentQuestionIndex] === option;
                    const isCorrect = currentQuizItem.questions[currentQuestionIndex]?.correct_options.includes(option);

                    let buttonClass = 'option-btn';
                    if (isSelected && isCorrect) {
                      buttonClass += ' correctans'; // Correct answer selected by the user
                    } else if (isSelected && !isCorrect) {
                      buttonClass += ' answrong'; // Incorrect answer selected by the user
                    } else if (!isSelected && isCorrect && selectedAnswers.length > 0) {
                      buttonClass += ' ansright'; // Correct answer, not selected by the user
                    }

                    return (
                      <div className="btn-wrapper">
                    <div className={buttonClass}>
                    <button
                        key={index}
                        onClick={() => handleAnswer(option)}
                        disabled={selectedAnswers[currentQuestionIndex] !== undefined}
                      >
                        {option}
                      </button>
                  </div>
                  </div>
                      
                    );
                  }) || 'No options available'}
                </div>
                <div className="navigation-buttons">
                  {currentQuestionIndex === currentQuizItem?.questions.length - 1 ? (
                    <button onClick={handleSubmit} className='category-btn rounded-[3px]  hover:font-extrabold font-normal text-[12px] text-white pr-[15px] pl-[15px] py-[9px] uppercase'>
                      View Results
                    </button>
                  ) : (
                    <button onClick={nextQuestion} className='category-btn rounded-[3px]  hover:font-extrabold font-normal text-[12px] text-white pr-[15px] pl-[15px] py-[9px] uppercase'>
                      Next
                    </button>
                  )}
                </div>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
              </>
            )}
          </Modal>
        </div>
      ))}
    </div>
  );
};

export default Quiz;
