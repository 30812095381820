import React, { useState, useEffect } from "react";
import banner from "../../assets/images/banner-react-inner.jpg";
import axiosInstance from "../../services/axios";
import { useParams } from "react-router-dom";
import { ErrorItem } from "../../components/global/ErrorPage";
import ApplyJob from "../../components/job/ApplyJob";
import { useSelector } from "react-redux";
import MetaTag from "../../components/global/MetaTag";
import { processImageUrl } from "../../utils/utils";

const JobDetail = () => {
  const [jobDetail, setJobDetail] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const metaData = useSelector((state) => state.siteConfig.meta_tags);

  //Get the Query string values
  const { alias } = useParams();

  useEffect(() => {
    const fetchBlog = async () => {
      setLoading(true);
      try {
        const res = await axiosInstance.get(
          `/read/entity/node/jobs?alias=/jobs/${alias}`
        );
        setJobDetail(res.data.data);
        setLoading(false);
      } catch (error) {
        setError(error);
      }
    };
    fetchBlog();
    
  }, [alias]);

  useEffect(function () {
    window.scrollTo(0, 0);
  }, []);

  if (error) return <ErrorItem error={error} />;

  return (
    <>
      <MetaTag metaData={metaData[0]} />
      <section className="bg-white pb-10 dark:bg-dark ">
        <div className="banner-section">
          <div className="banner-img">
            <img src={jobDetail?.field_image?.field_value[0]?.uri ? processImageUrl(jobDetail?.field_image?.field_value[0]?.uri) : banner} alt='banner' className='w-full max-h-[600px]' />
            
          </div>
          <div className="banner-content">
            <div className="banner-text">
              <h2>{jobDetail.field_job_title}</h2>
              <div className=" block mt-[10px] md:flex">
                <p className="location relative text-[18px] md:text-[20px] text-[#fff] pb-[10px] pl-[25px] before:left-[0] before:absolute before:h-[25px] before:w-[25px] before:top-[3px]">
                  {jobDetail?.field_job_location?.field_value?.[0]?.term?.name}
                </p>
                <p className="experience relative text-[18px] md:text-[20px] text-[#fff] ml-0 md:ml-[20px] pb-[10px] pl-[30px] before:left-[0] before:absolute before:h-[25px] before:w-[25px] before:top-[3px]">
                  {jobDetail.field_experience}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className=" min-[320px]:px-10 lg:px-20 overflow-hidden pt-[40px]">
          <div className="flex flex-wrap">
            <div className="left-data w-full md:w-[50%] md:pr-[20px] lg:pr-[50px]">
              {jobDetail.field_job_description && (
                <div className="job-description mb-[30px]">
                  <div className="font-bold text-[#000] text-[34px] mb-[18px] leading-[38px] ">
                    Job Description
                  </div>
                  <div
                    className="job-items-detail"
                    dangerouslySetInnerHTML={{
                      __html: jobDetail.field_job_description,
                    }}
                  ></div>
                </div>
              )}

              {jobDetail.field_responsibilities && (
                <div className="resonsiblities mb-[30px]">
                  <div className="font-bold text-[#000] text-[34px] mb-[18px] leading-[38px] ">
                    Responsibilities
                  </div>
                  <div
                    className="job-items-detail"
                    dangerouslySetInnerHTML={{
                      __html: jobDetail.field_responsibilities,
                    }}
                  ></div>
                </div>
              )}

              {jobDetail.field_good_to_have && (
                <div className="good-to-have mb-[30px]">
                  <div className="font-bold text-[#000] text-[34px] mb-[18px] leading-[38px] ">
                    Good to Have
                  </div>
                  <div
                    className="job-items-detail"
                    dangerouslySetInnerHTML={{
                      __html: jobDetail.field_good_to_have,
                    }}
                  ></div>
                </div>
              )}
            </div>
            <div className="right-form w-full md:w-[50%] lg:pl-0 lg:max-w-[800px] lg:mx-auto">
              <div className=" text-[#000] p-0 md:p-5 ">
                <ApplyJob nid={jobDetail.nid} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default JobDetail;
