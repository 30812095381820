import React from 'react'
import Comment from '../../components/blog/Comment'
import blogsimg from '../../assets/images/blogs.png'
import { useState, useEffect } from 'react'
import axiosInstance from '../../services/axios';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom'
import Loader from '../../components/global/Loader';
import { useSelector } from 'react-redux';
import MetaTag from '../../components/global/MetaTag';
import { getEnvUrl, processImageUrl} from '../../utils/utils';
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import customTwitterIconUrl from "../../assets/images/twitter.png"


const Detail = () => {
  let uid = 0;
  if(localStorage.getItem('userInfo') !== null){
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    uid = userInfo.uid;
  }
  const navigate = useNavigate();
  const location = useLocation();
  const [blog, setBlog] = useState([]);
  const [blogId, setBlogId] = useState();
  const [viewCount, setViewCount] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { lid } = useParams();
  const { id } = useParams();
  const blogalias = '/' + id + '/' + lid;
  const metaData = useSelector(state => state.siteConfig.meta_tags);
  const [totalLike, setTotalLike] = useState({});
  
  const ErrorItem = ({ error }) => {
    return (
      <div className="min-h-[500px] flex items-center justify-center"><div className="flex-[1_1_100%] text-center p-[48px]">
        <h1 className="text-[48px] font-bold mb-[10px] text-blue-900 leading-[48px]">{error?.code}</h1>
        <p className="text-[20px] m-0 text-gray-600">{error?.message}</p>
      </div></div>
    )
  }
 
  const fetchBlog = async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/read/blog-detail?alias=${blogalias}`);
      const blogData = res?.data?.data;
      setBlog(blogData);
      if (blogData?.length > 0) {
        const blogIdFromData = blogData[0]?.nid;
        setBlogId(blogIdFromData);
      }
      setLoading(false);
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  // const fetchViewCount = async (blogId) => {
  //   try {
  //     if (uid > 0) {
  //       let current_blog_url = localStorage.getItem('current-blog');
  //       if (current_blog_url !== location.pathname) {
  //         const res = await axiosInstance.post("/read/most-viewed-blogs", JSON.stringify({ nid: blogId }), {
  //           headers: { "Content-Type": "application/json" },
  //         });
  //         setViewCount(res?.data?.data?.count);
  //         localStorage.setItem('current-blog', location.pathname);
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const fetchViewCount = async (blogId) => {
    try {
      let current_blog_url = localStorage.getItem('current-blog');
      console.log(current_blog_url, location.pathname);
        const res = await axiosInstance.post("/read/most-viewed-blogs", JSON.stringify({ nid: blogId }), {
          headers: { "Content-Type": "application/json" },
        });
        setViewCount(res?.data?.data?.count);
        localStorage.setItem('current-blog', location.pathname);
    } catch (error) {
      console.log(error);
    }
  };
  
 
    
  useEffect(() => {
    fetchBlog();
  }, []);

  useEffect(() => {
    if (blogId) {
      console.log(blogId);
      fetchViewCount(blogId);
    }
  }, [blogId]);

 
  const saveBlogLike = (reactionType) => {
    const isAlreadyReacted = totalLike.user_reaction === reactionType;
    const updatedReactions = { ...totalLike };
    if (isAlreadyReacted) {
      updatedReactions.user_reaction = null;
      if (reactionType === 'reaction_like') {
        updatedReactions.total_likes = Math.max(0, updatedReactions.total_likes - 1);
      } else if (reactionType === 'reaction_love') {
        updatedReactions.total_love = Math.max(0, updatedReactions.total_love - 1);
      } else if (reactionType === 'reaction_laughing') {
        updatedReactions.total_happy = Math.max(0, updatedReactions.total_happy - 1);
      } else if (reactionType === 'reaction_surprised') {
        updatedReactions.total_surprised = Math.max(0, updatedReactions.total_surprised - 1);
      }
    } else {
      if (updatedReactions.user_reaction) {
        if (updatedReactions.user_reaction === 'reaction_like') {
          updatedReactions.total_likes = Math.max(0, updatedReactions.total_likes - 1);
        } else if (updatedReactions.user_reaction === 'reaction_love') {
          updatedReactions.total_love = Math.max(0, updatedReactions.total_love - 1);
        } else if (updatedReactions.user_reaction === 'reaction_laughing') {
          updatedReactions.total_happy = Math.max(0, updatedReactions.total_happy - 1);
        } else if (updatedReactions.user_reaction === 'reaction_surprised') {
          updatedReactions.total_surprised = Math.max(0, updatedReactions.total_surprised - 1);
        }
      }
      updatedReactions.user_reaction = reactionType;
      if (reactionType === 'reaction_like') {
        updatedReactions.total_likes += 1;
      } else if (reactionType === 'reaction_love') {
        updatedReactions.total_love += 1;
      } else if (reactionType === 'reaction_laughing') {
        updatedReactions.total_happy += 1;
      } else if (reactionType === 'reaction_surprised') {
        updatedReactions.total_surprised += 1;
      }
    }
    setTotalLike(updatedReactions);
    saveBlogLikePostApi(reactionType, updatedReactions);
  };
   const saveBlogLikePostApi = async (reactionType, updatedReactions) => {
     const payload = {
       reaction: reactionType,
       nid: blog[0]?.nid,
       user_id: uid
     };
    try {
       const response = await axiosInstance.post('/save-blog-likes', payload);
       if (response?.data?.status) {
         saveBlogLikeGetApi();
 
       } else {
          console.error('Internal Server Error');
       }
     } catch (e) {
       console.log(e);
       console.error('Internal Server Error');
     }
   };
  
   const saveBlogLikeGetApi = async () => {
     try {
       const response = await axiosInstance.get(`/read/blog-likes?alias=${blogalias}&uid=${uid}`);
       if (response?.data?.status) {
         setTotalLike({
           total_likes: response?.data?.data[0]?.total_likes || 0,
           total_love: response?.data?.data[0]?.total_love || 0,
           total_happy: response?.data?.data[0]?.total_happy || 0,
           total_surprised: response?.data?.data[0]?.total_surprised || 0,
           user_reaction: response?.data?.data[0]?.user_reaction,
         });
 
       } else {
         console.error('Internal Server Error');
       }
     } catch (e) {
       console.log(e);
       console.error('Internal Server Error');
     }
   };
    useEffect(() => {
     saveBlogLikeGetApi();
   }, []); 
  if (error) return <ErrorItem error={error} />;

  if (!blog && !loading) {
    return <div>No Blog information available!</div>
  }
  if (loading) {
    return <div className='py-[500px] text-center'><Loader /></div>
  }
  const getSnippet = (text) => {
    const words = text.split(' ');
    return words.slice(0, 40).join(' ');
  }

  function decodeHTMLEntities(text) {
    return text.replace(/&amp;/g, '&')
               .replace(/&lt;/g, '<')
               .replace(/&gt;/g, '>')
               .replace(/&quot;/g, '"')
               .replace(/&#039;/g, "'");
}

  return (
    <>
      <MetaTag metaData={metaData[0]} />
      {blog.map((data, index) => (
        <div className="" key={index}>
          <div className="banner-section">
            <div className="banner-img">
              <img
                src={
                  data.blog_image ? processImageUrl(data.blog_image) : blogsimg
                }
                alt={data.blog_image_alt}
              />
            </div>
            <div className="banner-content">
              <div className="banner-text">
                <h2>
                  {data.title.length > 0 && (
                    <div dangerouslySetInnerHTML={{ __html: data.title }} />
                  )}
                </h2>
              </div>
            </div>
          </div>
          <section className="bg-white pb-10 dark:bg-dark ">
            <div className="min-[320px]:px-10 lg:px-20 pt-[50px] md:pt-[80px]">
              <Link
                to="/blogs"
                className="blog-arrow relative text-[#034d82] text-[20px] ml-[22px] before:absolute before:w-[16px] before:h-[16px] before:top-[7px] before:left-[-22px]"
              >
                {" "}
                All Blogs
              </Link>
              <div className=" mt-[30px] mb-[30px] lg:mb-[20px] ">
                <h2 className="text-[#034d82] font-bold text-[36px] md:text-[29px] lg:text-[42px] ">
                  {data.title.length > 0 && (
                    <div dangerouslySetInnerHTML={{ __html: data.title }} />
                  )}
                </h2>
              </div>
              <div className="flex flex-wrap items-center mb-[40px] justify-between">
                <div className="flex flex-wrap items-center ">
                  <div className="usrimg-blog ">
                    <img
                      src={processImageUrl(data.user_picture)}
                      alt=""
                      className="w-[50px] h-[50px] rounded-full"
                    />
                  </div>
                  <div className="Blogger pl-[20px]">
                    <p className="text-[16px] text-[#000] font-bold">
                      {data.user_name}
                    </p>
                    <span className="text-[#505050] text-[14px]">
                      {data.date}, {data.node_read_time}{" "}
                    </span>
                  </div>
                </div>
                <div className="social-sharing-buttons flex items-center mt-[30px] md:mt-0 gap-3">
                  <FacebookShareButton
                    title={`${decodeHTMLEntities(data.title)}\n`}
                    url={`${getEnvUrl()}/blog${data.blog_alias}`}
                    hashtag={"#blog..."}
                  >
                    <FacebookIcon size={40} round={true} />
                  </FacebookShareButton>

                  <TwitterShareButton
                    title={`${decodeHTMLEntities(data.title)}\n`}
                    url={`${getEnvUrl()}/blog${data.blog_alias}`}
                    hashtag={"#blog..."}
                  >
                    <img
                      src={customTwitterIconUrl}
                      height={40}
                      width={40}
                      alt="Twitter"
                    />{" "}
                    {/* Custom Twitter icon */}
                  </TwitterShareButton>

                  <WhatsappShareButton
                    title={`${decodeHTMLEntities(data.title)}\n`}
                    url={`${getEnvUrl()}/blog${data.blog_alias}`}
                  >
                    <WhatsappIcon size={40} round={true} />
                  </WhatsappShareButton>

                  <LinkedinShareButton
                    title={`${decodeHTMLEntities(data.title)}\n`}
                    url={`${getEnvUrl()}/blog${data.blog_alias}`}
                    hashtag={"#blog..."}
                  >
                    <LinkedinIcon size={40} round={true} />
                  </LinkedinShareButton>
                </div>
              </div>
              <div className="text-[#90a3b4] text-[18px] lg:text-[20px] text-justify pb-[30px]">
                {data.body &&
                  data.body.length > 0 &&
                  (uid > 0 ? (
                    <>
                      <div dangerouslySetInnerHTML={{ __html: data.body }} />
                      <div className="like-block flex gap-1 mt-5">
                        <div className="relative flex flex-col group">
                          <button
                            className="flex items-center"
                            onClick={() => saveBlogLike("reaction_like", totalLike.total_likes)}
                          >
                            <svg
                              className={`h-8 w-8 ${totalLike.user_reaction === "reaction_like" ? "text-blue-500" : "hover:text-blue-500"}`}
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              {" "}
                              <path stroke="none" d="M0 0h24v24H0z" />{" "}
                              <path d="M7 11v 8a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-7a1 1 0 0 1 1 -1h3a4 4 0 0 0 4 -4v-1a2 2 0 0 1 4 0v5h3a2 2 0 0 1 2 2l-1 5a2 3 0 0 1 -2 2h-7a3 3 0 0 1 -3 -3" />
                            </svg>
                            <small>{totalLike.total_likes || 0}</small>
                          </button>
                          <div className="absolute bottom-3 flex-col items-center hidden mb-5 group-hover:flex">
                            <span className="relative rounded-md z-10 p-3 text-xs leading-none text-white whitespace-no-wrap bg-blue-500 shadow-lg">
                              Like
                            </span>
                            <div className="w-3 h-3 -mt-2 rotate-45 bg-blue-500"></div>
                          </div>
                        </div>

                        <div className="relative flex flex-col group">
                          <button
                            className="flex items-center"
                            onClick={() => saveBlogLike("reaction_love")}
                          >
                            <svg
                              className={`h-8 w-8 ${totalLike.user_reaction === "reaction_love" ? "text-blue-500" : "hover:text-blue-500"}`}
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              {" "}
                              <path stroke="none" d="M0 0h24v24H0z" />{" "}
                              <path d="M12 20l-7 -7a4 4 0 0 1 6.5 -6a.9 .9 0 0 0 1 0a4 4 0 0 1 6.5 6l-7 7" />
                            </svg>
                            <small>{totalLike.total_love || 0}</small>
                          </button>
                          <div className="absolute bottom-3 flex-col items-center hidden mb-5 group-hover:flex">
                            <span className="relative rounded-md z-10 p-3 text-xs leading-none text-white whitespace-no-wrap bg-blue-500 shadow-lg">
                              Love
                            </span>
                            <div className="w-3 h-3 -mt-2 rotate-45 bg-blue-500"></div>
                          </div>
                        </div>

                        <div className="relative flex flex-col group">
                          <button
                            className="flex items-center"
                            onClick={() => saveBlogLike("reaction_laughing")}
                          >
                            <svg
                              className={`h-8 w-8 ${totalLike.user_reaction === "reaction_laughing" ? "text-blue-500" : "hover:text-blue-500"}`}
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              {" "}
                              <path stroke="none" d="M0 0h24v24H0z" />{" "}
                              <circle cx="12" cy="12" r="9" />{" "}
                              <line x1="9" y1="9" x2="9.01" y2="9" />{" "}
                              <line x1="15" y1="9" x2="15.01" y2="9" />{" "}
                              <path d="M8 13a4 4 0 1 0 8 0m0 0H8" />
                            </svg>
                            <small>{totalLike.total_happy || 0}</small>
                          </button>
                          <div className="absolute bottom-3 flex-col items-center hidden mb-5 group-hover:flex">
                            <span className="relative rounded-md z-10 p-3 text-xs leading-none text-white whitespace-no-wrap bg-blue-500 shadow-lg">
                              Happy
                            </span>
                            <div className="w-3 h-3 -mt-2 rotate-45 bg-blue-500"></div>
                          </div>
                        </div>

                        <div className="relative flex flex-col group">
                          <button
                            className="flex items-center"
                            onClick={() => saveBlogLike("reaction_surprised")}
                          >
                            <svg
                              className={`h-8 w-8 ${totalLike.user_reaction === "reaction_surprised" ? "text-blue-500" : "hover:text-blue-500"}`}
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              {" "}
                              <path stroke="none" d="M0 0h24v24H0z" />{" "}
                              <path d="M3 12h1M12 3v1M20 12h1M5.6 5.6l.7 .7M18.4 5.6l-.7 .7" />{" "}
                              <path d="M9 16a5 5 0 1 1 6 0a3.5 3.5 0 0 0 -1 3a2 2 0 0 1 -4 0a3.5 3.5 0 0 0 -1 -3" />{" "}
                              <line x1="9.7" y1="17" x2="14.3" y2="17" />
                            </svg>
                            <small>{totalLike.total_surprised || 0}</small>
                          </button>
                          <div className="absolute bottom-3 flex-col items-center hidden mb-5 group-hover:flex">
                            <span className="relative rounded-md z-10 p-3 text-xs leading-none text-white whitespace-no-wrap bg-blue-500 shadow-lg">
                              Relevant
                            </span>
                            <div className="w-3 h-3 -mt-2 rotate-45 bg-blue-500"></div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : data.authenticated_content ? (
                    <div className="blog-content">
                      <p>
                        {getSnippet(data.body.replace(/<[^>]+>/g, ""), 20)}....{" "}
                        <div className="my-[20px]">
                          <div className="content-auth">
                            <div className="auth-card box-shadow relative p-[60px] bg-white rounded-[5px] border border-gray-300 shadow-md">
                              <div className="overlay flex justify-center">
                                <p className="text-center">
                                  {" "}
                                  <Link
                                    to="/login"
                                    state={{ from: location.pathname }}
                                    className="btn text-[18px] rounded text-white py-4 px-12"
                                  >
                                    Login
                                  </Link>
                                </p>
                                <span className="pl-[10px] text-[#000] text-[22px]">
                                  Click on Login to view full content.
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </p>
                    </div>
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: data.body }} />
                  ))}
              </div>

              <Comment nid={blog[0] && blog[0].nid} alias={blogalias} />
            </div>
          </section>
        </div>
      ))}
    </>
  );
}

export default Detail
