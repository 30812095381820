import React, { useState } from 'react';
import Modal from 'react-modal';

const QuizModal = ({ quizItem }) => {
  const [quizState, setQuizState] = useState({
    modalIsOpen: false,
    questions: quizItem.questions || [],
    selectedAnswer: undefined,
    showScore: false,
    score: 0,
    currentQuestionIndex: 0,
  });

  const openModal = () => {
    setQuizState({ ...quizState, modalIsOpen: true });
  };

  const closeModal = () => {
    setQuizState({ ...quizState, modalIsOpen: false });
  };

  const startAgain = () => {
    setQuizState({
      ...quizState,
      selectedAnswer: undefined,
      showScore: false,
      score: 0,
      currentQuestionIndex: 0,
    });
  };

  const handleAnswer = (selectedAnswer) => {
    if (quizState.selectedAnswer !== undefined) {
      return; // Prevent changes if an answer is already selected
    }

    const { currentQuestionIndex, questions, score } = quizState;
    const isCorrect = selectedAnswer === questions[currentQuestionIndex].correct_options[0];

    setQuizState({
      ...quizState,
      selectedAnswer,
      score: isCorrect ? score + 1 : score,
    });
  };

  const nextQuestion = () => {
    if (quizState.currentQuestionIndex + 1 < quizState.questions.length) {
      setQuizState({
        ...quizState,
        currentQuestionIndex: quizState.currentQuestionIndex + 1,
        selectedAnswer: undefined,
      });
    } else {
      setQuizState({ ...quizState, showScore: true });
    }
  };

  return (
    <>
      <button onClick={openModal} className="start-quiz-btn">Start Quiz</button>
      <Modal
        isOpen={quizState.modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Quiz Modal"
        className="Modal"
        overlayClassName="Overlay"
      >
        <button className="close-button" onClick={closeModal}>×</button>
        {quizState.showScore ? (
          <div className="score-section">
            <h2 className="text-center">Quiz Score</h2>
            <p className="text-center">You scored  <span className='score-1'>{quizState.score}</span> out of <span className='score-2'>{quizItem.questions.length}</span>.</p>
            <button onClick={startAgain} className="start-again-btn">Start Again</button>
          </div>
        ) : (
          <>
            <div className="question-section">
              <h2 className="text-center">
                {quizState.questions[quizState.currentQuestionIndex].question}
              </h2>
            </div>
            <div className="answer-section">
              {quizState.questions[quizState.currentQuestionIndex].options.map((option, index) => {
                const isSelected = quizState.selectedAnswer === option;
                const isCorrect = option === quizState.questions[quizState.currentQuestionIndex].correct_options[0];

                let buttonClass = 'option-btn';

                if (isSelected && isCorrect) {
                  buttonClass += ' correctans'; // Correct answer selected by the user
                } else if (isSelected && !isCorrect) {
                  buttonClass += ' answrong'; // Incorrect answer selected by the user
                } else if (!isSelected && isCorrect && quizState.selectedAnswer !== undefined) {
                  buttonClass += ' ansright'; // Correct answer, not selected by the user
                }

                return (
                  <div className="btn-wrapper">
                    <div className={buttonClass}>
                  <button
                    key={index}
                    onClick={() => handleAnswer(option)}
                    disabled={quizState.selectedAnswer !== undefined}
                  >
                    {option}
                  </button>
                  </div>
                  </div>
                );
              })}
            </div>
            <div className="next-button-container">
              <button 
                className="next-btn" 
                onClick={nextQuestion} 
                disabled={quizState.selectedAnswer === undefined}
              >
                {quizState.currentQuestionIndex === quizState.questions.length - 1 ? 'View Results' : 'Next Question'}
              </button>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default QuizModal;
