import React from 'react';
import SlickSlider from "../slick/SlickSlider";
import { Link } from 'react-router-dom';

const Portfolio = ({ portfolioData }) => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 700,
    centerMode:true,
    initialSlide: 1,
    centerPadding: "50px",
    arrows:false,
    responsive: [
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
          centerMode: false,
          swipeToSlide: false,
          autoplaySpeed: 3000,
          speed: 1500,
          dots: true
        }
      }
    ]
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="px-5 portfolio-section mt-12"> 
      <div className="portfolio-header flex justify-center items-center" >
        <h2 className="text-sky-800 text-center lg:text-[64px] text-[38px] font-bold lg:leading-[76px] leading-[50px]">{portfolioData?.title}</h2>
        <img src={portfolioData?.icon} />
      </div>
      <h4 className="text-center text-lg font-normal text-gray-700 leading-64px pt-[10px] mb-[36px]">{portfolioData?.Sub_title}</h4>
      <SlickSlider
        settings={sliderSettings}
        slides={portfolioData?.content && portfolioData.content.length > 0 ? (
          portfolioData.content.map((item, index) => (
            <div className="lg:px-9 px-4 sm:px-9" key={index}>
              <Link to={`${item?.path}`} onClick={handleScrollToTop}>
                <img
                  className="aspect-[146/100] border rounded-lg text-slate-800 w-full border-solid border-black border-opacity-30" 
                  src={item?.image}
                  alt={item?.image_alt}
                />
              </Link>
              <Link to={`${item?.path}`} onClick={handleScrollToTop}>
                <strong className="block text-center text-#034d82 text-xl font-bold leading-30px tracking-tighter mt-3">
                  {item?.title}
                </strong>
              </Link>
              <h5 className="text-center text-base text-#143863 font-normal leading-7">
                {item?.categories?.map((category, index) => (
                  <span key={index}>{index !== 0 && ', '}<Link to={`/category${category?.alias}`} onClick={handleScrollToTop}>{category.name}</Link></span>
                ))}
              </h5>
            </div>
          ))
        ) : (
          []
        )}
      />
      <div className="view-all-link text-center mb-12">
        <Link className="inline-block rounded text-white py-4 px-12 mt-[40px]" to="/portfolios" onClick={handleScrollToTop}>{portfolioData?.block_read_more_label}</Link>
      </div>
    </div>
  );
};

export default Portfolio;
