import React, { useState, useEffect } from 'react';
import axiosInstance from '../../services/axios';
import { ShimmerItem } from '../../components/global/Loader';
import QuizModal from './QuizModal';

const QuizShimmerItem = () => {
  return (
    <>

      <div className='text-center'>
        <div className="mx-auto w-[800px] h-[80px] mt-[40px] mb-[16px] bg-slate-300 rounded"></div>
      </div>
      <div className=" w-[80px] h-[40px] mt-[40px] mb-[16px] bg-slate-300 rounded ml-20"></div>

      <div className="px-[80px] flex gap-[20px] mt-[40px] ">
        <div className="mx-auto w-[800px] h-[259px] bg-slate-300 rounded"></div>
        <div className="mx-auto w-[800px] h-[259px]  bg-slate-300 rounded"></div>
        <div className="mx-auto w-[800px] h-[259px]  bg-slate-300 rounded"></div>
      </div>

    </>
  );
};

const Quiz = () => {
  const [quizList, setQuizList] = useState([]);
  const [filteredQuizList, setFilteredQuizList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const fetchQuizList = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get('/quizzes');
        if (response.data.status === 200) {
          setQuizList(response.data.data);
          setFilteredQuizList(response.data.data); // Initialize with all quizzes
        } else {
          throw new Error(response.data.message || 'Failed to fetch quiz list');
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchQuizList();
  }, []);

  const handleCheckedItem = () => {
    setFilter([]);
    setIsVisible(false);
  };

  const handleFilterChange = (category) => {
    setFilter((prevFilter) => {
      if (prevFilter.includes(category)) {
        return prevFilter.filter((item) => item !== category);
      } else {
        return [...prevFilter, category];
      }
    });
  };

  useEffect(() => {
    if (filter.length === 0) {
      setFilteredQuizList(quizList);
    } else {
      setFilteredQuizList(
        quizList.filter((quizItem) => filter.includes(quizItem.quiz_type))
      );
    }
  }, [filter, quizList]);

  if (loading) {
    return <p className="py-16 text-center"><QuizShimmerItem /></p>;
  }

  if (filteredQuizList.length === 0) {
    return <div>No quiz items to show!</div>;
  }

  const uniqueCategories = [...new Set(quizList.map((quizItem) => quizItem.quiz_type))];

  return (
    <div className="content">
      <div className="quiz-container bg-[#f7fbff] pt-12 pb-[50px] px-4">
        <div className="min-[320px]:px-10 lg:px-20">
          <h2 className="text-center not-italic lg:text-[64px] text-[38px] font-bold lg:leading-[76px] leading-[50px] text-[#034d82] m-[40px]">Quiz Categories</h2>
          <div className="search-filter relative pb-10">
            <button className="text-[#034d82] font-bold flex items-center gap-3" onClick={() => setIsVisible(!isVisible)}>
              <span>Filter</span>
              {isVisible ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24">
                  <g fill="currentColor" fillRule="nonzero">
                    <path d="M12 22C5.372 22 0 16.628 0 10S5.372-2 12 2c6.628 0 12 5.372 12 12s-5.372 12-12 12zM12 0C5.372 0 0 5.372 0 12s5.372 12 12 12 12-5.372 12-12S18.628 0 12 0z"></path>
                    <path d="M16.192 16.192a1 1 0 01-1.414 0L12 13.414 9.222 16.192a1 1 0 01-1.414-1.414L10.586 12 7.808 9.222a1 1 0 011.414-1.414L12 10.586l2.778-2.778a1 1 0 011.414 1.414L13.414 12l2.778 2.778a1 1 0 010 1.414z"></path>
                  </g>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24">
                  <path fill="currentColor" fillRule="nonzero" d="M2 4.5A1.5 1.5 0 013.5 3h17a1.5 1.5 0 010 3h-17A1.5 1.5 0 012 4.5zm5 6A1.5 1.5 0 018.5 9h7a1.5 1.5 0 010 3h-7A1.5 1.5 0 017 10.5zm5 6a1.5 1.5 0 011.5-1.5h1a1.5 1.5 0 010 3h-1A1.5 1.5 0 0112 16.5z" />
                </svg>
              )}
            </button>

            {isVisible && (
              <div className="category-details w-full lg:w-[26%] bg-white absolute top-[36px] left-0 z-10">
                <div className="category-detail-container flex flex-wrap justify-between gap-[26px] px-[20px] py-[20px] rounded-[4px]">
                  {uniqueCategories.map((category, index) => (
                    <div className="blog-checkbox flex gap-3" key={`${category}-${index}`}>
                      <input
                        id={`category-${index}`}
                        type="checkbox"
                        className="m-auto !h-[19px] !w-[23px] border-gray-200 rounded"
                        value={category}
                        checked={filter.includes(category)}
                        onChange={() => handleFilterChange(category)}
                      />
                      <label
                        htmlFor={`category-${index}`}
                        className="w-[90%] text-[20px] tracking-[-1px] font-normal text-black dark:text-gray-400"
                      >
                        {category}
                      </label>
                    </div>
                  ))}
                </div>
                <div className="px-[20px] py-[20px]">
                  <button className="check-btn w-[75px] hover:font-extrabold font-normal text-[12px] text-white pr-[15px] pl-[15px] py-[9px] uppercase" onClick={handleCheckedItem}>
                    RESET
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="category-cards flex flex-wrap gap-4">
            {filteredQuizList.map((quizItem) => (
              <div className="card" key={quizItem.id}>
                <div className="card-content">
                  <div className="card-front flex flex-col">
                    <span></span>
                    <h3 className="mb-[10px] text-center text-[24px] font-bold text-[#034d82]">{quizItem.title}</h3>
                  </div>
                  <div className="card-back">
                    <span></span>
                    <QuizModal quizItem={quizItem} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quiz;
