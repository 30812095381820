import React, { useEffect, useState } from 'react';
import Portfolio from '../../components/home/Portfolio';
import Quiz from '../../components/home/Quiz';
import Blogs from '../../components/home/Blogs';
import Banner from '../../components/home/Banner';
import Testimonial from '../../components/home/Testimonial';
import axiosInstance from '../../services/axios';
import MetaTag from '../../components/global/MetaTag';
import PollingComponent from '../quiz/PollingComponent';
import Cookies from 'js-cookie';

const Home = () => {
  const [portfolioData, setPortfolioData] = useState([]);
  const [quizData, setQuizData] = useState({});
  const [testimonialData, setTestimonialData] = useState([]);
  const [blogData, setBlogData] = useState([]);
  const [metaData, setMetaData] = useState([]);
  const [hasVoted, setHasVoted] = useState(false);
  const [userId, setUserId] = useState(null);
  // const [pollData, setPollData] = useState([]);
  const [error, setError] = useState(null);
  const [pollData, setPollData] = useState({
    question: '',
    choices: [],
    poll_id: null,
  });
  const ErrorItem = ({ error }) => {
    return (
      <div className="min-h-[500px] flex items-center justify-center">
        <div className="flex-[1_1_100%] text-center p-[48px]">
          <h1 className="text-[48px] font-bold mb-[10px] text-blue-900 leading-[48px]">
            {error?.code}
          </h1>
          <p className="text-[20px] m-0 text-gray-600">{error?.message}</p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get('/read/home-page');
      const homepageData = response?.data?.data?.[0];
      setPortfolioData(homepageData?.portfolio);
      setQuizData(homepageData?.quiz);
      setTestimonialData(homepageData?.testimonial);
      setBlogData(homepageData?.blog);
      setMetaData(homepageData?.meta[0]);
      setPollData(homepageData?.poll[0]);
      // debugger
      setPollData({
        question: homepageData?.poll?.question,
        choices: homepageData?.poll?.choices?.map((choice, index) => ({
          index: index + 1,
          text: choice,
          votes: homepageData?.poll?.poll_result[index + 1] || 0,
          selected: false,
        })),
        poll_id: homepageData?.poll?.poll_id,
      });
      // Check for voting cookie
      const cookieName = `poll_${homepageData?.poll?.poll_id}_voted`;
      const voted = Cookies.get(cookieName);
      if (voted) {
        setHasVoted(true);
      }

      // Get user ID from localStorage
      const userInfo = localStorage.getItem('userInfo');
      if (userInfo) {
        const user = JSON.parse(userInfo);
        setUserId(user.uid); // Assuming userInfo contains a user_id field
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error);
    }
  };

  if (error) return <ErrorItem error={error} />;

  return (
    <div>
      <MetaTag metaData={metaData} />
      <Banner />
      <Portfolio portfolioData={portfolioData} />
      <Quiz quiz={quizData} setPollData={setPollData} pollData={pollData} hasVoted = {hasVoted} setHasVoted = {setHasVoted} userId = {userId} setUserId = {setUserId} />
      <Testimonial testimonialData={testimonialData} />
      <Blogs blogs={blogData} />
    </div>
  );
};

export default Home;
