import React, { useEffect, useState } from "react";
import SlickSlider from "../slick/SlickSlider";
import "../../assets/scss/testimonial.scss";
import TestimonialSlide from "./TestimonialSlide";
import { Link } from "react-router-dom";

const Testimonial = ({ testimonialData }) => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1500,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "10%",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
          centerMode: false,
          centerPadding: "",
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: "",
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: "",
          dots: false,
        },
      },
    ],
  };

  //it makes equal height of the testimonial slides
  useEffect(() => {
    setTimeout(() => {
      var highestBox = 0;
    document
      .querySelectorAll(".testimonial-sec .slick-slider .testimonial-des")
      .forEach(function (e) {
        if (e.clientHeight > highestBox) {
          highestBox = e.clientHeight;
        }
      });
    document
      .querySelectorAll(".testimonial-sec .slick-slider .testimonial-des")
      .forEach(function (e) {
        e.style.height = highestBox + "px";
      });
    },2000)
  }, [testimonialData]);

  // if (loading) return "Loading......";
  if (!testimonialData || testimonialData.length === 0) {
    return <div>No Testimonials items to show!</div>;
  }

  return (
    <div className="testimonial-sec mt-10 pt-14 bg-gradient-to-r from-[#134663] to-[#133562] text-white pb-[3rem]">
      <div className="items-center text-center p-[10px]">
        <h2 className="text-white lg:text-[64px] text-[38px] font-bold lg:leading-[76px] leading-[50px]">
          {testimonialData?.title}
        </h2>
        <p className="text-[#90a3b4] text-center text-lg font-normal  leading-64px pt-[10px] mb-[10px]">
          {testimonialData?.Sub_title}
        </p>
      </div>

      <SlickSlider
        settings={sliderSettings}
        slides={
          testimonialData?.content?.data &&
          testimonialData.content?.data.length > 0
            ? testimonialData.content.data.map((item, index) => (
                <TestimonialSlide
                  data={item}
                  key={index}
                  moretxt={testimonialData?.card_read_more_label}
                />
              ))
            : []
        }
      />

      <div className="items-center text-center flex justify-center ">
        <div className="read-testimonial-btn  ">
          <Link
            to="/testimonials"
            className="font-[16px] text-base leading-5 underline pr-2 cursor-pointer"
          >
            {testimonialData?.block_read_more_label}
          </Link>
        </div>
        <svg
          className="w-4 h-6 text-[white]"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 10"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M1 5h12m0 0L9 1m4 4L9 9"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default Testimonial;
