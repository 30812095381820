import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  footer_social:{},
  home_featuredblog:{},
  home_portfolio:{},
  home_quiz:{},
  home_testimonial:{},
  meta_tags:{}
}

const headingSlice = createSlice({
  name:'siteConfig',
  initialState,
  reducers:{
    updateConfig(state,action){
      const data = action.payload;
      state.footer_social = data.footer_social;
      state.meta_tags = data.meta;
    }
  }
});

export const siteConfig = headingSlice.actions;
export default headingSlice;