import React, { useEffect, useState } from "react";
import {Link} from 'react-router-dom'
import '../../assets/scss/common.scss'
import Modal from "react-modal";
import axiosInstance from '../../services/axios'
import Loader from '../../../src/components/global/Loader';
import Pagination from '../../components/global/Pagination';
import { ErrorItem } from '../../components/global/ErrorPage';
import ApplyJob from "../../components/job/ApplyJob";
import MetaTag from '../../components/global/MetaTag';
import { processImageUrl } from "../../utils/utils";

const Jobs = () => {

  const [jobData, setJobData] = useState([]);
  const [error, setError] = useState(false);
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    searchTitle: '',
    functions: 'Any',
    jobRole: [],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPge, SetPostsPerPage] = useState(9);
  const [totalItems, settotalItems] = useState(null);
  const [pagination, setPagination] = useState(false);

  
  const openPopup = () => {
    setPopupIsOpen(true);
  };

  const closePopup = () => {
    setPopupIsOpen(false);
  };

  useEffect(function(){
    window.scrollTo(0,0);
   },[]);

  // Function to handle page change
  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
    setPagination(true);
    document.querySelector(".helping-text").scrollIntoView({behavior:'smooth'});
  }

  /* Function to handle the onchange request of search filter */
   const handleInputChange = (event) => {
    //event.persist(); // Persist the event to access its properties later
    const { name, value, type, checked } = event.target;
    settotalItems(null);
    setCurrentPage(1);
    setPagination(false);
    
    if (type === 'checkbox') {
      if (checked) {
        // Add the checked value to the jobRole array
        setFormData({
          ...formData,
          jobRole: [...formData.jobRole, value],
        });
      } else {
        // Remove the unchecked value from the jobRole array
        setFormData({
          ...formData,
          jobRole: formData.jobRole.filter((item) => item !== value),
        });
      }
    } else {
      if(type === 'text'){
        setFormData({
          ...formData,
          [name]: value,
        });

      }else{
      //settotalItems(null);
      //setCurrentPage(1);
      setFormData({
        ...formData,
        [name]: value,
      });
    }
    }
  };

  const fetchJobs = async () => {
    setIsLoading(true);
    try{
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const integerJobRoles = Object.values(formData.jobRole).map(Number);
      const offset = formData.searchTitle !=='' ? 0 : parseInt(currentPage -1 ) * parseInt(postsPerPge);
      const raw = JSON.stringify({
        'function_ids' : formData.functions === 'Any' ? [] : [formData.functions],
        'job_role_ids' : integerJobRoles || [],
        'search_key' : formData.searchTitle ? formData.searchTitle : '',
        'offset': isNaN(offset) ? 0 : offset
       });
      const res = await axiosInstance.post('/job-list',raw, { headers : myHeaders});
      setJobData(res.data);
      SetPostsPerPage(res?.data?.data?.pager?.items_per_page);
      if(!totalItems && pagination === false){
        settotalItems(res?.data?.data?.pager?.total_items);
      }
      if(formData.functions !== 'Any' && pagination === false){
        settotalItems(res?.data?.data?.pager?.total_items);
      }
      if(formData.jobRole.length > 0 && pagination === false){
        settotalItems(res?.data?.data?.pager?.total_items);
      }
    }catch(error){
      setError(error);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
    setIsFetching(false);
  }
  useEffect(() => {
    fetchJobs();
   },[formData,currentPage,postsPerPge])

  
   const MainShimmerItem = () => {
    return (
      <>
      <div className=" animate-pulse h-[500px] mb-3 mt-[-80px] bg-gray-500"></div>
         
      <div className='text-center'>
        <div className="mx-auto w-[800px] h-[60px] mt-[20px] mb-[16px] bg-slate-300 rounded"></div>
        <div className="mx-auto w-[800px] h-[16px] mb-[50px] bg-slate-300 rounded"></div>
      </div>
         <div className="container mx-auto mt-6">
        <div className="flex flex-wrap -m-4">
          <div className="p-4 md:w-[33.33%]">
            <div className="h-full border-2 border-gray-200 rounded-lg overflow-hidden">
              <div className="lg:h-48 bg-gray-400 md:h-36 w-full object-cover object-center"></div>
              <div className="p-6">
                <h2 className="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></h2>
                <h1 className="w-1/2 mb-4 h-6 animate-pulse bg-gray-500"></h1>
                <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                <p className="leading-relaxed mb-3 w-2/3 h-3 animate-pulse bg-gray-400"></p>
                <p className="leading-relaxed mb-3 w-1/2 h-3 animate-pulse bg-gray-400"></p>
                <div className="flex items-center flex-wrap ">
                  <a className="bg-indigo-300 h-4 animate-pulse mt-2 w-32 inline-flex items-center md:mb-2 lg:mb-0">

                    </a>
                    <span className="bg-indigo-300 w-16 mt-2 h-4 animate-pulse mr-3 px-2 inline-flex items-center ml-auto leading-none text-sm pr-5 py-1">

                    </span>

                  </div>
                </div>
              </div>
            </div>
            <div className="p-4 md:w-[33.33%]">
              <div className="h-full border-2 border-gray-200 rounded-lg overflow-hidden">
                <div className="lg:h-48 bg-gray-400 md:h-36 w-full object-cover object-center"></div>
                <div className="p-6">
                  <h2 className="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></h2>
                  <h1 className="w-1/2 mb-4 h-6 animate-pulse bg-gray-500"></h1>
                  <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                  <p className="leading-relaxed mb-3 w-2/3 h-3 animate-pulse bg-gray-400"></p>
                  <p className="leading-relaxed mb-3 w-1/2 h-3 animate-pulse bg-gray-400"></p>
                  <div className="flex items-center flex-wrap ">
                    <a className="bg-indigo-300 h-4 animate-pulse mt-2 w-32 inline-flex items-center md:mb-2 lg:mb-0">

                    </a>
                    <span className="bg-indigo-300 w-16 mt-2 h-4 animate-pulse mr-3 px-2 inline-flex items-center ml-auto leading-none text-sm pr-5 py-1">

                    </span>

                  </div>
                </div>
              </div>
            </div>
            <div className="p-4 md:w-[33.33%]">
              <div className="h-full border-2 border-gray-200 rounded-lg overflow-hidden">
                <div className="lg:h-48 bg-gray-400 md:h-36 w-full object-cover object-center"></div>
                <div className="p-6">
                  <h2 className="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></h2>
                  <h1 className="w-1/2 mb-4 h-6 animate-pulse bg-gray-500"></h1>
                  <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
                  <p className="leading-relaxed mb-3 w-2/3 h-3 animate-pulse bg-gray-400"></p>
                  <p className="leading-relaxed mb-3 w-1/2 h-3 animate-pulse bg-gray-400"></p>
                  <div className="flex items-center flex-wrap ">
                    <a className="bg-indigo-300 h-4 animate-pulse mt-2 w-32 inline-flex items-center md:mb-2 lg:mb-0">

                    </a>
                    <span className="bg-indigo-300 w-16 mt-2 h-4 animate-pulse mr-3 px-2 inline-flex items-center ml-auto leading-none text-sm pr-5 py-1">

                    </span>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </>
    );
  };

   if(isFetching) return <MainShimmerItem />;
   if(error) { return <ErrorItem error={error} /> }
   
   return (
    <>
    <MetaTag metaData={jobData?.data?.meta[0]} />
    <section className="bg-white pb-10 dark:bg-dark ">
      <div className="banner-section">
      <div className="banner-img">
        <img src={processImageUrl(jobData?.data?.image)} alt={jobData?.data?.image_alt} className='w-full max-h-[600px]'/>
        </div>
        <div className="banner-content">
              <div className="banner-text">
                <h2>
                {!!jobData?.data?.banner_title
                  ? jobData?.data?.banner_title
                  : "Job Listing"}
                </h2>
                <p dangerouslySetInnerHTML={{ __html: jobData?.data?.banner_description }}>
                </p>
              </div>
        </div>
      </div>
      <div className="jobs-main min-[320px]:px-10 lg:px-20 overflow-hidden ">
        <div className="">
          <div className="heading-list text-center not-italic lg:text-[64px] text-[38px] font-bold lg:leading-[76px] leading-[50px] text-[#034d82] mt-[40px] mb-[30px]">{jobData?.data?.subtitle}</div>
          <div className="helping-text text-center text-[18px] leading-[1.4] text-[#444] lg:text-[20px] pb-[40px]" dangerouslySetInnerHTML={{__html:jobData?.data?.description}}></div>
          <div className='filters pb-[40px] block md:flex lg:flex flex-wrap md:m-[-15px] items-end'>
            <div className=' w-full md:w-[calc(33.33%_-_30px)] md:m-[15px] mb-[20px] md:mb-[0]'>
              <label htmlFor="first_name" className="block mb-2  text-[16px] text-[#646464] font-semibold">Search</label>
              <input 
              type="text" name="searchTitle" placeholder="Search Title" value={formData.searchTitle}  onChange={handleInputChange} 
              className="bg-gray-50 border border-[#90a3b4] text-[18px] text-[#3b3b3b] focus:border-[#646464] block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-[60px]" />
            </div>
            <div className='w-full job-filter md:w-[calc(33.33%_-_30px)] md:m-[15px] mb-[20px] md:mb-[0]'>
            <summary role="button" className="text-[#313131] filter-button text-[18px] p-[15px] w-full bg-[#fff] h-[60px] list-none border-solid border-[1px] border-[#90a3b4]" aria-controls="edit-field-functions-target-id-collapsible" aria-expanded="false" aria-pressed="false">Functions<span className="summary"></span></summary>
              <MultiSelectDropdown option_data={jobData?.data?.filters?.function_ids} formData={formData} handleInputChange={handleInputChange } />
            </div>
            <div className='w-full job-filter md:w-[calc(33.33%_-_30px)] md:m-[15px] md:mb-[0]'>
            <summary role="button" className="text-[#313131] filter-button text-[18px] p-[15px] w-full bg-[#fff] h-[60px] list-none border-solid border-[1px] border-[#90a3b4]" aria-controls="edit-field-functions-target-id-collapsible" aria-expanded="false" aria-pressed="false">Job Roles<span className="summary"></span></summary>
              <MultiSelectDropdown option_data={jobData?.data?.filters?.job_role_ids} formData={formData} handleInputChange={handleInputChange } />
            </div>
          </div>
          {
            jobData?.data?.list.length > 0 ? (
              <>
              <JobCard data={jobData?.data?.list} isLoading={isLoading}/>
              {totalItems > postsPerPge && (
              <Pagination length={totalItems} postsPerPage={postsPerPge} handlePagination={handlePagination} currentPage={currentPage}/>
              )}
            </>
            ) : (
              <div></div>
            )
          }
          {
            jobData?.data?.list.length > 0 ? (
              <div className='text-center flex flex-col items-center'>
                <div className="footer-list pt-[50px] pb-[30px] text-[#000] text-[30px]" dangerouslySetInnerHTML={{__html:jobData?.data?.footer}}></div>
                <button data-dialog-type="modal" className="submit-form block text-[#0071b3] text-center border border-black w-[200px] py-[10px] px-[5px] " onClick={() => openPopup()}> Send Your Resume</button>
              </div>

            ) : (
              <div className='text-center flex flex-col items-center'>
                <div className="footer-list pt-[50px] pb-[30px] text-[#000] text-[30px]" dangerouslySetInnerHTML={{__html:jobData?.data?.no_result_text}}></div>
              </div>
            )
          }

        </div>
      </div>
      <Modal
        isOpen={popupIsOpen}
        onRequestClose={closePopup}
        contentLabel="Modal"
        className={"resume-popup"}
        ariaHideApp={false}
      >
         <div className="resume-inner relative text-[#000] p-5 overflow-auto "> 
            <ApplyJob nid={null}/>
          </div>
          <button className="close-modal absolute bg-black rounded-[50%] h-12	w-12	my-[-17px] right-[-18px] top-[2px] text-xl text-white" onClick={closePopup}>X</button>
        </Modal>
    </section>
    </>
    )
}

const MultiSelectDropdown = ({ option_data, formData, handleInputChange }) => {
  // Check if option_data is defined and not null
  if (!option_data || typeof option_data !== 'object') {
    return null; // or handle the error in some way
  }
  

  return(
    <div className="form-radios bef-nested">
      {(option_data?.multiple === false) ? (
      <ul>
        <li>
          <label><input type="radio" name="functions" value="Any" onChange={handleInputChange} checked={formData.functions === "Any"} />Any</label>
      </li>
      {Object.entries(option_data?.options || {}).map(([key,value]) => (
        <li key={key}>
          <label>
            <input
              type="radio"
              name="functions"
              value={key}
              checked={formData.functions === key}
              onChange={handleInputChange}
            />
            {value}
          </label>
        </li>
      ))}
      </ul>
      ) : ( 
        /* Multi value select field*/
        <ul>
        {Object.entries(option_data?.options || {}).map(([key,value]) => (
          <li key={key}>
            <label>
              <input
                type="checkbox"
                name={key}
                value={key}
                checked={formData[key]}
                onChange={handleInputChange}
              />
              {value}
            </label>
          </li>
        ))}
        </ul>

      )}
    </div>
  )
}

const JobCard = ({ data, isLoading }) => {
  return (
    <>
    <div className="min-h-[610px]">
      {isLoading ? (
        <Loader />
      ) : (
        <ul className='listing block md:flex lg:flex md:flex-wrap lg:flex-wrap m-[-15px]'>
          {data.map((item, index) => (
            <li
              className='md:w-[calc(33.33%_-_30px)] m-[15px] bg-gradient-to-r from-[#0f5c74] to-[rgba(0,3,69,.91)] text-[#fff] p-[20px] lg:p-[40px] hover:scale-[1.05] shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.38)]'
              key={item.nid}
            >
              <Link to={item.view_node}>
                <h3 className='text-[18px] lg:text-[22px] font-bold pb-[15px] 'dangerouslySetInnerHTML={{__html:item?.field_job_title}}></h3>
              </Link>
              {item.field_experience && (
                <p className='experience relative text-[20px] pb-[10px] pl-[50px] before:left-[0] before:absolute before:h-[25px] before:w-[25px] before:top-[3px]'>
                  {item.field_experience}
                </p>
              )}
              {item.field_job_location && (
                <p className='location relative text-[20px] pb-[10px] pl-[50px] before:left-[0] before:absolute before:h-[25px] before:w-[25px] before:top-[3px]'>
                  {item.field_job_location}
                </p>
              )}
            </li>
            

          ))}
        </ul>
      )}
      </div>
    </>
  );
};


export default Jobs
