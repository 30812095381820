import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Config from "../../Const";
import { getAuthClient } from "../../auth/auth";
const Resetpassword = ({ id }) => {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [isMatchPass, setMatchPass] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false)
    const auth = getAuthClient();
    const ids = id.split('/');
    const onSubmit = async (data) => {
        setLoading(true)


        if (data.password === data.cpassword) {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                "id": ids[0],
                "password": data.password
            });
            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };
            const decode_data = decodeURIComponent(escape(atob(ids[0])))
            const d_decode_data = decode_data.split('$');
            const user_name = decodeURIComponent(escape(atob(d_decode_data[1])))

            fetch(`${auth.config.base}/api/v1/forgot-password?_format=json`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.data.response) {
                        setErrorMessage('')
                        setSuccessMessage('Password successfully reset!!!');

                        if (ids[1] === "active") {
                            auth.login(user_name, data.password).then((res) => {
                                if (res.access_token) {
                                    setLoading('Please wait...');
                                    setTimeout(() => {
                                        navigate('/dashboard')
                                    }, 3000)
                                }
                            }).catch((error) => {
                                console.log(error);
                            })

                        } else {
                            setTimeout(() => {
                                navigate('/login');
                            }, 3000)
                        }

                    } else {
                        setErrorMessage(result.data.status);
                        setSuccessMessage('')
                    }
                    setLoading(false)
                })
                .catch((error) => console.error(error));
            setMatchPass(true)
        } else {
            setMatchPass(false);
            setLoading(false);
        }
    }


    return (
        <div className="forgot-password lg:pb-[0] flex justify-center text-center lg:min-w-[450px] w-full bg-[#f3f4f6] lg:p-14 py-[50px] px-[10px]">
            <form className="form lg:mt-[0] md:mt-[100px] lg:min-h-[290px] md:min-h-[290px] min-h-[250px] p-10 lg:w-[500px] max-w-[500px] md:min-w-[450px] min-w-[350px] rounded-2xl shadow-2xl w-full" onSubmit={handleSubmit(onSubmit)}>
                <label className="heading p-[0] text-[25px] m-[0] block font-semibold text-left mb-[20px] pb-[10px]">{ids[1] === 'active' ? 'Set Your Password' : 'Reset Your Password'}</label>
                {!isMatchPass && <p className="text-red-500">Password mismatch</p>}
                {successMessage && <p className="text-green-500">{successMessage}</p>}
                {errorMessage && <p className="text-red-500">{errorMessage}</p>}
                <input
                    type="password"
                    name="password"
                    id="password"
                    maxLength={30}
                    className="lg:ml-[0] md:ml-[0] ml-[0] placeholder-gray-700 placeholder-opacity-50 lg:mb-[20px] md:mb-[20px] mb-[15px] rounded-[8px] w-full lg:m-3 m-0 block border border-gray-400 py-3 px-1 lg:mt-3 my-[5px]"
                    placeholder="Password"
                    {...register("password", {
                        required: "Password is required",
                        maxLength: {
                            value: 30,
                            message: "Too long characters"
                        },
                        onChange: (e) => setMatchPass(true),
                    })}
                />
                {errors.password && (
                    <p className="text-red-500">{errors.password.message}</p>
                )}
                <input
                    type="password"
                    name="cpassword"
                    id="cpassword"
                    maxLength={30}
                    className="lg:ml-[0] md:ml-[0] ml-[0] placeholder-gray-700 placeholder-opacity-50 lg:mb-[20px] md:mb-[20px] mb-[15px] rounded-[8px] w-full lg:m-3 m-0 block border border-gray-400 py-3 px-1 lg:mt-3 my-[5px]"
                    placeholder="Confirm password"
                    {...register("cpassword", {
                        required: "Confirm password is required",
                        maxLength: {
                            value: 30,
                            message: "Too long characters"
                        },
                        onChange: (e) => setMatchPass(true),
                    })}
                />
                {errors.cpassword && (
                    <p className="text-red-500">{errors.cpassword.message}</p>
                )}
                <button className="m-[10px] rounded-[8px] px-8 py-2 btn bg-black text-white text-center p-[10px]" disabled={loading ? true : false}>{loading ? 'please wait...' : 'submit'}</button>
            </form>
        </div>
    )
}

export default Resetpassword;