import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Resetpassword from "./ResetPassword";
import Config from "../../Const";
import { getAuthClient } from "../../auth/auth";
const ForgetPassword = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false)
    const [queryParam, setQueryParam] = useState('');
    const url = Config.base_url;
    const auth = getAuthClient();
    const onSubmit = async (data) => {
        setLoading(true);

        const requestOptions = {
            method: "POST",
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
                "name": data.username,
                "base_url": Config.base_url
            }),
            redirect: "follow"
        };

        fetch(`${auth.config.base}/api/v1/forgot-password`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if(result.data.response) {
                    setErrorMessage('');
                    setSuccessMessage('Link send to your mail to reset password');
                } else {
                    setErrorMessage('Wrong username or email');
                    setSuccessMessage('');
                }
                setLoading(false);
            })
            .catch((error) => console.error(error));
    }

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const paramValue = params.get('id');
        setQueryParam(paramValue);

    }, []);
    return (
        <div className="forgot-password flex justify-center text-center lg:min-w-[450px] w-full bg-[#f3f4f6] lg:p-14 py-[50px] px-[10px]">
        <div className="lg:m-[40px] m-[0]">
        {/* <label className="lg:mb-[25px] text-[25px] md:mb-[25px] mb-[15px] block p-3 font-semibold">Find Your Account</label> */}
            {
                queryParam ? <Resetpassword id={queryParam} /> : <form className="form mt-[50px] lg:mt-[50px] md:mt-[100px] lg:min-h-[290px] md:min-h-[290px] min-h-[250px] p-10 lg:w-[500px] max-w-[500px] md:min-w-[450px] min-w-[350px] rounded-2xl shadow-2xl w-full" onSubmit={handleSubmit(onSubmit)}>
                    {successMessage && <p className="text-green-500">{successMessage}</p> }
                    <label className="heading p-[0] text-[25px] m-[0] block font-semibold text-left mb-[20px] pb-[10px]">Find Your Account</label>
                    <label className="lg:mb-[25px] md:mb-[25px] mb-[15px] block font-[500] text-left">Please enter your email address or mobile number to search for your account.</label>
                    <input
                        type="text"
                        name="username"
                        id="username" maxLength={40}
                        placeholder="Enter your Username or Email Address"
                        className="lg:ml-[0] md:ml-[0] ml-[0] placeholder-gray-700 placeholder-opacity-50 lg:mb-[20px] md:mb-[20px] mb-[15px] rounded-[8px] w-full lg:m-3 m-0 block border border-gray-400 py-3 px-1 lg:mt-3 my-[5px]"
                        {...register("username", {
                            required: "Username or email is required",
                        })}
                    />
                    {errorMessage && <p className="text-red-500">{errorMessage}</p>}
                    {errors.username && (
                        <p className="text-red-500 mb-3">{errors.username.message}</p>
                    )}
                    <button className="m-[10px] rounded-[8px] px-8 py-2 btn bg-black text-white text-center p-[10px]" disabled={loading ? true : false}>{loading ? 'please wait...' : 'submit'}</button>
                </form>
            }
        </div >
        </div>
    )
}

export default ForgetPassword;