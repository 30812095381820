import oops from '../../assets/images/error.jpeg'

function ErrorPage() {
  
    return (
        <section className="bg-white pb-10 pt-[80px] pl-[20px] pr-[20px] dark:bg-dark lg:pb-20 ">
          <div className='container my-[0] mx-[auto]'>
              <div className='text-center flex justify-center'>
                <img src={oops} alt='oops' className='h-[360px] lg:h-[600px] md:h-[600px]'/>
              </div>
              <p className="text-center text-black text-[18px] pt-[20px] tracking-[2px] text-shadow">
              Sorry but it seems like you have lost your way. The link you followed may be broken or the page may have been removed.<a  className="text-[#0071b3]" href="https://reactdemo-application.qa2.tothenew.net/"> Click here </a>to get back to homepage. Happy searching
              </p>
              </div>
      </section>
       
    ) 
}

export function ErrorItem({error}){
  return (
    <div className="min-h-[500px] flex items-center justify-center"><div className="flex-[1_1_100%] text-center p-[48px]">
      <h1 className="text-[48px] font-bold mb-[10px] text-blue-900 leading-[48px]">{error?.code}</h1>
      <p className="text-[20px] m-0 text-gray-600">{error?.message}</p>
    </div></div>
  )
}

export default ErrorPage
