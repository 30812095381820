import React, {useEffect, useState} from 'react';
import icons from '../../Icons';
import { Link } from 'react-router-dom';
import axiosInstance from '../../services/axios'

const LanguageList = () => {
  const [langaugeSwitcher, setLanguageSwitcher] = useState({})

  useEffect(() => {
    const fetchLanguageData = async () => {
        try {
          const res = await axiosInstance.get('/read/language-switcher');
          setLanguageSwitcher(res.data.data);
        } catch(err){}
      }
      fetchLanguageData();
    }, []);

   const handleLanguageSelect = (item) => {
    localStorage.setItem("selectedLanguage", item);
   }
  
  return (
    <div className=' relative py-[33px] '>
      <Link to="#" className="language-dropdown"><img className="" src={icons.LanguageFont} alt="Search" /></Link>
      <div id ="language-box" className="bg-white w-64 absolute top-[100%] 	right-0 	pt-10 pb-5 px-10">
        <ul className='text-stone-600	text-xs flex flex-wrap justify-between'>
          {Object.keys(langaugeSwitcher).map((item, index) => (
            <li onClick={() => handleLanguageSelect(item)} key={index} className="flex items-center	gap-2  mb-5 cursor-pointer">
              <img src={langaugeSwitcher?.[item]?.icon} alt={langaugeSwitcher?.[item]?.name}/>
              {langaugeSwitcher?.[item]?.name}
              </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default LanguageList



