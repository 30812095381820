import React, { useEffect, useState } from "react";
import icons from "../../Icons";
import Modal from "react-modal";
import axiosInstance from "../../services/axios";
import { processImageUrl } from "../../utils/utils";

const ExpandedTestimonials = () => {
  const [expandedData, setExpandedData] = useState([]);
  const [selectedItem , setSelectedItem] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = (index) => {
    setSelectedItem(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get("/read/expanded-testimonials");
        setExpandedData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(function(){
    window.scrollTo(0,0);
   },[])
   
  return (
    <div className="expanded-list-container pb-10 glossary-inner min-[320px]:px-10 lg:px-20  lg:pt-[80px] md:pt-[50px] pt-[50px]">
      <h2 className="text-4xl lg:text-5xl font-bold	text-[#034d82] text-center	mb-[30px]">Client Testimonials</h2>
      <ul className="flex flex-wrap m-[-15px]">
      {expandedData.map((item, index) => (
          <li key={index} className=" w-[calc(100%_-_30px)] lg:w-[calc(33.33%_-_30px)] md:w-[calc(50%_-_30px)] m-[15px] list-none p-5 border border-solid border-black rounded-[20px] shadow-[10px_10px_5px_#aaa] min-h-80">
            <div className="listing-testimonial relative">
              <div className="listing-info flex gap-[14px]">
              <div className="listing-left min-w-[65px]">
              <div className="listing-icon-image">
                    <img
                      loading="lazy"
                      src={processImageUrl(item?.field_icon_image)}
                      alt="img"
                      className="image-style-thumbnail w-[65px] h-[65px] rounded-[30px]"
                    />
                  </div>
              </div>
              <div className="listing-right">
              <div className="listing-name capitalize	 mb-3 text-base text-[#034d82] font-bold leading-9"> {item?.field_name} </div>
              <div className="listing-desc ">
              <div className="listing-content contents text-[15px] leading-6 text-[#4f4f4f] pl-20 tracking-wide" dangerouslySetInnerHTML={{__html: `${item?.field_description?.slice(0, 425)}... `}} />
              {item?.field_description?.length > 425 && (
                <button
                  data-dialog-type="modal"
                  className="btn inline-block text-[#0071b3]"
                  onClick={() => openModal(index)}
                >
                  Read More
                </button>
              )}
              </div>
              </div>
              </div>
            </div>
          </li>
        
      ))}
      </ul>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Modal"
        className={"testimonial-modal"}
        ariaHideApp={false}
      >
        <li className=" m-[24px] p-5 list-none relative">
            <div className="listing-testimonial ">
              <div className="listing-info flex justify-between">
                <div className="listing-left flex gap-[14px]">
                  <div className="listing-icon-image">
                    <img
                      loading="lazy"
                      src={processImageUrl(expandedData?.[selectedItem]?.field_icon_image)}
                      alt="img"
                      className="image-style-thumbnail w-[65px] h-[65px] rounded-[30px]"
                    />
                  </div>
                  <div className="listing-name capitalize	 text-base text-[#034d82] font-bold leading-9"> {expandedData?.[selectedItem]?.field_name} </div>
                </div>
                <div className="listing-right">
                  <div className="listing-coma-image">
                    <img src={processImageUrl(icons.PopupComa)} alt={expandedData?.[selectedItem]?.field_name} />
                  </div>
                </div>
              </div>
              <div className="listing-desc text-sm leading-6 text-[#4f4f4f] md:pl-20 tracking-wide " dangerouslySetInnerHTML={{__html: expandedData?.[selectedItem]?.field_description}} />
            </div>
          </li>
        <button className="close-modal absolute bg-black rounded-[50%] h-12	w-12	my-[-17px] right-[-18px] top-[2px] text-xl text-white" onClick={closeModal}>X</button>
      </Modal>
    </div>
  );
};

export default ExpandedTestimonials;
