import React, { useState, useEffect } from 'react';
import Modal from "react-modal";
import axiosInstance, { axiosBaseInstance } from '../../services/axios';
import InfiniteScroll from 'react-infinite-scroller';

const Comment = ({ alias, nid }) => {
  let uid = 0;
  if(localStorage.getItem('userInfo') !== null){
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    uid = userInfo.uid;
  }

  const [commentPopupIsOpen, setCommentPopupIsOpen] = useState(false);
  const [comments, setComments] = useState([]);
  const [saveComment, setSaveComment] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [postComment, setPostComment] = useState(0);
  const [cid, setCid] = useState(null);
  const [type, setType] =useState("post")

  const handleCidChange = (cid) => {
    setCid(cid);
  };

  const openCommentPopup = (commentType) => {
    setSaveComment('');
    setErrorMessage('');
    setCommentPopupIsOpen(true);
    setType(commentType);
  };

  const closeCommentPopup = () => {
    setCommentPopupIsOpen(false);
  };
   const fetchComment = async () => {
      setLoading(true);
      try {
        const rescomment = await axiosInstance.get(`/read/node/comments?alias=${alias}`);
        setComments(rescomment.data.data);
        setLoading(false);
        setPostComment(0);
      } catch (error) {
        setError(error);
        console.log(error);
      }
    };
  useEffect(() => {
 

    fetchComment();
  }, [alias, postComment]);


  const handleSaveComment = async () => {
    let pid = [];
    try {
      if (saveComment.trim() === '') {
        // alert('Please enter a comment before saving.');
        setErrorMessage('This field is required.');
        return;
      }

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      
      if(cid !== null){
        pid = [{"target_id": cid,"target_type": "comment"}]
      }

      const raw = JSON.stringify({
        "entity_id": [{ "target_id": nid }],
        "entity_type": [{ "value": "node" }],
        "comment_type": [{ "target_id": "comment" }],
        "field_name": [{ "value": "field_comment" }],
        "subject": [{ "value": saveComment.length > 30 ? saveComment.substring(0, 30) + '...' : saveComment }],
        "comment_body": [{ "value": saveComment.trim() }],
        "status": [{ "value": 1 }],
        "pid": pid,
        "uid": [{ "target_id": uid, "target_type": "user" }]
      });
      const response = await axiosBaseInstance.post('/comment',
        raw,
        { headers: myHeaders }
      );
      setPostComment(1);
      setCid(null);
      closeCommentPopup();
    } catch (error) {
      console.error('Error saving comment:', error);
    }
  }
  
  return (
    <div className="antialiased">
      {comments.length > 0 &&
        <h3 className="text-[#034d82] text-[30px] font-bold leading-[32px] pb-[30px]">Comments</h3>
      }

      <MessageBox
        commentData={comments}
        openCommentPopup={openCommentPopup}
        parentIndex={0}
        handleSaveComment={handleSaveComment}
        closeCommentPopup={closeCommentPopup}
        saveComment={saveComment}
        setSaveComment={setSaveComment}
        onCidChange={handleCidChange}
        uid={uid}
      />
      <Modal
        isOpen={commentPopupIsOpen}
        onRequestClose={closeCommentPopup}
        contentLabel="Modal"
        className={"comment-popup"}
        appElement={document.getElementById('root')}
        ariaHideApp={false}
      >
        <div className='comment-popup-container'>
          <textarea
            placeholder={type === "reply"?"Reply on comment":'Post your comment..'}
            value={saveComment}
            onChange={(e) => setSaveComment(e.target.value)}
          />
          <div className='text-center'>
          {errorMessage && <p className="text-red-500">{errorMessage}</p>}
            <button
              className='save-post-btn inline-block rounded text-white py-4 px-[60px] my-[20px]'
              onClick={handleSaveComment}
            >
              Submit
            </button>
          </div>
        </div>
        <button className='absolute bg-white rounded-[50%] h-12	w-12	my-[-17px] top-[-45px] lg:left-[48%] md:left-[44%] left-[45%]	text-xl font-black' onClick={closeCommentPopup}>X</button>

      </Modal>
      <div className="text-right position-postbtn">
        {
          uid > 0 ? (
            <a className='cursor-pointer comment-post-btn inline-block rounded text-white	py-3 px-[25px] mt-[40px]' onClick={() => openCommentPopup("post")}>Post comment</a>
          ): ''
        } 
      </div>
    </div>
  )
}

const MessageBox = ({ commentData, openCommentPopup, parentIndex, handleSaveComment, onCidChange, uid }) => {
  const [cidValue, setCidValue] = useState(null);
  const [count, setCount] = useState(3);

  const handleCidChange = (cid, commentType) => {
    setCidValue(cid);
    onCidChange(cid);
    openCommentPopup(commentType);
  };

  const onLoadHandler = () => {
   setTimeout(() => {
    setCount(prevState => {
      return prevState+3;
    });
   },1500) 
  }

  return (
    <>
      <InfiniteScroll
        pageStart={0}
        loadMore={onLoadHandler}
        hasMore={count < commentData?.length}
        loader={
          <div key="loader" className='flex gap-6'>
            <div className='w-[50px] h-[50px] rounded-[50%] bg-slate-300'></div>
            <div className=" w-[400px] lg:w-[650px] h-[100px]  bg-slate-300 rounded"></div>
          </div>
        }
      >
        {commentData &&
          commentData.slice(0,count).map((data, index) => {
            let currentIndex = parentIndex;

            return (
              <div className="space-y-4 comment-sec-reply" key={index}>
                <div className="flex flex-nowrap mb-[20px]">
                  <div className=" mr-[20px] mb-[20px] md:mb-[20px]  w-[50px]">
                    <div className="pb-[10px] flex justify-end">
                      <img
                        className="mt-3 rounded-full max-w-[50px] max-h-[50px]"
                        src={data.user.image}
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    className={`max-w-[312px] lg:max-w-full md:max-w-full comment relative ${
                      currentIndex % 2 == 0 ? "bg-gray-100" : "bg-slate-300"
                    } rounded-lg sm:px-6 sm:py-4 leading-relaxed px-[25px] py-[20px] w-100% md:w-[95%] lg:w-[45%] before:content-[''] before:absolute before:border before:border-l-0 `}
                  >
                    <span className="text-[#0071b3] block break-words font-bold text-[18px] mr-[10px]">
                      {data.user.name}
                    </span>{" "}
                    <span className=" text-[#000] text-[13px]">
                      {data.user.date}
                    </span>
                    <div className="text-[#000] py-[2px]">{data.subject}</div>
                    {uid > 0 ? (
                      <button
                        className="reply-comment-btn text-[13px] font-bold text-[#0071b3]"
                        onClick={() => handleCidChange(data.cid, "reply")}
                      >
                        Reply
                      </button>
                    ) : (
                      ""
                    )}
                    {data[`children`] !== undefined && (
                      <>
                        <h4 className="my-3 uppercase tracking-wide text-gray-400 font-bold text-xs">
                          Replies
                        </h4>
                        <MessageBox
                          commentData={data?.children}
                          parentIndex={parentIndex + 1}
                          openCommentPopup={openCommentPopup}
                          handleSaveComment={handleSaveComment}
                          onCidChange={onCidChange}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </InfiniteScroll>
    </>
  );

}
export default Comment