// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-footer {
  padding-top: 3%;
  padding-bottom: 1%;
}
.home-footer .common-footer-about {
  padding-bottom: 20px;
}
.home-footer .common-footer-about h3 {
  font-size: 36px;
  letter-spacing: 0.3px;
  font-weight: 700;
  color: #fff;
  line-height: 1.3;
  margin-bottom: 20px;
}
.home-footer .common-footer-about p {
  letter-spacing: 0.5px;
  font-feature-settings: "salt" on, "liga" off;
  color: #b4b3b3;
  margin: 0;
  padding-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/footer.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,kBAAA;AACJ;AACI;EACI,oBAAA;AACR;AACQ;EACI,eAAA;EACA,qBAAA;EACA,gBAAA;EACA,WAAA;EACA,gBAAA;EACA,mBAAA;AACZ;AAEQ;EACI,qBAAA;EACA,4CAAA;EACA,cAAA;EACA,SAAA;EACA,oBAAA;AAAZ","sourcesContent":[".home-footer {\n    padding-top: 3%;\n    padding-bottom: 1%;\n\n    .common-footer-about {\n        padding-bottom: 20px;\n\n        h3 {\n            font-size: 36px;\n            letter-spacing: .3px;\n            font-weight: 700;\n            color: #fff;\n            line-height: 1.3;\n            margin-bottom: 20px;\n        }\n\n        p {\n            letter-spacing: .5px;\n            font-feature-settings: \"salt\" on, \"liga\" off;\n            color: #b4b3b3;\n            margin: 0;\n            padding-bottom: 15px;\n        }\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
