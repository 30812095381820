import React, { useState, useEffect } from "react";
import SlickSlider from "../slick/SlickSlider";
import "../../assets/scss/homepage.scss";
import { Link } from "react-router-dom";
import axiosInstance from "../../services/axios";
import ReactPlayer from "react-player";

const Banner = () => {
  const [bannerslider, setBannerlider] = useState([]);
  const [activeSlide,setActiveSlide] = useState(0);

  const sliderSettings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 300,
    slidesToShow: 1,
    autoplay: true,
    afterChange:(current)=>{
      setActiveSlide(current);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get("/read/slider/60");
        setBannerlider(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="banner-wrapper text-white min-h-[645px] ">
      <div>
        <SlickSlider
          settings={sliderSettings}
          slides={bannerslider.map((item, index) => {
            return item.field_banner_layout === "text_and_image" ? (
              <div className="flex  pt-8 flex-col lg:flex-row" key={index}>
                <div className="lg:w-1/2 w-full	 left px-10 lg:px-0 lg:pl-[80px]">
                  <strong className="block text-center	md:text-start	 lg:text-[64px] text-[38px] font-bold lg:leading-[76px] leading-[50px] w-full lg:w-11/12 sm:w-11/12 ">
                    {item.field_title}
                  </strong>
                  <p className="text-base text-center	md:text-start	lg:leading-8 leading-6 font-normal lg:pt-[65px] pt-[10px] lg:max-w-[56%]">
                    {item.field_description}
                  </p>
                  <div className="text-center  lg:text-left">
                    <Link
                      target={"_blank"}
                      to={item.field_slider_link}
                      className="py-[16px] px-[36px] me-2 lg:mt-14 mt-3	lg:mb-14 mb-3 text-base  font-medium text-sky-500 focus:outline-none bg-white rounded border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-block"
                    >
                      See how it works
                    </Link>
                  </div>
                </div>
                <div className="lg:w-1/2 w-full mb-10 md:mb-2 right px-10 lg:px-0 lg:pr-[80px]">
                  <img
                    src={item.field_image}
                    className=" pr-[0] w-full h-[250px] md:h-[469px] mt-5 rounded-tl-[10px] rounded-bl-[10px] rounded-tr-[10px] rounded-br-[10px]"
                    alt="banner-img"
                  />
                </div>
              </div>
            ) : item.field_banner_layout === "video" ? (
              <div className="" key={index}>
                <div className="banner-section ">
                  <div className="banner-img">
                    <ReactPlayer
                      url={item.field_media_oembed_video}
                      autoPlay={true}
                      playing={activeSlide === index ? true:false}
                      muted={true}
                      controls={true}
                      playsinline={true}
                      className="video w-full h-[900px] lg:h-[638px] max-h-[638px]"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="" key={index}>
                <div className="banner-section ">
                  <div className="banner-img">
                    <img
                      src={item.field_image}
                      alt="banner-img"
                      className="w-full max-h-[638px]"
                    />
                  </div>
                  <div className="banner-content top-[276px] md:top-8 ">
                    <div className="banner-text !p-0">
                      <strong className="block text-center	md:text-start	 lg:text-[64px] text-[38px] font-bold lg:leading-[76px] leading-[38px] w-full lg:w-11/12 sm:w-11/12 ">
                        {item.field_title}
                      </strong>
                      <span className="text-base block text-center	md:text-start	lg:leading-8 leading-6 font-normal lg:pt-[65px] pt-[10px] lg:max-w-[56%]">
                        {item.field_description}
                      </span>
                      <div className="text-center  lg:text-left">
                        <Link
                          target={"_blank"}
                          to={item.field_slider_link}
                          className="py-[16px] px-[36px] me-2 lg:mt-14 mt-3	lg:mb-14 mb-3 text-base  font-medium text-sky-500 focus:outline-none bg-white rounded border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-block"
                        >
                          See how it works
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        />
      </div>
    </div>
  );
};

export default Banner;
