import React from 'react'
import {Link} from 'react-router-dom'
import blogsimg from '../../assets/images/blogs.png'

const CardList = ({ posts, handleFilterChange }) => {

  return (   
<>
<ul className='flex flex-wrap m-[-12px] p-[20px] lg:p-[0]'>
{posts.map((data, index) => (

    <li key={data.nid} className="shadow-neutral-700 shadow-lg rounded-xl w-[calc(100%_-_30px)] md:w-[calc(50%_-_30px)] xl:w-[calc(33.33%_-_30px)] m-[15px] relative" >
        <div className="blog-imge rounded-t-[12px] transform transition-transform duration-200 hover:scale-90 p-[8px] pb-0">
            <Link to={data?.blog_url}>
                <img src={data.blog_image ? data.blog_image: blogsimg} alt={data.blog_image_alt} className='rounded-t-[12px] w-full' />
            </Link>
        </div>
        <div className='blogs-bottom-sec p-[20px] lg:pb-[60px]'>
        <div className=''>
        <Link to={data?.blog_url}>
            <h5 className="md:mb-[40px] mb-[40px] lg:mb-2 text-[#034d82] font-bold text-[20px] line-clamp-3 pb-[8px] leading-[30px]">{data.title.length > 0 && (
                    <div dangerouslySetInnerHTML={{ __html: data.title }} />
                  )}</h5>
        </Link>
        </div>
        <div className='container flex items-center	lg:justify-between md:justify-between sm:justify-start	mb-[20px] md:w-[81%] 2xl:w-[84%] absolute bottom-0 '>
            <span className="category-btn mr-[60px] lg:mr-[30px] md:mr-[30px] w-[127px]">
                <Link onClick={handleFilterChange} className='block text-center hover:font-extrabold font-normal text-[12px] text-white pr-[15px] pl-[15px] py-[9px] uppercase cursor-pointer' to={`/blogs?cid=${data.category_id}`}>  {data.categories}</Link>
            </span>
            <Link to={data?.blog_url}>
                <span className='reads text-[#000] capitalize font-bold text-[16px] relative after:absolute after:h-[29px] after:w-[29px] after:top-[53%] after:left-[96%]  after:ml-[15px] after:translate-y-[-50%] after:transition after:duration-[.2s] hover:after:translate-y-[-50%] hover:after:translate-x-[5px]'> {data.read_time} Read </span>
            </Link>
        </div>
        </div>
    </li>
   
))}
</ul>
</>

  )
}

export default CardList

