import React, { useEffect, useState } from 'react';
import axiosInstance from '../../services/axios';
import { useParams } from 'react-router-dom'
import "../../assets/scss/static-pages.scss";
import Loader from '../../components/global/Loader';
const ErrorItem = ({error}) => {
    return (
      <div className="min-h-[500px] flex items-center justify-center"><div className="flex-[1_1_100%] text-center p-[48px]">
        <h1 className="text-[48px] font-bold mb-[10px] text-blue-900 leading-[48px]">{error?.code}</h1>
        <p className="text-[20px] m-0 text-gray-600">{error?.message}</p>
      </div></div>
    )
  }

  const Pages = () => {
    const [content,setContent] = useState([]);
    const [error,setError] = useState(null);
    const [loading,setLoading] = useState(true);
    const {id} = useParams();
    useEffect(() => {
    const fetchContent = async () => {
      setLoading(true);
        try {
          const res = await axiosInstance.get(`/read/static?alias=/page/${id}`);
          setContent(res.data);
          setLoading(false)
        } catch(err){
          setError(err);
          setLoading(false);
        }
        setLoading(false);
      }
      fetchContent();
    }, [id]);
    if (loading) {
      return <p className='py-[500px] text-center'><Loader /></p>
    }
if(error) return <ErrorItem error={error} />;
if(!content && !loading){
return <div>Content is under review!</div>
}
  
    return (
      <>
        <section className="static-page bg-[#f8f8f8] pb-10 pt-[80px] min-[320px]:px-10 lg:px-20 dark:bg-dark lg:pb-20 ">
        <div className="page-title flex justify-center items-end text-center lg:items-center mt:-[30px] mb-[30px] lg:mt-[50px] ">
            <h2 className='text-[#034d82] font-bold text-[38px] lg:text-[48px] tracking-[-1px] capitalize '>
            {content.data && content.data.length > 0 && (
              <div dangerouslySetInnerHTML={{__html: content.data[0].title}} />
           )}
            
            </h2>
        </div> 
           {content.data && content.data.length > 0 && (
              <div dangerouslySetInnerHTML={{__html: content.data[0].body}} />
           )}
          
        </section>
        </>
    )
       
      
      
    

  
}

export default Pages
