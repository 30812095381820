import StateList from "./StateList";
import LanguageList from "./LanguageList";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, NavLink } from "react-router-dom";
import { logout } from "../../features/auth/authSlice";
import Img from "../../assets/images/TechSpotLight.png";

const Nav = () => {
  const userInfo = useSelector((state) => state.auth);
  const [toggleNavbar, setToggleNavbar] = useState(false);
  const [userDetails, setUserDetails] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [localStorageData, setLocalStorageData] = useState(null);
  useEffect(() => {
    const getUser = async () => {
      if (userInfo.isAuthenticated) {
        const userData = await axios.get(
          `https://demo-application.qa2.tothenew.net/user/${userInfo?.user?.user?.uid}?_format=json`
        );
        setUserDetails(userData);
      }
    };
    const data = localStorage.getItem("userInfo");
    setLocalStorageData(JSON.parse(data));
    getUser();
  }, [userInfo]);

  const toggleNavbarHandler = () => {
    setToggleNavbar(!toggleNavbar);
  };

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem("userInfo");
    localStorage.removeItem("drupal-oauth-token");
    navigate("/");
  };
  const handleScrollToTop = () => {
    const header = document.querySelector("body");

    if (header) {
      header.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <nav>
      <div className="flex flex-wrap justify-between items-center min-[320px]:px-10 lg:px-20 py-[15px] md:py-0 ">
        <NavLink
          to="/"
          className=" nav-link flex items-center space-x-3 rtl:space-x-reverse"
        >
          {/* <span className="newi self-center text-2xl whitespace-nowrap dark:text-white">Newi</span> */}
          <img src={Img} alt="logo" className="-ml-3 w-[160px] h-auto "></img>
        </NavLink>
        <button
          id="hamburger-menu"
          onClick={toggleNavbarHandler}
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden "
        >
          <span className="sr-only">Open main menu</span>
          {!toggleNavbar ? (
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          ) : (
            <svg
              height="24"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill="#fff" fillRule="nonzero">
                <path d="m12 22c5.5228475 0 10-4.4771525 10-10s-4.4771525-10-10-10-10 4.4771525-10 10 4.4771525 10 10 10zm0 2c-6.627417 0-12-5.372583-12-12s5.372583-12 12-12 12 5.372583 12 12-5.372583 12-12 12z" />
                <path d="m9.20710678 16.2071068c-.39052429.3905243-1.02368927.3905243-1.41421356 0s-.39052429-1.0236893 0-1.4142136l6.99999998-6.99999998c.3905243-.39052429 1.0236893-.39052429 1.4142136 0s.3905243 1.02368927 0 1.41421356z" />
                <path d="m7.79289322 9.20710678c-.39052429-.39052429-.39052429-1.02368927 0-1.41421356s1.02368927-.39052429 1.41421356 0l7.00000002 6.99999998c.3905243.3905243.3905243 1.0236893 0 1.4142136s-1.0236893.3905243-1.4142136 0z" />
              </g>
            </svg>
          )}
        </button>
        <div
          id="mega-menu-full"
          className={`items-center justify-between ${
            !toggleNavbar && "hidden"
          } w-full lg:flex lg:w-auto lg:order-1`}
        >
          <ul className="animated-list flex flex-col items-center mt-4 font-medium lg:flex-row lg:mt-0 lg:space-x-8 rtl:space-x-reverse text-sm leading-4 tracking-wider">
            <li>
              <NavLink
                to="/"
                className="block py-4 text-white border-b border-gray-100  lg:hover:bg-transparent lg:border-0  dark:text-white  dark:hover:bg-gray-700  md:dark:hover:bg-transparent dark:border-gray-700 max-[767px]:focus:bg-sky-300"
                aria-current="page"
                onClick={handleScrollToTop}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/blogs">
                <button
                  id="mega-menu-full-dropdown-button"
                  className="flex items-center justify-between w-full py-4 font-medium text-white border-b border-gray-100 md:w-auto   lg:hover:bg-transparent lg:border-0  dark:text-white  dark:hover:bg-gray-700  md:dark:hover:bg-transparent dark:border-gray-700  max-[767px]:focus:bg-sky-300"
                  onClick={handleScrollToTop}
                >
                  Blog
                  {/* <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                </svg> */}
                </button>
              </NavLink>
              <div
                id="mega-menu-full-dropdown"
                className="absolute left-0 w-[100%] z-10 top-[100%] bg-white border-gray-200 shadow-sm border-y dark:bg-gray-800 dark:border-gray-600"
              >
                <div className="flex flex-wrap max-w-screen-xl mx-auto gap-10 py-5">
                  <ul aria-labelledby="mega-menu-full-dropdown-button">
                    <li>
                      <NavLink
                        to="/"
                        className="px-6 py-3 hover:bg-blue-100 dark:hover:bg-blue-700 flex flex-wrap"
                      >
                        <div className="text-gray-900 font-semibold mr-3">
                          Online Stores
                        </div>
                        <svg
                          className="w-4 h-6 text-gray-600"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 10"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M1 5h12m0 0L9 1m4 4L9 9"
                          ></path>
                        </svg>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/"
                        className="px-6 py-3 hover:bg-blue-100 dark:hover:bg-blue-700 flex flex-wrap"
                      >
                        <div className="text-gray-900 font-semibold mr-3">
                          Segmentation
                        </div>
                        <svg
                          className="w-4 h-6 text-gray-600"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 10"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M1 5h12m0 0L9 1m4 4L9 9"
                          ></path>
                        </svg>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/"
                        className="px-6 py-3 hover:bg-blue-100 dark:hover:bg-blue-700 flex flex-wrap"
                      >
                        <div className="text-gray-900 font-semibold mr-3">
                          Marketing CRM
                        </div>
                        <svg
                          className="w-4 h-6 text-gray-600"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 10"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M1 5h12m0 0L9 1m4 4L9 9"
                          ></path>
                        </svg>
                      </NavLink>
                    </li>
                  </ul>
                  <div className="flex flex-wrap">
                    <ul>
                      <li>
                        <NavLink
                          to="/"
                          className="block p-3 rounded-lg  dark:hover:bg-gray-700"
                        >
                          <div className="text-gray-900 font-semibold">
                            Online Stores
                          </div>
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            Connect with third-party tools that you're already
                            using.
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/"
                          className="block p-3 rounded-lg  dark:hover:bg-gray-700"
                        >
                          <div className="text-gray-900 font-semibold">
                            Segmentation
                          </div>
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            Connect with third-party tools that you're already
                            using.
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/"
                          className="block p-3 rounded-lg  dark:hover:bg-gray-700"
                        >
                          <div className="text-gray-900 font-semibold">
                            Marketing CRM
                          </div>
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            Connect with third-party tools that you're already
                            using.
                          </span>
                        </NavLink>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <NavLink
                          to="/"
                          className="block p-3 rounded-lg  dark:hover:bg-gray-700"
                        >
                          <div className="text-gray-900 font-semibold">
                            Audience Management
                          </div>
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            Connect with third-party tools that you're already
                            using.
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/"
                          className="block p-3 rounded-lg  dark:hover:bg-gray-700"
                        >
                          <div className="text-gray-900 font-semibold">
                            Creative Tools
                          </div>
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            Connect with third-party tools that you're already
                            using.
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/"
                          className="block p-3 rounded-lg  dark:hover:bg-gray-700"
                        >
                          <div className="text-gray-900 font-semibold">
                            Marketing Automation
                          </div>
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            Connect with third-party tools that you're already
                            using.
                          </span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <NavLink
                to="/portfolios"
                className="block py-4 text-white border-b border-gray-100  lg:hover:bg-transparent lg:border-0  dark:text-white  dark:hover:bg-gray-700  md:dark:hover:bg-transparent dark:border-gray-700  max-[767px]:focus:bg-sky-300"
                onClick={handleScrollToTop}
              >
                Portfolio
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/glossary"
                className="block py-4 text-white border-b border-gray-100  lg:hover:bg-transparent lg:border-0  dark:text-white  dark:hover:bg-gray-700  md:dark:hover:bg-transparent dark:border-gray-700  max-[767px]:focus:bg-sky-300"
                onClick={handleScrollToTop}
              >
                Glossary
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/jobs"
                className="block py-4 text-white border-b border-gray-100  lg:hover:bg-transparent lg:border-0  dark:text-white  dark:hover:bg-gray-700  md:dark:hover:bg-transparent  dark:border-gray-700  max-[767px]:focus:bg-sky-300"
                onClick={handleScrollToTop}
              >
                Jobs
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/quiz"
                className="block py-4 text-white border-b border-gray-100  lg:hover:bg-transparent lg:border-0  dark:text-white md dark:hover:bg-gray-700   dark:border-gray-700  max-[767px]:focus:bg-sky-300"
                onClick={handleScrollToTop}
              >
                Quiz
              </NavLink>
            </li>
            <li className=" login-info relative py-5  border-b border-gray-100  lg:hover:bg-transparent lg:border-0 cursor-pointer">
              {localStorage.getItem("userInfo") ? (
                <div className="z-10 block divide-y divide-gray-100  w-full dark:bg-gray-700 dark:divide-gray-600">
                  <div className="md:px-4 md:py-3 text-sm text-gray-900 dark:text-white flex gap-2">
                    <div className="my-account text-white ">My Account</div>
                    <svg
                      height="20"
                      width="20"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                      fill="#fff"
                      focusable="false"
                      className="css-tj5bde-Svg"
                    >
                      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                    </svg>
                  </div>
                  <div className="login-container pb-[10px] absolute w-max bg-white top-[100%]  pl-[18px] pr-[18px] shadow-lg">
                    <ul
                      className="py-2 text-sm text-gray-700 dark:text-gray-200"
                      aria-labelledby="dropdownUserAvatarButton"
                    >
                      <li className="mb-2">
                        <NavLink to="/dashboard" className="block px-4 py-2 ">
                          Dashboard
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/settings/password"
                          className="block px-4 py-2 "
                        >
                          Change Password
                        </NavLink>
                      </li>
                    </ul>
                    <div className="sign-out">
                      <NavLink
                        to="/"
                        className="block px-4 py-2 text-sm text-gray-700  dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                        onClick={handleLogout}
                      >
                        Sign out
                      </NavLink>
                    </div>
                  </div>
                </div>
              ) : (
                <NavLink
                  to="/login"
                  className="block py-4 text-white  dark:text-white  dark:hover:bg-gray-700  md:dark:hover:bg-transparent dark:border-gray-700"
                  data-twe-nav-link-ref
                  data-twe-ripple-init
                  data-twe-ripple-color="light"
                >
                  Login/Signup
                </NavLink>
              )}
            </li>
            {/* <li className="hidden md:inline-flex">
              <img className="py-4" src={icons.Search} alt="Search" />
            </li> */}

            <li className="state-list-container border-b border-gray-100   lg:hover:bg-transparent lg:border-0">
              <StateList />
            </li>
            <li className=" language-dropdown-container inline-flex">
              <LanguageList />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
