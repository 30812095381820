import React from "react";
import SlickSlider from "../../components/slick/SlickSlider";
import '../../assets/scss/login.scss';
import LoginBg1 from "../../assets/images/loginbg2.jpg";
import LoginBg2 from "../../assets/images/loginbg1.png";
import { processImageUrl } from "../../utils/utils";

const sliderSettings = {
  dots: false,
  infinite: true,
  arrows: false,
  speed: 300,
  slidesToShow: 1,
  autoplay: true,
};
const LoginData = [
  {
    title: "Turn your ideas into reality",
    description: "Start for free and get attractive offer from the community.",
    image:LoginBg1,
  },
  {
    title: "We value your time and money",
    description: "Don't waste your time and money join us for free.",
    image:LoginBg2,
  },
];

const LoginSlider = () => {
  return (
    <div>
        <SlickSlider 
        settings={sliderSettings}
        slides={LoginData.map((item, index) => (
          <div className="login-slider-content text-center md:text-start px-4 md:px-0	md:pl-[6%] pt-[4rem]  h-[100%]">
            <div className="login-title text-[38px] lg:text-[64px] font-bold leading-[50px] lg:leading-[64px] text-white mb-3">{ item?.title }</div>
            <div className="login-description text-xl leading-7	text-[#c8c8c8] mb-8">{ item?.description }</div>
            <div className="login-image">
            <img
              src={processImageUrl(item?.image)}
              className="w-[300px] h-[160px] m-auto md:m-0"
              alt="login-img"
            />
            </div>
          </div>
       ))}
        />
    </div>
  )
};

export default LoginSlider;
