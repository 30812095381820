import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import icons from "../../Icons";
import { processImageUrl } from "../../utils/utils";

const TestimonialSlide = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState(props);
  const [modalId, setModalId] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try{
      if (!modalIsOpen || !modalId) {
        return;
      }
    }
      catch (error) {
        console.error('Error fetching data:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    
  }, [modalIsOpen, modalId]);

  const openModal = (nid) => {
    setModalIsOpen(true);
    setModalId(nid);
    setModalData(modalData);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalId(null);
    setModalData(null)
  };


  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Modal"
        className={"testimonial-modal"}
        ariaHideApp={false}
      >
        <li className=" m-[24px] p-5 list-none relative">
          <div className="listing-testimonial overflow-auto h-[300px]">
            {loading ? (
              "Loading..."
            ) : error ? (
              "Failed to Load"
            ) : (
              <>
                <div className="listing-info flex justify-between">
                  <div className="listing-left flex gap-[14px]">
                    <div className="listing-icon-image">
                      <img
                        loading="lazy"
                        src={processImageUrl(props?.data?.field_icon_image)}
                        alt="img"
                        className="image-style-thumbnail w-[65px] h-[65px] rounded-[30px]"
                      />
                    </div>
                    <div className="listing-name text-base text-[#034d82] font-bold leading-9 capitalize	">
                    {props?.data?.field_title}
                    </div>
                  </div>
                  <div className="listing-right">
                    <div className="listing-coma-image">
                      <img src={processImageUrl(icons.PopupComa)} alt={props?.data?.field_name} />
                    </div>
                  </div>
                </div>
                <div
                  className="listing-desc text-sm leading-6 text-[#4f4f4f] md:pl-20 tracking-wide "
                  dangerouslySetInnerHTML={{
                    __html: props?.data?.full_description,
                  }}
                />
              </>
            )}
          </div>
        </li>
        <button className="close-modal absolute bg-black rounded-[50%] h-12	w-12	my-[-17px] right-[-18px] top-[2px] text-xl text-white" onClick={closeModal}>
          X
        </button>
      </Modal>

      <div className="h-full mx-[20px] mt-[10px] mb-[20px] pb-[112px]">
        <div className="h-full px-[10px]">
          <div className="testimonial inline-block bg-white min-h-[282px]  flex-col items-inherit text-gray-700 rounded-[20px] p-[15px] h-full">
            <div className="testimonial-des rounded-[20px] p-[15px] min-h-[250px] w-full text-center items-center font-normal text-[14px] leading-[24px] tracking-[0.5px] font-sans">
              <h3 className="font-[600] text-[18px] leading-[18px] text-center font-sans tracking-wide font-feature-salt font-feature-liga text-[#151617] my-[10px]">
                {props?.data?.field_description_title}
              </h3>
              <p
                className=" tracking-[0.5px] text-[#4f4f4f] pb-[15px]"
                dangerouslySetInnerHTML={{
                  __html: props?.data?.field_description,
                }}
              />
              {props?.data?.field_description?.length >= 233 && (
                <button
                  data-dialog-type="modal"
                  className="btn text-[#0071b3] use-ajax mb-5 mx-auto block "
                  onClick={() => openModal(props?.data?.nid)}
                >
                  {props?.moretxt}
                </button>
              )}
            </div>
            <div className="author-info-outer bottom-[-200px] w-full left-0 flex justify-center items-center mx-auto my-[95px]">
              <div className="author-info flex">
                <div className="testimonial-img flex">
                  <img
                    className=" w-[70px] h-auto rounded-[50%] "
                    src={processImageUrl(props?.data?.field_icon_image)}
                    alt={props?.data?.image_alt}
                  />
                </div>
                <div className="info pl-[20px] mt-[9px] flex-1">
                  <h4 className="text-white m-auto p-0 font-[700] text-[18px] leading-7 capitalize">
                    {props?.data?.field_name}
                  </h4>
                  <p className="text-[#939393] font-[400] text-[14px] leading-7 pb-0 ">
                    {props?.data?.field_designation},{" "}
                    <span>{props?.data?.field_title}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSlide;
