import { useEffect, useState } from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import axiosInstance from "../../services/axios";
import Loader from '../../components/global/Loader';
import { useSelector } from 'react-redux';
import MetaTag from '../../components/global/MetaTag';
import { processImageUrl } from "../../utils/utils";

const ShimmerItem = () => {
  return (
    <>
      <div className="animate-pulse w-full aspect-[37/10] bg-slate-300 mb-11"></div>
      <div className="container animate-pulse mx-auto">
        <div className="w-[150px] bg-slate-300 h-[30px] rounded mb-5"></div>
        <div className="w-[400px] bg-slate-300 h-[60px] rounded mb-4"></div>
        <div className="w-full bg-slate-300 h-4 rounded mb-2"></div>
        <div className="w-full bg-slate-300 h-4 rounded mb-2"></div>
        <div className="w-[60%] bg-slate-300 h-4 rounded mb-5"></div>

        <div className="w-[25%] bg-slate-300 h-3 rounded mb-4"></div>

        <div className="w-full bg-slate-300 h-4 rounded mt-10 mb-3"></div>
        <div className="w-full bg-slate-300 h-4 rounded mb-3"></div>
        <div className="w-full bg-slate-300 h-4 rounded mb-3"></div>
        <div className="w-[40%] bg-slate-300 h-4 rounded mb-10"></div>

        <div className="w-full bg-slate-300 h-4 rounded mt-10 mb-3"></div>
        <div className="w-full bg-slate-300 h-4 rounded mb-3"></div>
        <div className="w-full bg-slate-300 h-4 rounded mb-3"></div>
        <div className="w-full bg-slate-300 h-4 rounded mb-3"></div>
        <div className="w-[50%] bg-slate-300 h-4 rounded mb-10"></div>

        <div className="w-full bg-slate-300 h-4 rounded mt-10 mb-3"></div>
        <div className="w-[40%] bg-slate-300 h-4 rounded mb-10"></div>
      </div>
    </>
  )
}

const ErrorItem = ({error}) => {
  return (
    <div className="min-h-[500px] flex items-center justify-center"><div className="flex-[1_1_100%] text-center p-[48px]">
      <h1 className="text-[48px] font-bold mb-[10px] text-blue-900 leading-[48px]">{error?.code}</h1>
      <p className="text-[20px] m-0 text-gray-600">{error?.message}</p>
    </div></div>
  )
}

const PortfolioDetailItem = ({id,nid}) => {
  const [portfolioDetail,setPortfolioDetail] = useState(null);
  const [isFetching,setIsFetching] = useState(true);
  const [error,setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const metaData = useSelector(state => state.siteConfig.meta_tags);
  useEffect(() => {
    setIsFetching(true);
    const fetchPortfolioDetail = async () => {
      setLoading(true);
      try {
        const res = await axiosInstance.get(`/read/entity/node/portfolio?alias=/portfolio/${id}`);
        if(res.data.status === 204){
          const errMsg = {code:res.data.status,message:res.data.message + " for " + id}
          throw errMsg;
        }        
        setPortfolioDetail(res.data);
        setLoading(false)
      } catch (err) {
        setError(err);
      }
      setIsFetching(false);
    }
    fetchPortfolioDetail();
  }, [nid,id]);
  if (loading) {
    return <p className='py-[500px] text-center'><Loader /></p>
  }
  if(isFetching) return <ShimmerItem />;

  if(error) return <ErrorItem error={error} />;
  return (
    <>
      <MetaTag metaData={metaData[0]} />
      <div className="banner-section">
        <div className="banner-img case-study-image ">
          <img
            src={processImageUrl(portfolioDetail?.data?.field_image?.field_value[0].uri)}
            alt={portfolioDetail?.data?.title}
          />
        </div>
        <div className="banner-content">
          <div className="banner-text">
            <h2>{portfolioDetail?.data?.title}</h2>
          </div>
        </div>
      </div>
      <div className="min-[320px]:px-10 lg:px-20 pt-[50px] md:pt-[80px]">
        <h1 className="ml-[-2px] lg:text-[64px] text-[38px] font-bold lg:leading-[76px] leading-[50px] text-[#034d82]"> 
          {portfolioDetail?.data?.title}
        </h1>
        <div className="auth-info mb-[40px]">
          <b>
            by{" "}
            <span className="potfolio-username capitalize">
              {portfolioDetail?.data?.uid?.field_value[0]?.username}
            </span>
          </b>{" "}
          on{" "}
          {new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "short",
            day: "2-digit",
          }).format(`${portfolioDetail?.data?.changed}000`)}
        </div>
        <div
          className="text-[16px] text-black mt-[10px] mb-[15px]"
          dangerouslySetInnerHTML={{ __html: portfolioDetail?.data?.body }}
        ></div>

        {portfolioDetail?.data?.field_b && (
          <>
            <h3 className="mb-[10px] text-[#034d82] font-bold text-[30px]">
              Business Solutions
            </h3>
            <div
              className="portfolio-content text-black text-[16px] mt-[10px] mb-[15px]"
              dangerouslySetInnerHTML={{
                __html: portfolioDetail?.data?.field_b,
              }}
            ></div>
          </>
        )}

        {portfolioDetail?.data?.field_about_the_customer && (
          <>
            <h3 className="mb-[10px] text-[#034d82] font-bold text-[30px]">
              About the Customer
            </h3>
            <div
              className="portfolio-content text-black text-[16px] mt-[10px] mb-[15px]"
              dangerouslySetInnerHTML={{
                __html: portfolioDetail?.data?.field_about_the_customer,
              }}
            ></div>
          </>
        )}

        {portfolioDetail?.data?.field_engagement_overview && (
          <>
            <h3 className="mb-[10px] text-[#034d82] font-bold text-[30px]">
              Engagement Overview
            </h3>
            <div
              className="portfolio-content text-black text-[16px] mt-[10px] mb-[15px]"
              dangerouslySetInnerHTML={{
                __html: portfolioDetail.data.field_engagement_overview,
              }}
            ></div>
          </>
        )}

{portfolioDetail.data?.field_tags?.field_value?.length ? 
        <div className="mb-[15px]">
          <h3 className="mb-[10px] text-[#034d82] font-bold text-[30px]">
            Tags
          </h3>
          <div className="flex items-center flex-wrap border-t-2 border-b-2 border-slate-200">
            {portfolioDetail.data?.field_tags?.field_value?.map(
              (tag, index) => (
                <span
                  className=" text-black bg-slate-200 px-[10px] py-1 mr-[10px] my-[20px] rounded-[4px] inline-block"
                  key={index}
                >
                  
                  <NavLink to={`/category${tag?.term?.path_alias}`}>
                    {tag?.term?.name}
                  </NavLink>
                </span>
              )
            )}
          </div>
        </div> : null}
      </div>
    </>
  );
}

const PrePortfolioItem = ({id,nid}) => {
  const [nextPortfolio,setNextPortFolio] = useState(null);
  const [isFetching,setIsFetching] = useState(true);
  const [error,setError] = useState(null);

  useEffect(() => {
    setIsFetching(true)
    const fetchNextPortfolio = async () => {
      try {
        const res = await axiosInstance.get(`/read/node/portfolio?alias=/portfolio/${id}`);
        if(res.data.status === 204){
          const errorMsg = {code:res.data.status,message:"No related portfolio found for "+id};
          throw errorMsg;
        }
        setNextPortFolio(res.data.data);
      } catch(err){
        setError(err);
      }
      setIsFetching(false);
    }
    fetchNextPortfolio();
  }, [nid,id])

  if(isFetching) return (
      <div className="container mx-auto animate-pulse my-[70px]">
        <div className="w-[200px] bg-slate-300 h-[40px] rounded mb-4"></div>
        <div className="w-full bg-slate-300 h-4 rounded mb-2"></div>
        <div className="w-full bg-slate-300 h-4 rounded mb-2"></div>
        <div className="w-[60%] bg-slate-300 h-4 rounded mb-2"></div>
      </div>
  );

  if(error) return (<div className=" lg:mx-24 md:mx-16 min-[320px]:mx-10 my-[70px]">
    <p className="text-[20px]">{error.message}</p>
  </div>);

  return (
    <div className="block md:flex justify-between items-center flex-wrap pb-[50px] md:pb-[80px] pt-[15px]">
    <div className=" portfolio-suggestion min-[320px]:px-10 lg:px-20 pb-[15px] md:pb-0">
      
      {nextPortfolio.prev?.nid >0 ? (
      <>
      {/* <h3 className=" text-[#034d82] font-bold text-[24px]">Read Previous</h3> */}
      <NavLink to={`${nextPortfolio.prev?.path}?nid=${nextPortfolio.prev?.nid}`} className="border-b-solid border-b-[#90a3b4] border-b-2  hover:border-b-0 text-[18px] lg:text-[20px] leading-[30px]">
        <div className="suggestion-title hover:text-[#034d82] flex items-center justify-center"> 
        <div className="text-[28px] pr-[4px]">&laquo;</div>
         <div>{nextPortfolio.prev?.title} </div>
         </div>
      </NavLink>
      </>):('')} 
    </div>
        <div className=" portfolio-suggestion min-[320px]:px-10 lg:px-20">
      
        {nextPortfolio.next?.nid >0 ? (
        <>
        {/* <h3 className=" text-[#034d82] font-bold text-[24px] ">Read Next</h3> */}
        <NavLink to={`${nextPortfolio.next?.path}?nid=${nextPortfolio.next?.nid}`} className="border-b-solid border-b-[#90a3b4] border-b-2  hover:border-b-0 text-[18px] lg:text-[20px] leading-[30px]">
          <div className="suggestion-title hover:text-[#034d82] flex items-center justify-center">
            <div>{nextPortfolio.next?.title}</div>
            <div className="text-[28px] pl-[4px]">&raquo;</div> 
            </div>
        </NavLink>
          {/* <span className="suggestion-desc text-[#90a3b4]" dangerouslySetInnerHTML={{__html:nextPortfolio.next?.body_value.replace(/(<([^>]+)>)/gi,"")}}></span> */}
        </>):('')} 
      </div>
      </div>
  )
}
export default function PortfolioDetail(props){
  const [searchParams] = useSearchParams();
  const {id} = useParams();
  const nid = searchParams.get('nid');
  window.scrollTo(0,0);

  return (
    <>
      <PortfolioDetailItem id={id} nid={nid} />
      <PrePortfolioItem id={id} nid={nid} />
    </>
  )
}