import SlickSlider from "../slick/SlickSlider";
import { Link, useLocation } from "react-router-dom";
import { processImageUrl } from "../../utils/utils";

const UserBlogSlider = ({ interestedBlog }) => {
 
  const sliderSettings = {
    infinite: interestedBlog.length > 3 ? true : false,
    arrows: interestedBlog.length > 3 ? true : false,
    speed: 300,
    slidesToShow: 3,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 3000,
          speed: 1500,
          arrows: false,
        }
      }
    ]};
  return (
    <div className="slider1 flex-grow-[1] ">
      <div className="blogs-outer user-blog-slider">
        <SlickSlider
          settings={sliderSettings}
          slides={interestedBlog.map((item, index) => (
            <div className="m-[15px] relative">
              <div className="xyz h-full shadow-neutral-700 shadow-lg rounded-xl bg-[#ffffff]">
                <div className="blog-imge transform transition-transform duration-200 hover:scale-90 p-[8px] pb-0">
                  <Link to={item?.view_node}>
                    <img
                      src={processImageUrl(item?.field_image_1)}
                      alt="blogImg"
                      className=" w-full rounded-t-lg"
                    />
                  </Link>
                </div>
                <div className="blogs-bottom-sec p-[20px] lg:pb-[60px]">
                  <Link to={item?.view_node}>
                    <div className="blog-title ">
                      {item.title.length > 0 && (
                        <div className="min-h-[90px] overflow-hidden  mb-[40px] text-[#034d82] font-bold text-[20px] line-clamp-3  leading-[30px]" dangerouslySetInnerHTML={{ __html: item.title }} />
                      )}
                    </div>
                  </Link>
                  <div className='container flex items-center	lg:justify-between md:justify-between sm:justify-start	mb-[20px] md:w-[81%] 2xl:w-[84%] absolute bottom-0 '>
                    <span className="category-btn mr-[60px] lg:mr-[30px] md:mr-[30px] w-[127px]">
                        <Link to={`/blogs?cid=${item.field_category_id}`} className='block text-center hover:font-extrabold font-normal text-[12px] text-white pr-[15px] pl-[15px] py-[9px] uppercase cursor-pointer'> {item.field_category}</Link>
                    </span>
                    
                  <Link data-dialog-type="modal" className="btn" to={item?.view_node}>
                    <p className="reads text-[#000] capitalize font-bold text-[16px] relative after:absolute after:h-[29px] after:w-[29px] after:top-[67%] after:left-[96%]  after:ml-[15px] after:translate-y-[-50%] after:transition after:duration-[.2s] hover:after:translate-y-[-50%] hover:after:translate-x-[5px]">
                      {item.node_read_time} Read
                    </p>
                  </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        />
      </div>
    </div>
  );
}

export default UserBlogSlider
